/// <reference path="controlador.js" />
/// <reference path="../scripts/rsvp.js" />

//var promesaIndiceRecinto;
//var promesaIndicePabellones;

//******** VARIABLES PARA PABELLONES ***************************//
var myScrollRecinto;
var zomRecinto = 1;
var arrayPabellones = new Array();
var puntos = Array();
var NombreImagen = '';
var xmindgn = 0, ymindgn = 0, anchodgn = 0, altodgn = 0, anchopix = 0, altopix = 0, Yoffset = 0;
var STAND_Recinto = '';

//******** VARIABLES PARA PABELLONES ***************************//

//******** VARIABLES PARA STANDS ***************************//
var pabellones = new Array();
var stands = new Array();
var xmindgn_stand;
var ymindgn_stand;
var anchodgn_stand;
var altodgn_stand;
var gstand;
var contador;
var zom = 1;
var vPabellonActual = '';
var idCapaMarkerPlano = 'stand-in-map-recinto';
var idCapaFavoritoPlano = 'stand-in-map-recinto-fav';
var idMarkerPlano = 'markerPlano';
var idCapaMarkerPabellon = 'stand-in-map';
var idCapaFavoritoPabellon = 'stand-in-map-fav';
var idMarkerPabellon = 'markerPabellon';

//******** VARIABLES PARA STANDS ***************************//

var myscrollpabellon = null;
// **************** FUNCIONES PABELLONES ************************//
function NoZoomerRecinto() {
    if (zomRecinto > 1) {
        zomRecinto -= 0.5;
        myScrollRecinto.zoom(0, 0, zomRecinto, 0);
        PintarLocalizadorPlano(STAND_Recinto, $('#' + idCapaMarkerPlano), idMarkerPlano, true);
        tratamientoFavoritosPlano(GetObjGlobales().mostrarFavoritos);
    }
}

function ZoomerRecinto() {
    zomRecinto += 0.5;
    myScrollRecinto.zoom(0, 0, zomRecinto, 0);
    PintarLocalizadorPlano(STAND_Recinto, $('#' + idCapaMarkerPlano), idMarkerPlano, true);
    tratamientoFavoritosPlano(GetObjGlobales().mostrarFavoritos);
}


function BuscarCentroides(Stand) {

    for (i = 0; i < stands.length; i++) {
        if (stands[i].numstand == Stand)
            return stands[i];
    }
}

function BuscarStandPorEmpresa(idEmpresa) {

    var arrayStands = new Array();
    //Marco, el código que viene en la exportación es del tipo XXX_XX_XX, mientras que el que esta en los ficheros de coordenadas es XXX_XX
    var res = idEmpresa.split("_");
    var codEmpresa = res[0] + "_1"; //"_1_0";
    for (i = 0; i < stands.length; i++) {
        if (stands[i].codempr == codEmpresa) {
        	 arrayStands.push(stands[i]);
        }        
    }
    return arrayStands;
}

function punto() { };
punto.prototype = {
    mslink: '', marcado: false, prefimg: '',
    coordenadas: [],
    inicializa: function (i, j) {
        this.mslink = i; this.prefimg = j;
        this.coordenadas = new Array();
    },
    addcoordenada: function (x1, y1) {
        this.coordenadas.push({ x: x1, y: y1 });
    },
    pinta: function () { }
}



function getIndiceRecinto() {
    //promesaIndiceRecinto = promesaIndiceRecinto || new RSVP.Promise(...
    return new RSVP.Promise(function (resolve, reject) {
        $.ajax({
            type: "GET",
            url: GetObjGlobales().getRutaFichero("X", "imgrecinto.xml"),
            dataType: "xml",
            success: function (xml) {
                resolve(xml);
            },
            error: function (reason) {
                reject(reason);
            }
        });
    });;
}


function CargarRecinto() {
    var cadena = '';
    var mslink = '', prefimg = '';

    return new RSVP.Promise(function (resolve, reject)
    {
        getIndiceRecinto().then(function (xml)
        {
            
            var contador = 0;

            //obtener parametros de imagen
            $(xml).find('IMG').each(function () {
                NombreImagen = $(this).attr('NOMBRE');
                xmindgn = parseFloat($(this).attr('xmindgn'));
                ymindgn = parseFloat($(this).attr('ymindgn'));
                anchodgn = parseFloat($(this).attr('anchodgn'));
                altodgn = parseFloat($(this).attr('altodgn'));
                anchopix = parseFloat($(this).attr('anchopix'));
                altopix = parseFloat($(this).attr('altopix'));
            });

            $(xml).find('IMG').each(function () {
                $(this).find('PAB').each(function () {
                    puntos[contador] = new punto();
                    puntos[contador].inicializa($(this).attr('mslink'), $(this).attr('prefimg'));
                    $(this).find('V').each(function () {
                        puntos[contador].addcoordenada(parseFloat($(this).attr('x')), parseFloat($(this).attr('y')));
                    });
                    contador++;
                });
            });

            resolve('');
        })
        ['catch']
        (
            
             function (error) {
                   console.log("ERROR", error);
             }
        );
    });
};

function LoadMapa() {
    ScrollRecinto('wrapperMapa');
    myScrollRecinto.refresh();
    setTimeout(function () {
        if (STAND_Recinto != "") {
            // Sólo se pinta el marker de la empresa en ajax_plano.html cuando se hace click desde la ficha de la empresa, y no desde la opción de menú planos.
            PintarLocalizadorPlano(STAND_Recinto, $('#' + idCapaMarkerPlano), idMarkerPlano, true);
            GetObjGlobales().mostrarFavoritos = false;
        } else {
            // Se deben mostrar desde la opción Planos del menú principal
            GetObjGlobales().mostrarFavoritos = true;
        }
        $('#chkFavoritos').attr('checked', GetObjGlobales().mostrarFavoritos);
        tratamientoFavoritosPlano(GetObjGlobales().mostrarFavoritos);

        //alert('Load XXX *** STAND: ' + STAND_PlanoPabellon + ' ancho: ' + anchopix + ' alto: ' + altopix + ' Yoffset: ' + Yoffset);
    }, 100);
}

function isPointInPoly(poly, pt) {
    for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
        ((poly[i].y <= pt.y && pt.y < poly[j].y) || (poly[j].y <= pt.y && pt.y < poly[i].y))
        && (pt.x < (poly[j].x - poly[i].x) * (pt.y - poly[i].y) / (poly[j].y - poly[i].y) + poly[i].x)
        && (c = !c);
    return c;
}

function PintarLocalizadorPlano(STAND, capa, idMarker, mostrarFav) {
    if (STAND != null && STAND != "undefined" && STAND != "" && ($('#MapaPabellones').length)) {
        //hago esta chapu, ya que si no el double tap en iOS no funciona adecuadamente ... no me preguntes porque.
        if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Planos'); } else { GetObjGlobales().titulaCabecera('Floor Plan'); }

        var anchopix = ($("#MapaPabellones").width() * zomRecinto);
        var altopix = $("#MapaPabellones").height() * zomRecinto;
        var Yoffset = $("#MapaPabellones").offset().top;
        var Centroide = BuscarCentroides(STAND);
        if (Centroide != 'undefined' && Centroide != null) {
            var FC = 2.8;
            var Px = ((Centroide.cx - xmindgn) / anchodgn) * anchopix;
            var Py = (((Centroide.cy + FC) - ymindgn) / altodgn) * altopix;
            Py = altopix - Py + Yoffset;
            Px = Px + myScrollRecinto.x;
            capa.css({ "top": +Py - 32, "left": +Px - 9 }); //36 x32 

            $('#' + idMarker).click(function () {
                GetPlanoRecinto(parseFloat(capa.css("left")) + 9, parseFloat(capa.css("top")) + 32);
            });

            if (mostrarFav) {
                capa.show();
                //myScrollRecinto.refresh();
            }
        }
    }
}

function PabellonActual(XDGN, YDGN) {
    var puntoTest = { x: XDGN, y: YDGN };
    var iPabellon = '';
    for (i = 0; i < puntos.length; i++) {

        if (isPointInPoly(puntos[i].coordenadas, puntoTest)) {
            iPabellon = puntos[i].prefimg;
            break;
        }
    }
    return iPabellon;   
}

function GetPlanoRecinto(X, Y) {
    var XDGN = 0;
    var YDGN = 0;
    var anchopix = $("#MapaPabellones").width() * zomRecinto;
    var altopix = $("#MapaPabellones").height() * zomRecinto;
    var Yoffset = $("#MapaPabellones").offset().top;

    Y = altopix - Y + Yoffset;
    X = X - myScrollRecinto.x;
    XDGN = ((X / anchopix) * anchodgn) + xmindgn;
    YDGN = ((Y / altopix) * altodgn) + ymindgn;

    var PabellonPulsado = PabellonActual(XDGN, YDGN);
    if (PabellonPulsado != null && PabellonPulsado != "undefined" && PabellonPulsado != "" && PabellonPulsado.length > 0) {
        if (STAND_Recinto != null && STAND_Recinto != "undefined" && STAND_Recinto != "" && STAND_Recinto.length > 0) {
            window.location = '#ajax_pabellon.html?p=' + PabellonPulsado + '&s=' + STAND_Recinto;
        }
        else {
            window.location = '#ajax_pabellon.html?p=' + PabellonPulsado;
        }
    }
};

// **************** FUNCIONES PABELLONES ************************//


function stand() { };
stand.prototype = {
    mslink: '540548',
    numstand: '3E23',
    codempr: '750894_1',
    cx: 0,
    cy: 0,
    pabellon: '',
    puntos: [],
    inicializa: function (mslink, numstand, codempr, cx, cy, pab) {
        this.mslink = mslink;
        this.numstand = numstand;
        this.codempr = codempr;
        this.cx = cx;
        this.cy = cy;
        this.pabellon = pab;
        this.puntos = new Array();
    },
    addpunto: function (x1, y1) {
        this.puntos.push({ x: x1, y: y1 });
    }
};

function pabellon() { };
pabellon.prototype = { xmindgn: 0, ymindgn: 0, anchodgn: 0, altodgn: 0, prefimg: '',
    inicializa: function (a, b, c, d, e) {
        this.xmindgn = a; this.ymindgn = b; this.anchodgn = c; this.altodgn = d; this.prefimg = e;
    },
    pinta: function () { }
}

function BuscarPabellon(Pabellon) {
    for (i = 0; i < pabellones.length; i++) {
        if (pabellones[i].prefimg == Pabellon)
            return { xmin: pabellones[i].xmindgn, ymin: pabellones[i].ymindgn,
                ancho: pabellones[i].anchodgn, alto: pabellones[i].altodgn
            }
    }
}

function InicializaCoordenadasDGNPabellon(Pabellon) {
    var Coor = BuscarPabellon(Pabellon);
    vPabellonActual = Pabellon;
    if (Coor != 'undefined' && Coor != "undefined" && Coor != null) {
        xmindgn_stand = Coor.xmin;
        ymindgn_stand = Coor.ymin;
        anchodgn_stand = Coor.ancho;
        altodgn_stand = Coor.alto;
    }
}

function getIndicePabellones() {
    //promesaIndicePabellones = promesaIndicePabellones || new RSVP.Promise(...
    return new RSVP.Promise(function (resolve, reject) {
        $.ajax({
            type: "GET",
            url: GetObjGlobales().getRutaFichero("X", "imgstands.xml"),
            dataType: "xml",
            success: function (xml) {
                resolve(xml);
            },
            error: function (reason) {
                reject(reason);
            }
        });
    });;
}

function CargarPabellon() {
    return new RSVP.Promise(function (resolve, reject)
    {
        getIndicePabellones().then(function (xml)
        {
            var stand1;
            var pabellon1;
            pabellones = [];
            stands = [];
            $(xml).find('PAB').each(function () {
                pabellon1 = new pabellon();
                pabellon1.inicializa(parseFloat($(this).attr('xmindgn')),
                                        parseFloat($(this).attr('ymindgn')),
                                        parseFloat($(this).attr('anchodgn')),
                                        parseFloat($(this).attr('altodgn')),
                                        $(this).attr('prefimg'));
                pabellones.push(pabellon1);

                //                xmindgn_stand = parseFloat($(this).attr('xmindgn'));
                //                ymindgn_stand = parseFloat($(this).attr('ymindgn'));
                //                anchodgn_stand = parseFloat($(this).attr('anchodgn'));
                //                altodgn_stand = parseFloat($(this).attr('altodgn'));

                //<PAB pab="PAB1" xmindgn="-158,115047453031" ymindgn="-13,742960248518" anchodgn="3,9661698552713" altodgn="3,9661698552713" anchopix="500" altopix="500">
                $(this).find('ST').each(function () {
                    //mslink="540548" numstand="3E23" codempr="750894_1" cx="-9,75" cy="-5,225"
                    stand1 = new stand();
                    stand1.inicializa($(this).attr('mslink'),
                                            $(this).attr('numstand'),
                                            $(this).attr('codempr'),
                                            parseFloat($(this).attr('cx')),
                                            parseFloat($(this).attr('cy')), pabellon1.prefimg);
                    stands.push(stand1);
                    $(this).find('V').each(function () {
                        //<V x="-15,9" y="-5,9"/>
                        stand1.addpunto(parseFloat($(this).attr('x')), parseFloat($(this).attr('y')));
                    })
                })
            })

            resolve('');
        });
    });
};


//// **************** FUNCIONES STANDS ************************//
function PintarLocalizadorStand(pstand, capa, idMarker, mostrarFav)
{
    //if (gstand != null && gstand != "undefined" && gstand != "" && ($('#mappab').length)) {
    PoneTituloPabellon();
    //        if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Pabellón'); } else { GetObjGlobales().titulaCabecera('Pavilion'); }
    var anchopix = ($("#mappab").width() * zom);
    var altopix = $("#mappab").height() * zom;
    var Yoffset = $("#mappab").offset().top;
    var Xoffset = $("#mappab").offset().right;
    var Centroide = BuscarCentroides(pstand);

    if (Centroide != 'undefined' && Centroide != null) {
        if (Centroide.pabellon == vPabellonActual) {
            var Px = ((Centroide.cx - xmindgn_stand) / anchodgn_stand) * anchopix;
            var Py = (((Centroide.cy) - ymindgn_stand) / altodgn_stand) * altopix;
            Py = altopix - Py + Yoffset;
            Px = Px + myscrollpabellon.x;
            capa.css({ "top": +Py - 32, "left": +Px - 9 }); //36 x32


            $('#' + idMarker).click(function ()
            {
                GetObjEmpresasStand(true).MostrarEmpresasStand(
                    parseFloat(capa.css("left")) + 9,
                    parseFloat(capa.css("top")) + 32,
                    stands,
                    {
                        anchodgn_stand: anchodgn_stand,
                        altodgn_stand: altodgn_stand,
                        xmindgn_stand: xmindgn_stand,
                        ymindgn_stand: ymindgn_stand
                    },
                    zom);
            });

            if (mostrarFav) {
                capa.show();
            }
        }
    }
    //} 
}

function NoZoomerPabellon() {
    if (zom > 1) {
        zom -= 1;
        myscrollpabellon.zoom(0, 0, zom, 0);
        tratamientoFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
    }
}

function ZoomerPabellon() {
    zom += 1;
    myscrollpabellon.zoom(0, 0, zom, 0);
    tratamientoFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
}

function imagencargada() {
    contador = contador + 1;
    if (contador >= 25) {
        if (myscrollpabellon == null) {
            elScrollPabellon('wrapperPabellon');
        }
        myscrollpabellon.refresh();
    }
}

function LoadStands() {
    //alert('pabellon cargado');
    elScrollPabellon('wrapperPabellon');
    myscrollpabellon.refresh();
    setTimeout(function () {
        if (STAND_Recinto != "") {
            PintarLocalizadorStand(gstand, $('#' + idCapaMarkerPabellon), idMarkerPabellon, true);
        }
        tratamientoFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
    }, 100);

}
//// **************** FUNCIONES STANDS ************************//

//************** CREAR SCROLLS **********************************///
function elScrollPabellon(quien) {
    if ($('#' + quien).length > 0) {
        myscrollpabellon = new iScroll(quien, {
            useTransform: true,
            zoom: true,
            zoomMin: 1,
            zoomMax: 6,
            bounce: false,
            momentum: false,
            vScrollbar: true,
            onScrollMove: function () {
                if (gstand != "") {
                    PintarLocalizadorStand(gstand, $('#' + idCapaMarkerPabellon), idMarkerPabellon, true);
                    //tratamientoFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
                }
                tratamientoFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
            },
            onZoomEnd: function () {
                zom = this.scale;
                if (gstand != "") {
                    PintarLocalizadorStand(gstand, $('#' + idCapaMarkerPabellon), idMarkerPabellon, true);
                    //tratamientoFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
                }
                tratamientoFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
            },
            onScrollEnd: function () {
                //   PintarLocalizadorStand(gstand);
            },
            onZoomStart: function () {
                OcultaLocalizadorStand();
                limpiarFavoritosPabellon();
            }
        });
        $('#capaCargador').fadeOut();
    }
};

function ScrollRecinto(quien) {
    if ($('#' + quien).length > 0) {
        myScrollRecinto = new iScroll(quien,
        {
            useTransform: true,
            zoom: true,
            zoomMin: 1,
            zoomMax: 6,
            bounce: false,
            momentum: false,
            vScroll: true,
            onScrollMove: function () {
                PintarLocalizadorPlano(STAND_Recinto, $('#' + idCapaMarkerPlano), idMarkerPlano, true);
                tratamientoFavoritosPlano(GetObjGlobales().mostrarFavoritos);
                /*if (STAND_Recinto != "") {
                tratamientoFavoritosPlano(GetObjGlobales().mostrarFavoritos);
                } else {
                tratamientoFavoritosPlano(true);
                }*/
            },
            onZoomEnd: function () {
                zomRecinto = this.scale;
                PintarLocalizadorPlano(STAND_Recinto, $('#' + idCapaMarkerPlano), idMarkerPlano, true);
                tratamientoFavoritosPlano(GetObjGlobales().mostrarFavoritos);
                /*if (STAND_Recinto != "") {
                tratamientoFavoritosPlano(GetObjGlobales().mostrarFavoritos);
                } else {
                tratamientoFavoritosPlano(true);
                }*/
            },
            onTouchEnd: function () {
                //alert('touch end');
            },
            onZoomStart: function () {
                OcultaLocalizadorRecinto();
                limpiarFavoritosPlano();
            }
        });
        $('#capaCargador').fadeOut();
    }
};

function PoneTituloPabellon() {
    if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Pabellón'); } else { GetObjGlobales().titulaCabecera('Hall'); }
}

function tratamientoFavoritosPlano(mostrarFav) {
    /* Gestiona si se debe mostrar o no el check de Favoritos, y además en caso de estar disponible la opción, se deben añadir dinámicamente
    los favoritos como elementos <li> en la lista <ul> de id "stand-in-map-recinto" */
    limpiarFavoritosPlano();
    limpiarFavoritosPabellon();

    $('#chkFavoritos').click(function () {
        if ($(this).is(':checked')) {
            GetObjGlobales().mostrarFavoritos = true;
        } else {
            GetObjGlobales().mostrarFavoritos = false;
        }
        mostrarFavoritosPlano(GetObjGlobales().mostrarFavoritos);
    });


    if (!mostrarFav) {
        if (GetObjGlobales().mostrarFavoritos) {
            $('#chkFavoritos').attr('checked', true);
        } else {
            $('#chkFavoritos').attr('checked', false);
        }
    } else {
        $('#chkFavoritos').attr('checked', true);
    }

	var arrayFavs = GetObjGlobales().loadArrayFavoritos();
    if (arrayFavs.length > 0) {
        for (var i = 0; i < arrayFavs.length; i++) {
            arrayStands = BuscarStandPorEmpresa(arrayFavs[i].id);
            crearFavoritosPlano(arrayStands, mostrarFav);
        }
        $('#trataFavoritos').show();
    }         	
}

function crearFavoritosPlano(arrayFavoritos, mostrarFav) {
    // Se crean las capas para los favoritos. Una por favorito.
    var idCapa;
    var idMarkerFavorito;
    var capa;

    for (var j = 0; j < arrayFavoritos.length; j++) {
        idCapa = idCapaFavoritoPlano + GetObjGlobales().numFavoritos;
        idMarkerFavorito = 'fav' + GetObjGlobales().numFavoritos;
        capa = $('<div id="' + idCapa + '" class="pushpinFavoritoPlano"><li><img id="' + idMarkerFavorito + '" src="img/fav_marker.png" /></li></div>');
        capa.hide();
        if (arrayFavoritos[j].numstand != STAND_Recinto) {
            // Se añade el favorito sólo si no está visible ya la empresa
            $('#favoritos').append(capa);
            GetObjGlobales().numFavoritos++;
            PintarLocalizadorPlano(arrayFavoritos[j].numstand, capa, idMarkerFavorito, mostrarFav);
        }
    }
}

function limpiarFavoritosPlano() {
    // Se borran todas las capas de favoritos de ajax_plano.html
    GetObjGlobales().numFavoritos = 0;
    var arrayCapasFavoritos = $("div[id^=" + idCapaFavoritoPlano + "]");
    $.each(arrayCapasFavoritos, function (ind, capaFavorito) {
        $(capaFavorito).remove();
    });
}

function mostrarFavoritosPlano(mostrar) {
    // Función que muestra/oculta las capas de favoritos según marque o demsarque el check el usuario
    var arrayCapasFavoritos = $("div[id^=" + idCapaFavoritoPlano + "]");
    if (mostrar) {
        $.each(arrayCapasFavoritos, function (ind, capaFavorito) {
            $(capaFavorito).show();
        });
    } else {
        $.each(arrayCapasFavoritos, function (ind, capaFavorito) {
            $(capaFavorito).hide();
        });
    }

}


function tratamientoFavoritosPabellon(mostrarFav) {
    /* Gestiona si se debe mostrar o no el check de Favoritos, y además en caso de estar disponible la opción, se deben añadir dinámicamente
    los favoritos como elementos <li> en la lista <ul> de id "stand-in-map-recinto" */

    limpiarFavoritosPlano();
    limpiarFavoritosPabellon();

    $('#chkFavoritosPab').click(function () {
        if ($(this).is(':checked')) {
            GetObjGlobales().mostrarFavoritos = true;
        } else {
            GetObjGlobales().mostrarFavoritos = false;
        }
        mostrarFavoritosPabellon(GetObjGlobales().mostrarFavoritos);
    });

    if (GetObjGlobales().mostrarFavoritos) {
        $('#chkFavoritosPab').attr('checked', true);
    } else {
        $('#chkFavoritosPab').attr('checked', false);
    }

	var arrayFavs = GetObjGlobales().loadArrayFavoritos();
    if (arrayFavs.length > 0) {
        for (var i = 0; i < arrayFavs.length; i++) {
            arrayStands = BuscarStandPorEmpresa(arrayFavs[i].id);
            if (mostrarFav){
            	// Si vengo de la opci�n de Planos del men� Principal
            	crearFavoritosPabellon(arrayStands, mostrarFav);
            } else {
            	// Si vengo de la opci�n Ficha de Empresa
            	crearFavoritosPabellon(arrayStands, GetObjGlobales().mostrarFavoritos);
            }  
        }
        $('#trataFavoritosPab').show();
    }   
}

function crearFavoritosPabellon(arrayFavoritos, mostrarFav) {
    // Se crean las capas para los favoritos. Una por favorito.

    var idCapa;
    var idMarkerFavorito;

    for (var j = 0; j < arrayFavoritos.length; j++) {
        idCapa = idCapaFavoritoPabellon + GetObjGlobales().numFavoritos;
        idMarkerFavorito = 'fav' + GetObjGlobales().numFavoritos;
        capa = $('<div id="' + idCapa + '" class="pushpinFavoritoPabellon"><img id="' + idMarkerFavorito + '" src="img/fav_marker.png" /></div>');
        capa.hide();
        if (arrayFavoritos[j].numstand != STAND_Recinto) {
            // Se añade el favorito sólo si no está visible ya la empresa
            $('#favoritosPab').append(capa);
            GetObjGlobales().numFavoritos++;
            PintarLocalizadorStand(arrayFavoritos[j].numstand, capa, idMarkerFavorito, mostrarFav);
        }
    }
}

function limpiarFavoritosPabellon() {
    // Se borran todas las capas de favoritos de ajax_pabellon.html
    GetObjGlobales().numFavoritos = 0;
    var arrayCapasFavoritos = $("div[id^=" + idCapaFavoritoPabellon + "]");
    $.each(arrayCapasFavoritos, function (ind, capaFavorito) {
        $(capaFavorito).remove();
    });
}

function mostrarFavoritosPabellon(mostrar) {
    // Función que muestra/oculta las capas de favoritos según marque o demsarque el check el usuario

    var arrayCapasFavoritos = $("div[id^=" + idCapaFavoritoPabellon + "]");
    if (mostrar) {
        $.each(arrayCapasFavoritos, function (ind, capaFavorito) {
            $(capaFavorito).show();
        });
    } else {
        $.each(arrayCapasFavoritos, function (ind, capaFavorito) {
            $(capaFavorito).hide();
        });
    }

}