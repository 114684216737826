/// <reference path="inicio.js" />

// ****************************************** PARTE OFFLINE ******************************************* 
// ****************************************************************************************************



function inicializarPlugins() {
    window.rutaActual = function (parametros, setRutaActualOk, setRutaActualError) {
        cordova.exec(setRutaActualOk, setRutaActualError, "RutaPlugin", "execute", parametros);
    };
    window.comprobarActualizacion = function (parametros, setActualizacionPendienteOk, setActualizacionPendienteError) {
        cordova.exec(setActualizacionPendienteOk, setActualizacionPendienteError, "ComprobarActualizacionPlugin", "execute", parametros);
    };
    window.obtenerTotalFicherosDescargar = function (parametros, setTotalFicherosDescargarOk, setTotalFicherosDescargarError) {
        cordova.exec(setTotalFicherosDescargarOk, setTotalFicherosDescargarError, "TotalFicherosDescargarPlugin", "execute", parametros);
    };
    window.actualizar = function (parametros, setFicherosDescargadosOk, setFicherosDescargadosError) {
        cordova.exec(setFicherosDescargadosOk, setFicherosDescargadosError, "ActualizarPlugin", "execute", parametros);
    };
    window.fileOpener = function (parametros) {
        cordova.exec(setFileOpenerOk, setFileOpenerError, "FileOpenerPlugin", "execute", parametros);
    };
    window.obtenerCodigoQR = function (parametros, setObtenerCodigoQROK, setObtenerCodigoQRError) {
    	cordova.exec(setObtenerCodigoQROK, setObtenerCodigoQRError, "ObtenerDatosCodigoQRPlugin", "execute", parametros);
    };
    window.obtenerCodigoPDF417 = function (parametros, setObtenerCodigoPDF417OK, setObtenerCodigoPDF417Error) {
        cordova.exec(setObtenerCodigoPDF417OK, setObtenerCodigoPDF417Error, "ObtenerDatosCodigoPDF417Plugin", "execute", parametros);
    };
    window.sincronizarCodigosQR = function (parametros, setObtenerSincronizarCodigosQROK, setObtenerSincronizarCodigsoQRError) {
        cordova.exec(setObtenerSincronizarCodigosQROK, setObtenerSincronizarCodigsoQRError, "SincronizarCodigosQRPlugin", "execute", parametros);    	
    };
}

function setFileOpenerOk() {

    //	$('#dialog-confirm').html('<p>' + _msgInfo + 'Todo ok.</p>'); 
    //    MsgSi('Error', null);

}

function setFileOpenerError(error) {
    if (GetObjGlobales().EsSpanish()) {
        $('#dialog-confirm').html('<p>' + _msgInfo + 'No se pudo abrir el documento.</p>');
        MsgSi('Error', null);
    } else {

        $('#dialog-confirm').html('<p>' + _msgInfo + 'Error opening document.</p>');
        MsgSi('Error', null);
    }
}




function setRutaActualOk(rutaApp) {
    //alert('setrutaactualok ' +rutaApp);
    var rutatemp = rutaApp.toString();
    GetObjGlobales().rutarootsinferia = rutatemp;
    GetObjGlobales().rutaroot = GetObjGlobales().rutarootsinferia + GetObjGlobales().feria;
    //GetObjGlobales().rutaroot = rutaApp; 	    	          

    //iniciarFlujoAplicacion es un metodo de inicio.js.
    iniciarFlujoAplicacion();
}

function setRutaActualError(error) {
    // Se mostra al usuario el error al obtener la ruta del contexto de la aplicación. Se debe cerrar la aplicaci�n
    //alert(GetObjGlobales().DameTraduccion(16));
    /*if (error == "Error de lectura en la SD")
    {
    // La SD no est� montada o s�lo tiene permisos de lectura.
    mostrarErrorSD();
    } else {
    mostrarError();
    }
	 
    // Se cierra la aplicaci�n
    cerrarApp();*/
}

function setActualizacionPendienteOk(actualizacionPendiente) {
    if (actualizacionPendiente == "true") {
        /* Se hacen las acciones correspondientes para modificar el menu Acerca de (Ajustes) 
        para indicar que hay actualizaciones pendientes. */
        GetObjGlobales().ActualizacionPendiente = true;
        //window.plugins.interfazCatalogoNativa.obtenerTotalFicherosDescargar([], setTotalFicherosDescargarOk, setTotalFicherosDescargarError);
        window.obtenerTotalFicherosDescargar([], setTotalFicherosDescargarOk, setTotalFicherosDescargarError);
    }
}

function setActualizacionPendienteError(error) {
    /* Se hacen las acciones correspondientes para mostrar el error al comprobar si hay actualizaciones pendientes */
    //alert(GetObjGlobales().DameTraduccion(16));
	if (error == 2) {
		mostrarMsgSinPermisos();
	} else {
	    mostrarMsgSinInternet();	
	}

    /* En este caso se continua la ejecuci�n ya que, que no tenga internet no impide que se pueda continuar 
    la ejecuci�n, y no se cierra la aplicación como en los dem�s casos de error. */
}

function setTotalFicherosDescargarOk(datosFichDescargar) {
    // Se establecen el n�mero de ficheros a descargar, y el tama�o de los mismos
    GetObjGlobales().numFicherosActualizar = parseInt(datosFichDescargar[0]);
    GetObjGlobales().tamFicherosDescargar = parseInt(datosFichDescargar[1]);

    // Mostramos el mensaje de que existe una actualizaci�n pendiente de X es tama�o si la conexi�n es WIFI
    mostrarActualizacionPendiente();

    // Se pinta el icono de actualizaciones pendientes en el men� Ajustes
    pintarActualizacionPendiente();
}

function setTotalFicherosDescargarError(error) {
    //alert(GetObjGlobales().DameTraduccion(16));
    mostrarError();

    // Se cierra la aplicaci�n
    cerrarApp();
}

function setFicherosDescargadosOk(datosActualizacion) {
    /* Se recibe un array de 7 posiciones:
    Posición 0 -> N�mero de ficheros descargados hasta el momento
    Posición 1 -> N�mero total de ficheros a descargar
    Posición 2 -> Tamaño en Mb de ficheros descargados hasta el momento
    Posición 3 -> N�mero de ficheros procesados del assets a la sd hasta el momento
    Posición 4 -> N�mero total de ficheros a procesar del assets a la sd hasta el momento
    Posición 5 -> Ruta de la aplicaci�n actualizada a la sd (una vez finaliza la copia de assets a sd)
    Posición 6 -> Flag que determina si estoy copiando la instalacion inicial (0) o estoy actualizando ficheros (1)
    
    Se hacen las acciones correspondientes para mostrar el mensaje al usuario cuando la 
    operaci�n haya finalizado correctamente. */

    if (datosActualizacion[6] == "0") {
        // Si estoy en proceso de copia de assets a sd, actualizo la barra de progreso de la ruta
        GetObjGlobales().numFicherosProcesadosAssets = datosActualizacion[3];
        GetObjGlobales().numFicherosTotalAssets = datosActualizacion[4];
        actualizarProgressBarRuta();
    }
    else {

        // Si estoy en proceso de actualizaci�n de ficheros.
        cambiarLiteralesProgressBar();

        // Actualizo a la nueva ruta
        GetObjGlobales().rutaroot = datosActualizacion[5];

        // Preparo la barra de progreso de actualizaci�n de ficheros
        GetObjGlobales().numFicherosDescargados = parseInt(datosActualizacion[0]);
        GetObjGlobales().numFicherosActualizar = parseInt(datosActualizacion[1]);
        GetObjGlobales().tamFicherosDescargar = parseInt(datosActualizacion[2]);

        actualizarProgressBar();

        if (datosActualizacion[0] == datosActualizacion[1]) {
            // Se muestra el botón Aceptar cuando haya finalizado la actualizaci�n
            $('#btnActualizarProgress').show();
            $('#actualizacionProgressOk').show();
        }
    }

}

function setFicherosDescargadosError(error) {
    if (error != null) {

        $('#CapaProgressBar').hide();
        /* Se hacen las acciones correspondientes para mostrar el error al realizar la actualizaci�n */
        //alert(GetObjGlobales().DameTraduccion(16));
        if (error == "Error de lectura en la SD") {
            // La SD no est� montada o s�lo tiene permisos de lectura.
            mostrarErrorSD();
        } else {
            mostrarError();
        }
    }

    // Se cierra la aplicaci�n
    //cerrarApp();
}
function obtenerModoConexion() {

    //DDC var networkState = navigator.network.connection.type;
    var networkState = navigator.connection.type;

    var states = {};
    states[Connection.UNKNOWN] = 'Conexion desconocida';
    states[Connection.ETHERNET] = 'Ethernet';
    states[Connection.WIFI] = 'Wifi';
    states[Connection.CELL_2G] = '2G';
    states[Connection.CELL_3G] = '3Gn';
    states[Connection.CELL_4G] = '4G';
    states[Connection.CELL] = 'Cell generic connection';
    states[Connection.NONE] = 'Sin conexion';

    GetObjGlobales().modoConexion = states[networkState];
}


function mostrarMsgSinInternet() {

    if (GetObjGlobales().EsSpanish()) {
        $('#dialog-confirm').html('<p>' + _msgInfo + 'El dispositivo no tiene conexi' + _o + 'n a internet, por tanto, no se ha podido comprobar si hay actualizaciones pendientes.</p>');
        MsgSi('Sin conexi' + _o + 'n a internet', null);
    } else {

        $('#dialog-confirm').html('<p>' + _msgInfo + 'The device is not connected to the Internet. It has not been possible to check whether there are any updates pending.</p>');
        MsgSi('Without internet connection', null);
    }
}


function mostrarMsgSinPermisos() {

    if (GetObjGlobales().EsSpanish()) {
        $('#dialog-confirm').html('<p>' + _msgInfo + 'La aplicaci' + _o + 'n no tiene permisos de escritura, por tanto, no se ha podido comprobar si hay actualizaciones pendientes.</p>');
        MsgSi('Sin permisos de escritura', null);
    } else {

        $('#dialog-confirm').html('<p>' + _msgInfo + 'The application has not write to storage access. It has not been possible to check whether there are any updates pending.</p>');
        MsgSi('Without write to storage access', null);
    }
}

function mostrarError() {
    if (GetObjGlobales().EsSpanish()) {
        //		$('#dialog-confirm').html('<p>' + _msgError + 'Se ha producido un error inesperado. La aplicaci'+_o+'n se cerrar' + _a + '.</p>'); 
        $('#dialog-confirm').html('<p>' + _msgError + 'Se ha producido un error inesperado. Revise la conexi'+_o+'n a internet.</p>');
        //MsgSi('Error',cerrarApp);
        MsgSi('Error');
    } else {
        $('#dialog-confirm').html('<p>' + _msgError + 'There was an unexpected error. Check your internet connection.</p>');
        //	MsgSi('Error',cerrarApp);
        MsgSi('Error');
    }
}

function mostrarErrorSD() {

    if (GetObjGlobales().EsSpanish()) {

        $('#dialog-confirm').html('<p>' + _msgError + 'Error de escritura en la tarjeta SD. Revise que la SD est' + _a + ' insertada y tiene permisos de escritura. La aplicaci' + _o + 'n se cerrar' + _a + '.</p>');
        MsgSi('Error', cerrarApp);
    } else {
        $('#dialog-confirm').html('<p>' + _msgError + 'SD card writing error. Check the SD card is inserted correctly and has write permission. The application will now close.</p>');
        MsgSi('Error', cerrarApp);
    }
}



jQuery.fn.inicializarProgressBar = function () {
    var vPb = this;

    // calling original progressbar
    $(vPb).children('.pbar').progressbar();

    // display current positions and progress
    $(vPb).children('.percent').html('<b> 0% </b>');
    $(vPb).children('.elapsed').html('<b> 0/0 </b>');
    $(vPb).children('.pbar').children('.ui-progressbar-value').css('width', '0%');
}

function actualizarProgressBar() {
    var Porc = (GetObjGlobales().numFicherosDescargados * 100) / GetObjGlobales().numFicherosActualizar;
    var vPb = ("#progressBar");

    $(vPb).children('.percent').html('<b> ' + Porc.toFixed(1) + '% </b>');
    $(vPb).children('.elapsed').html('<b> ' + GetObjGlobales().numFicherosDescargados + '/' + GetObjGlobales().numFicherosActualizar + '</b>');
    $(vPb).children('.pbar').children('.ui-progressbar-value').css('width', Porc + '%');

    // cuando terminemos
    if (Porc >= 100) {
        $(vPb).children('.percent').html('<b>100%</b>');
        //$(vPb).children('.elapsed').html('Finished');

        //si actualizamos desde ajustes
        if ($('#contenedorActualizaciones').length) {
            $('#contenedorActualizaciones').hide();
        }

        $("#actualizacionProgressOk").show();
        $("#PnActualizarProgress").show();
    }
}

function actualizarProgressBarRuta() {
    var Porc;
    if (GetObjGlobales().numFicherosTotalAssets == 0) {
        Porc = 100;
    } else {
        Porc = (GetObjGlobales().numFicherosProcesadosAssets * 100) / GetObjGlobales().numFicherosTotalAssets;
    }

    var vPb = ("#progressBar");

    $(vPb).children('.percent').html('<b> ' + Porc.toFixed(1) + '% </b>');
    $(vPb).children('.elapsed').html('<b> ' + GetObjGlobales().numFicherosProcesadosAssets + '/' + GetObjGlobales().numFicherosTotalAssets + '</b>');
    $(vPb).children('.pbar').children('.ui-progressbar-value').css('width', Porc + '%');

    // cuando terminemos
    if (Porc >= 100) {
        cambiarLiteralesProgressBar();
        $(vPb).children('.percent').html('<b>100%</b>');
        //$(vPb).children('.elapsed').html('Finished');

        //si actualizamos desde ajustes
        if ($('#contenedorActualizaciones').length) {
            $('#contenedorActualizaciones').hide();
        }
    }
}

function iniciarObtencionRuta() {

    if (GetObjGlobales().EsSpanish()) {
        $('#btnActualizarProgress').attr('value', 'Aceptar');
        $('#ActualizarTxt').html('<b>Preparando los datos de instalaci' + _o + 'n. Este proceso se realizar' + _a + ' una ' + _u + 'nica vez...</b>');
        $('#actualizacionProgressOk').html('Carga de datos realizada correctamente');
    } else {
        $('#btnActualizarProgress').attr('value', 'Ok');
        $('#ActualizarTxt').html('<b>Preparing installation information. This process will be done once only...</b>');
        $('#actualizacionProgressOk').html('Successfully data load');
    }

    iniciarProgressBar();
}

function cambiarLiteralesProgressBar() {
    if (GetObjGlobales().EsSpanish()) {
        $('#btnActualizarProgress').attr('value', 'Aceptar');
        $('#ActualizarTxt').html('<b>Actualizando ...</b>');
        $('#actualizacionProgressOk').html('Se han actualizado los datos correctamente.');
    } else {
        $('#ActualizarTxt').html('<b>Updating ...</b>');
        $('#btnActualizarProgress').attr('value', 'Ok');
        $('#actualizacionProgressOk').html('The data has been updated successfully.');
    }
}

//si presiona en ACEPTAR cuando se le pregunta por actualizar
function iniciarActualizacion() {

    if (GetObjGlobales().EsSpanish()) {
        $('#btnActualizarProgress').attr('value', 'Aceptar');
        $('#ActualizarTxt').html('<b>Actualizando ...</b>');
        $('#actualizacionProgressOk').html('Se han actualizado los datos correctamente');
    } else {
        $('#ActualizarTxt').html('<b>Updating ...</b>');
        $('#btnActualizarProgress').attr('value', 'Ok');
        $('#actualizacionProgressOk').html('The data has been updated successfully');
    }

    iniciarObtencionRuta();
    iniciarProgressBar();
    //window.plugins.interfazCatalogoNativa.actualizar([], setFicherosDescargadosOk, setFicherosDescargadosError);
    window.actualizar([], setFicherosDescargadosOk, setFicherosDescargadosError);
}

function iniciarProgressBar() {
    $("#actualizacionProgressOk").hide();
    $("#PnActualizarProgress").hide();
    $('#CapaProgressBar').show();
    $('#progressBar').inicializarProgressBar();
}

function pintarActualizacionPendiente() {
    setTimeout(function () { $("#minforma").css('background-image', 'url(img/Notif2.png)'); }, 300);
}

function ocultarActualizacionPendiente() {
	setTimeout(function () { $("#minforma").css('background-image', 'url(img/flecha_celeste.png)'); }, 300);
}

function comprobarActualizacionPendiente() {

    // Se comienza la comprobaci�n de actualizaciones pendientes
    if (localStorage.getItem('comprobarActualizaciones') == "true") {
        setTimeout(window.comprobarActualizacion([loadJSONParamToComprobarActualuzacionPlugin()], setActualizacionPendienteOk, setActualizacionPendienteError), 0);
        localStorage.setItem('comprobarActualizaciones', false);
    }
}

function loadJSONParamToComprobarActualuzacionPlugin() {
    var param = { ferias: [] };

    if (GetObjGlobales().existenFeriasMultiples) {
        param.existenFeriasMultiples = "1";

        for (i in GetObjGlobales().FeriasMultiples) {
            param.ferias.push({ "codigoFeria": GetObjGlobales().FeriasMultiples[i].cod, "descripcionFeria": GetObjGlobales().FeriasMultiples[i].desc });
        }
    } else {
        param.existenFeriasMultiples = "0";
    }

    return param;
}

function mostrarActualizacionPendiente() {
    if (GetObjGlobales().ActualizacionPendiente) {
        //Pintar la notificaci�n
        pintarActualizacionPendiente();

        //si el modo de conexi�n es WIFI y existen actualizaciones pendientes, 
        //preguntamos al usuario si quiere actualizar
        if (GetObjGlobales().hayConexion && GetObjGlobales().mostrarMsgActualizacion) {
            GetObjGlobales().mostrarMsgActualizacion = false;

            if (GetObjGlobales().EsSpanish()) {
                $('#dialog-confirm').html('<p>' + _msgInfo + 'Hay una nueva actualizaci' + _o + 'n de datos en espera. El tama' + _ene + 'o aproximado de descarga es de ' + GetObjGlobales().tamFicherosDescargar + 'Mb. ' + _interr + 'Quiere realizarla? </p>');

                MsgSiNo('Actualizaci' + _o + 'n disponible', iniciarActualizacion, null);
            } else {
                $('#dialog-confirm').html('<p>' + _msgInfo + 'There is new data available. The approximate size of the download is ' + GetObjGlobales().tamFicherosDescargar + ' Mb. Do you wish to download it?</p>');

                MsgSiNo('Update available', iniciarActualizacion, null);
            }
        }
    }else {
        //Ocultar la notificaci�n
        ocultarActualizacionPendiente();

    }
}

/*function setObtenerCodigoQROK(codigoQR){
	kendoNameSpace.obtenerQRPluginOK(codigoQR);
}

function setObtenerCodigoQRError(error){
	kendoNameSpace.obtenerQRPluginError(error);
}

function setObtenerSincronizarCodigosQROK(codigosActualizados){
	kendoNameSpace.sincronizarQRPluginOK(codigosActualizados);
}

function setObtenerSincronizarCodigsoQRError(error){
	kendoNameSpace.sincronizarQRPluginError(error);
}*/