var _a = '\u00e1';
var _e ='\u00e9';
var _i = '\u00ed';
var _o ='\u00f3';
var _u ='\u00fa';
var _n = '\u00f1';
var _interr = '\u00bf'; 
var _ene = '\u00f1';
var _msgInfo = '<span class="ui-icon ui-icon-lightbulb" style="float: left; margin: 0 7px 20px 0;"></span>';
var _msgError = '<span class="ui-icon ui-icon-alert" style="float: left; margin: 0 7px 20px 0;"></span>';
var last_click_time_cw = 0;
var click_time_cw = 0;

$(document).ready(function () {
    var userAgent, ieReg, ie, edge;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    ie = ieReg.test(userAgent);
    edge = navigator.userAgent.indexOf('Edge') >= 0;

    if (ie || edge) {
        $('#txtActividad, #txPonente').css({ "height": "45px", "width": "100%" });
    }

});

//if(document.addEventListener)
//    document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);

//if (!document.addEventListener) {
    //document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);


function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]); vars[hash[0]] = hash[1];
    }
    return vars;
}

function getUrlVar(name) {
    return getUrlVars()[name];
}

function EsLetraAlfabeto(letra) {
    letra = letra.toUpperCase();
    if (['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
    'M', 'N', '�', 'O', 'P', 'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X', 'Y', 'Z'].indexOf(normalize(letra)) >= 0)
        return true;
    else
        return false;
}


var normalize = (function () {
    var from = "����������������������������������������������",
      to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuu��cc",
      mapping = {};

    for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

    return function (str) {
        var ret = [];
        for (var i = 0, j = str.length; i < j; i++) {
            var c = str.charAt(i);
            if (mapping.hasOwnProperty(str.charAt(i)))
                ret.push(mapping[c]);
            else
                ret.push(c);
        }
        return ret.join('');
    }

})();

function getQueryVariable(variable) {
	  var query = window.location.search.substring(1); 
	  var vars = query.split("&"); 
	  for (var i=0;i<vars.length;i++) { 
		  var pair = vars[i].split("=");
		  if (pair[0] == variable) {
		      if (pair[1] == "undefined") {
		          return "";
		      }
		      else {
		          return pair[1];
		      }
		  }
        }
        return "";
}


function getParameterByName(name) { 
	name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]"); 
var regexS = "[\\?&]" + name + "=([^&#]*)"; 
var regex = new RegExp(regexS); 
var results = regex.exec(window.location.href); 
if (results == null) { return ""; } else { 
	return decodeURIComponent(results[1].replace(/\+/g, " ")); } }

function sacaParametro(name, ruta) { name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]"); var regexS = "[\\?&]" + name + "=([^&#]*)"; var regex = new RegExp(regexS); var results = regex.exec(ruta); if (results == null) { return ""; } else { return decodeURIComponent(results[1].replace(/\+/g, " ")); } }

function cortaPalabras(palabra, merme) {
    var ancho = $(window).width() - merme;
    var len = Math.round((ancho / 11));
    palabra = String(palabra);
    var lTxt = palabra.length;
    if (palabra != "undefined") {
        palabra = palabra.substr(0, len - 3);
        if (lTxt > (len - 3)) palabra += "...";
        return palabra;
    } else {
    return "";
    }
}

function cortaPalabrasListaEmpresas(palabra, merme) {
    var ancho = $(window).width() - merme;
    var len = Math.round((ancho / 9));
    var lTxt = palabra.length;
    palabra = palabra.substr(0, len - 3);
    if (lTxt > (len - 3)) palabra += "...";
    return palabra;
}

function GetTipoEventoParaClick() {
    return (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) ? 'touchstart' : 'click';
}


function fotilla(ancho, alto, cual, maxAncho, maxAlto) {


    if ((ancho <= 0) && (alto <= 0)) {
        // cual.attr('width', maxAncho);
        // cual.attr('height', maxAlto);

    }
    else {
        if ((ancho > maxAncho) || (alto > maxAlto)) {
            //Se calculan las coordenadas en caso de que ajustaramos el ancho de la  imagen al ancho disponible
            coorA1 = maxAncho
            coorH1 = Math.floor((maxAncho * alto) / ancho)


            //Se calculan las coordenadas en caso de que ajustaramos el alto de la imagen al alto disponible
            coorA2 = Math.floor((maxAlto * ancho) / alto)
            coorH2 = maxAlto

            //En caso de que se ajusten mejor las coordenadas del primer caso las cogemos
            //si no, se cogen las sel segundo caso

            if ((coorA1 <= maxAncho) && (coorH1 <= maxAlto)) {
                cual.attr('width', coorA1);
                cual.attr('height', coorH1);
            }
            else {
                if ((coorA2 <= maxAncho) && (coorH2 <= maxAlto)) {
                    cual.attr('width', coorA2);
                    cual.attr('height', coorH2);
                }

            }
        }
        else {
            cual.attr('width', ancho);
            cual.attr('height', alto);
        }
        //si ambas imagenes cupieran en el espacio disponible, se coge la del primer caso
    }
}

function online() { return navigator.onLine ? true : false; }


function cerrarApp() {
	navigator.app.exitApp();
}


function cargarScript(urlJs) {
	  var script = document.createElement('script');
	  script.setAttribute('type', 'text/javascript');
	  script.setAttribute('src', urlJs);
	  document.getElementsByTagName('head')[0].appendChild(script);
}

 function isDoubleClickBug(event) // click wall
 { 
        click_time_cw = (new Date()).getTime();
	    if (click_time_cw && (click_time_cw - last_click_time_cw) < 600) {
	        event.stopImmediatePropagation();
	        event.preventDefault();
	        return true;
	    }
	    last_click_time_cw = click_time_cw;
	    event.stopImmediatePropagation();
	    event.stopPropagation();
	    return false;
 }

 function GetIDModoCompatible()
 {    
     return ((document.documentMode) && (document.documentMode > 7)) || !(document.documentMode);
 }

 function isModoPrivadoEnabled() {
     try {
         $.DSt.set("AppFeria", "test");
     }
     catch (err) {
         if (err != undefined && err.message.length > 0) {
             var n = err.message.toUpperCase().indexOf("QUOTA");
             if (n > -1)
                 alert("Error al ejecutar la aplicaci�n, debe salir del modo privado de navegaci�n  \n\n Error running the application, you don't have to run it under private mode ");
         }
     }
 }
 
 function getAnioFromFecha(fechaCarga) {
     var res = fechaCarga.split("/");
     return parseInt(res[2]);
 }

 function getDiaFromFecha(fechaCarga) {
     return fechaCarga.substring(0, 2);
 }

 function getMesFromCarga(fechaCarga, idio) {
     var res = fechaCarga.split("/");
     var nMes = parseInt(res[1], 10) - 1;

     var month = new Array();
     month[0] = "January";
     month[1] = "February";
     month[2] = "March";
     month[3] = "April";
     month[4] = "May";
     month[5] = "June";
     month[6] = "July";
     month[7] = "August";
     month[8] = "September";
     month[9] = "October";
     month[10] = "November";
     month[11] = "December";

     var mes = new Array();
     mes[0] = "enero";
     mes[1] = "febrero";
     mes[2] = "marzo";
     mes[3] = "abril";
     mes[4] = "mayo";
     mes[5] = "junio";
     mes[6] = "julio";
     mes[7] = "agosto";
     mes[8] = "septiembre";
     mes[9] = "octubre";
     mes[10] = "noviembre";
     mes[11] = "diciembre";

     var mesp = new Array();
     mesp[0] = "Janeiro";
     mesp[1] = "Fevereiro";
     mesp[2] = "Mar�o";
     mesp[3] = "Abril";
     mesp[4] = "Maio";
     mesp[5] = "Junho";
     mesp[6] = "Julho";
     mesp[7] = "Agosto";
     mesp[8] = "Setembro";
     mesp[9] = "Outubro";
     mesp[10] = "Novembro";
     mesp[11] = "Dezembro";

     if (idio == "es") {
         return mes[nMes];
     }
     else if (idio == "pt")
     {
         return mesp[nMes];
     }
     else
     {
         return month[nMes];
     }
 }

