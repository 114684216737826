/// <reference path="globales.js" />

    function InicializarActividadesPatrocinador()
    {
        //debugger;
        addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Patrocinadores de Feria', '', 0);
        if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Patrocinadores'); } else { GetObjGlobales().titulaCabecera('Sponsors'); }
        cargaFicheroHTMLConAccion(GetObjGlobales().feriaPrincipal, 'patrocinadoresifema.html', 'patrocinadoresifema_en.html', $('#conteen'), InicializaPatrocinador);
    }

    function InicializaPatrocinador() 
    {
        // Se cargan las imágenes de los patrocinadores
        var arrayImagenes = $("li.PatrocinadorLElem").find("img");
        $.each(arrayImagenes, function (ind, imagen) {
            $(imagen).attr("src", GetObjGlobales().getRutaFichero("R", $(imagen).attr("src"), GetObjGlobales().feriaPrincipal));
        });

        // Se continua la ejecución
        addEventoNavega('[rel="enlace"]', 'href');         
        $('.AgendaPatrocinador').off();
        $('.AgendaPatrocinador').on(GetTipoEventoParaClick(), ActivaDesactiva);
        $('.PatrocinadorLista').css('display', 'block');
        setTimeout("elScroll('wrapperPatrocinadores');myScroll.refresh();", 300);//Esto habria que hacerlo mejor. NO se en que momento se han pintado todos los patrocinadores para poder hacer el refresh.
    }

    function ActivaDesactiva(e) 
    {
        ActivaDesactivaListaActividadByElement($(this).next('ul').get(0));
    }

    function ActivaDesactivaListaActividadById(nombre) 
    {
        if (document.getElementById(nombre).style.display == "none")
            document.getElementById(nombre).style.display = 'block';
        else
            document.getElementById(nombre).style.display = 'none';
        myScroll.refresh();
    }

    function ActivaDesactivaListaActividadByElement(elemento) 
    {
        if (elemento.style.display == "none")
            elemento.style.display = 'block';
        else
            elemento.style.display = 'none';
        myScroll.refresh();
    }

    function mostrarBannerPatrocinadores()
    {
        if (GetObjGlobales().mostrarPatrocinadores == 'S')
        {
            $('.btnPatrocinadores').attr({
                src: GetObjGlobales().getRutaFichero("R", "patrocinadores.jpg", GetObjGlobales().feriaPrincipal)
            });
            $('.btnPatrocinadores').show();
            $('.btnPatrocinadores').click(function () {
                mostrarPatrocinadores();
            });
        }
    }

    function mostrarPatrocinadores()
    {
        window.location.href = '#ajax_patrocinadores.html';
    }




