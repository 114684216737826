/// <reference path="../scripts/rsvp.js" />

// Este archivo es identico en Catalogo Movil y Catalogo Web.
var oProxyDatosCatalogo;

function GetObjProxyDatosCatalogo()
{
    if (oProxyDatosCatalogo == undefined)
    {
        oProxyDatosCatalogo = new ObjProxyDatosCatalogo();
    }
    return oProxyDatosCatalogo;
}

function ObjProxyDatosCatalogo()
{
    var that = this;

    var promesaIndiceEmpresasStand;

    this.getEmpresasStand = function (stand)
    {
        var url = GetObjGlobales().wsListaExpositoresPath +
            "GetIndiceEmpresasPais?Feria=" + GetObjGlobales().getFeria() +
            "&tipoBusqueda=TODO&numero=999&pagina=1&Tipoindice=ST&idindice=" + stand + "&ordenar=N";
        return new RSVP.Promise(function (resolve, reject)
        {
            $.ajax(url,
                {
                    success: function (response)
                    {
                        var datos = (eval(response.d));
                        if (datos)
                            resolve(datos.d);
                        else
                            resolve(datos);
                    },
                    error: function (reason)
                    {
                        reject(reason);
                    }
                });
        });
    }

    this.getEmpresasStandApp = function (stand)
    {
        return new RSVP.Promise(function (resolve, reject)
        {
            that.getIndiceEmpresasStand().then(function (xml)
            {
                var empresas = new Array();
                $(xml).find('st').each(function ()
                {
                    if ($(this).attr('id') == stand)
                    {
                        $(this).find('e').each(function ()
                        {
                            empresas.push({
                                id: $(this).attr('id'), no: $(this).attr('no'),
                                stand: stand, te: $(this).attr('te')
                            });
                        });
                    }
                });
                if (empresas.length > 0)
                {
                    resolve(empresas);
                }
                else
                {
                    resolve('');
                }
            });
        });
    }

    this.getIndiceEmpresasStand = function ()
    {
        that.promesaIndiceEmpresasStand = 
            new RSVP.Promise(function (resolve, reject)
            {
                $.ajax({
                    type: "GET",
                    url: GetObjGlobales().getRutaFichero("X", "indice_stands_empresas.xml"),
                    dataType: "xml",
                    success: function (xml)
                    {
                        resolve(xml);
                    },
                    error: function (reason)
                    {
                        reject(reason);
                    }
                });
            });

        return that.promesaIndiceEmpresasStand;
    }
}