
(function (kendoNameSpace) {
    'use strict';
    //below you place anything private you don't want exposed in the viewModel
    //below we create the viewModel
   
    kendoNameSpace.ley =
    {//create viewModel namespace in global i.e. namespace.[viewModel Name], to expose to global
    	
        viewModel: kendo.observable({
            //the actual model
            description: "Description",
            //titulo: "Aviso Legal",
            isDescriptionShown: false,
            show: function () {//set data on scrollview
                //este bind es para que se enganchen los botones de navegación de la cabecera
            	 kendo.bind($("#contenedor_paginas_kendo > #ajax_ley"), this);
                 GetObjGlobales().cargaIdioma();
                 if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Aviso Legal'); } else { GetObjGlobales().titulaCabecera('Legal Notice'); }
                 
            },
            onHome: function () {
                kendoNameSpace.navegacion.NavegaHome();
            },
            onBack: function (e) {
                kendoNameSpace.navegacion.NavegaBack();
            }
        })
    }
    
})(kendoNameSpace); //pass in global namespace