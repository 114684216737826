/// <reference path="../scripts/rsvp.js" />
// Este archivo es identico en Catalogo Movil y Catalogo Web.
var oEmpresasStand;

//EsApp quiere decir realmente si es la app de ferias o es el catalogo web nuevo
function GetObjEmpresasStand(EsApp) {
    if (oEmpresasStand == undefined) {
        oEmpresasStand = new ObjEmpresasStand(EsApp);
    }
    return oEmpresasStand;
}

function ObjEmpresasStand(EsApp) {
    var that = this;
    // variables para el dialogo de lista de empresas
    var MyScrollVentana;
    //

    this.MostrarEmpresasStand = function (X, Y, stands, dgn, zoom) {
        var infoPunto =
            {
                X: X,
                Y: Y,
                map:
                    {
                        width: $("#mappab").width() * zoom,
                        height: $("#mappab").height() * zoom,
                        offsetLeft: $("#mappab").offset().left,
                        offsetTop: $("#mappab").offset().top
                    }
            };

        var stand = that.GetPuntoStand(infoPunto, stands, dgn);

        if (stand && stand.length > 0) {
            var PromesaEmpresasStands;

            if (!GetObjGlobales().EsOffline()) {
                PromesaEmpresasStands = GetObjProxyDatosCatalogo().getEmpresasStand(stand);
            }
            else {
                PromesaEmpresasStands = GetObjProxyDatosCatalogo().getEmpresasStandApp(stand);
            }

            //Existen diferentes "sabores" de JavaScript, unos de ellos es el ES3.
            //IE8 es un navegador ES3.
            //En estos navegadores el uso de metodo catch es via promise['catch'] en lugar de promise.catch.
            PromesaEmpresasStands
                .then
                (
                    function (datosEmpresasStand) {
                        return that.PopUpEmpresasStand(infoPunto, stand, datosEmpresasStand, EsApp);
                    }
                )
                ['catch']
                (
                    function (error) {
                        console.log("ERROR", error);
                    }
                );
        }
    }

    this.GetPuntoStand = function (infoPunto, stands, dgn) {
        var Y = infoPunto.map.height - infoPunto.Y + infoPunto.map.offsetTop;   //+ 50; //Le damos la vuelta a la Y para adaptarnos a los ejes de coordenadas que maneja los dgn
        var X = infoPunto.X - infoPunto.map.offsetLeft;
        var XDGN = ((X / infoPunto.map.width) * dgn.anchodgn_stand) + dgn.xmindgn_stand;
        var YDGN = ((Y / infoPunto.map.height) * dgn.altodgn_stand) + dgn.ymindgn_stand;
        var puntoTest =
            {
                x: XDGN,
                y: YDGN
            };

        //Busca el stand en el archivo generado con los datos de GIS.
        for (i = 0; i < stands.length; i++) {
            if (stands[i].pabellon == vPabellonActual) {
                if (isPointInPoly(stands[i].puntos, puntoTest)) {
                    return stands[i].numstand;
                }
            }
        }

        return '';
    }

    this.InicializarScrollVentana = function (quien) {
        //************** CREAR SCROLLS **********************************///

        MyScrollVentana = new iScroll(quien, {
            useTransform: true,
            zoom: true,
            zoomMin: 1,
            zoomMax: 6,
            bounce: false,
            momentum: false,
            vScrollbar: true
        });

    }
    this.PopUpEmpresasStand = function (infoPunto, stand, data, EsApp) {


        if (data && data.length > 0) {
            if (data.length == 1) {
                GetObjControlador().navegarAFichaEmpresa(data[0].id, stand);
            }
            else {
                // limpiamos todo el resto de elementos
                $("[aria-describedby='dialog-stands-empresas']").remove();
                $("#dialog-stands-empresas").remove();
                // insertamos el dialogo antes del div stand-in-map que existe en catalogo y app
                $('<div id="dialog-stands-empresas"></div>').insertBefore("#stand-in-map");
                var ventana = $("#dialog-stands-empresas");
                ventana.append("<div id='dialog-stands-empresas-scroller' class='fondoBlancoListaCoexpos scroller'></div>");
                var ventanaScroller = $('#dialog-stands-empresas-scroller');
                ventanaScroller.append("<ul id='list-stands-empresas'></ul>");
                var listaempresas = $("#list-stands-empresas");
				var elem = '';
                for (i = 0; i < data.length; i++) {
                    elem = data[i].id.split('_');
                    if ((data[i].te == 'E') && (elem[1] == 1))
                        listaempresas.append("<li id='list-stands-empresas" + i + "' emp='" + data[i].id + "' st='" + stand + "'><a style='color:blue'>" + data[i].no + "</a></li>");
                    else
                        listaempresas.append("<li id='list-stands-empresas" + i + "' emp='" + data[i].id + "' st='" + stand + "'><a>" + data[i].no + "</a></li>");
                    $("#list-stands-empresas" + i).click(function (e) {
                        e.stopPropagation();
                        GetObjControlador().navegarAFichaEmpresa($(this).attr('emp'), $(this).attr('st'));
                        $("#dialog-stands-empresas").dialog("close");
                    });
                }

                var coefAlto;
                var coefAncho = 0.85;
                if (data.length <= 5)
                    coefAlto = 0.40;
                else
                    coefAlto = 0.75;
                // PopUpStands.
                if (GetObjGlobales().EsSpanish()) {
                    var dialogo = $("#dialog-stands-empresas").dialog({
                        title: stand,
                        //dialogClass: 'noTitleStuff',
                        autoOpen: false,
                        width: $(window).width() * coefAncho,
                        height: $(window).height() * coefAlto,
                        modal: true,
                        buttons: {
                            "Cerrar": function () {
                                $(this).dialog("close");
                            }
                        }
                    });
                }
                else
                {
                    var dialogo = $("#dialog-stands-empresas").dialog({
                        title: stand,
                        //dialogClass: 'noTitleStuff',
                        autoOpen: false,
                        width: $(window).width() * coefAncho,
                        height: $(window).height() * coefAlto,
                        modal: true,
                        buttons: {
                            "Close": function () {
                                $(this).dialog("close");
                            }
                        }
                    });
                }

                dialogo.css("height", "auto");
                //DDC Por defecto al centro de la pantalla. Para los móviles es mejor
                //left, right, center, top, bottom
/*                var X = infoPunto.X - infoPunto.map.offsetLeft;
                var Y = infoPunto.Y - infoPunto.map.offsetTop;
                if (X < (infoPunto.map.width / 2)) {
                    if (Y < (infoPunto.map.height / 2)) {
                        $("#dialog-stands-empresas").dialog("option", "position", { my: "left top", at: "left+" + X + " top+" + Y, of: $("#mappab") });
                    } else {
                        $("#dialog-stands-empresas").dialog("option", "position", { my: "left bottom", at: "left+" + X + " top+" + Y, of: $("#mappab") });
                    }
                } else {
                    if (Y < (infoPunto.map.height / 2)) {
                        $("#dialog-stands-empresas").dialog("option", "position", { my: "right top", at: "left+" + X + " top+" + Y, of: $("#mappab") });
                    } else {
                        $("#dialog-stands-empresas").dialog("option", "position", { my: "right bottom", at: "left+" + X + " top+" + Y, of: $("#mappab") });
                    }
                }*/
                //$("#dialog-stands-empresas").dialog("option", "maxHeight", (infoPunto.map.height / 3));
                $("#dialog-stands-empresas").dialog("open");
                // el iscroll solo se usa en la app
                //EsApp quiere decir realmente si es la app de ferias o es el catalogo web nuevo
                if (EsApp) setTimeout(function () { that.InicializarScrollVentana("dialog-stands-empresas"); }, 100);
            }
        }
    }
}