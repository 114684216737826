var lastClickTime = 0;
var oAgenda;
var PintaCabecera = 0;
var TieneSubCategorias = 0;

function GetObjAgenda() {
    if (oAgenda == undefined) {
        oAgenda = new ObjAgenda();
    }
    return oAgenda;
}
   
function ObjAgenda() { };
ObjAgenda.prototype = {
    dia: '',
    idio: 'es',
    modoPIM: 'N',
    extFile: '',
    agenda: '',
    sala: '',
    feria: '',
    ponencia: '',
    fecha: '',
    lugar: '',
    ponenecias: '',
    ponentes: '',
    resumen: '',
    fechaylugar: '',
    documentos: '',
    favorito: '',
    guardafav: '',
    guardaCal: '',
    descargaArchivo: '',
    vermas: '',
    titulo: '',
    categoria: '',
    organizacion: '',
    todos: '',
    colabora: '',
    contacto: '',
    masinfo: '',
    divActivo: '',
    outlook: '',
    extIndiceAgenda: '',
    almacenFavoritos: '',
    arrayFavoritos: '',
    mostrarIndice: false,
    hayoutlook: true,
    eventoRefresh: '',
    EstiloCategoriaBotones: true,
    eventoAfterPintarIndice: '',
    eventoAfterPintarFichaEvento: '',
    eventoAfterPintarFichaPonente: '',
    FotoIndiceGrande: true,
    NavegarAgenda: '',
    activoDia: 0,
    activoTematica: 0,
    activoUbicacion: 0,
    fotoGrande: '',
    fotoMini: '',

    //[ALTIA] array con estilos corporativos para su carga dinamica
    arrayEstilos: new Array(),
    //FIN [ALTIA]

    inicializa: function () {
        this.InicializaAgenda(getQueryVariable('idioma').toLowerCase(),
                         getQueryVariable('feria').toUpperCase(),
                         getQueryVariable('agenda'),
                         getQueryVariable('MODO').toUpperCase(),
                         true);
    },
    InicializaAgenda: function (lidioma, lferia, lagenda, lmodo, lhayoutlook) {
        if (lmodo == "PIM") {
            this.modoPIM = 'S';
            //document.addEventListener('contextmenu', event => event.preventDefault());
            document.addEventListener('contextmenu', function(e) {
                e.preventDefault();
            },false);
        }
        
        this.hayoutlook = lhayoutlook && (this.modoPIM != 'S');
        this.arrayFavoritos = new Array();
        if (lidioma != "")
            this.idio = lidioma;
        this.feria = lferia;
        this.divActivo = "dvDia";
        if (lagenda != '') {
            this.agenda = lagenda;
            this.almacenFavoritos = "AGENDA_" + this.feria + "_" + this.agenda;
            this.mostrarIndice = false;
        }
        else {
            this.agenda = '';
            this.mostrarIndice = true;
        }
        if (this.idio == "es") {
            this.extIndiceAgenda = "_ES.xml";
            this.extFile = "_" + this.agenda + "_ES.xml";
            this.dia = "Día ";
            this.sala = "Lugar: ";
            this.ponencia = "Ponencia";
            this.fecha = "Fecha";
            this.lugar = "Lugar";
            this.ponenecias = "Ponencias ";
            this.resumen = "Resumen";
            this.favorito = "Favorito";
            this.guardafav = "Guardar como favorito";
            this.guardaCal = "Agendar";
            this.vermas = "Ver más";
            this.ponentes = "Ponentes";
            this.fechaylugar = "Fecha y Lugar";
            this.descargaArchivo = "Descargar archivo";
            this.documentos = "Documentación Adicional";
            this.titulo = "Título: ";
            this.categoria = "Temática: "; // "Categoría: ";
            this.organizacion = "Organiza: ";
            this.colabora = "Colabora: ";
            this.contacto = "Contacto: ";
            this.masinfo = "Más información: ";
            this.outlook = "IFEMA - Feria de Madrid ";
            this.todos = "Todos";
        }
        else if (this.idio == "pt") {
            this.extIndiceAgenda = "_PT.xml";
            this.extFile = "_" + this.agenda + "_PT.xml";
            this.dia = "Dia ";
            this.sala = "Local: ";
            this.ponencia = "De papel ";
            this.fecha = "Data";
            this.lugar = "Lugar";
            this.ponenecias = "Apresentações";
            this.resumen = "Resumo";
            this.favorito = "Favorito";
            this.guardafav = "Guardar como favorito"
            this.guardaCal = "Agendar";
            this.vermas = "Ver mais";
            this.ponentes = "Orador";
            this.fechaylugar = "Data e lugar";
            this.descargaArchivo = "Descarregar arquivos";
            this.documentos = "Documentação adicional";
            this.titulo = "Título: ";
            this.categoria = "Temática: ";
            this.organizacion = "Organizador: ";
            this.colabora = "Colaborador: ";
            this.contacto = "Contato: ";
            this.masinfo = "Mais informação: ";
            this.outlook = "IFEMA ";
            this.todos = "All";
        }
        else {
            this.extIndiceAgenda = "_EN.xml";
            this.extFile = "_" + this.agenda + "_EN.xml";
            this.dia = "Day ";
            this.sala = "Place: ";
            this.ponencia = "Presentation ";
            this.fecha = "Date";
            this.lugar = "Place";
            this.ponenecias = "Presentations";
            this.resumen = "Summary";
            this.favorito = "Favourite";
            this.guardafav = "Save as favourite"
            this.guardaCal = "Schedule";
            this.vermas = "Read more";
            this.ponentes = "Speakers";
            this.fechaylugar = "Date and Place";
            this.descargaArchivo = "Download file";
            this.documentos = "Additional Documentation";
            this.titulo = "Title: ";
            this.categoria = "Topic: ";
            this.organizacion = "Organised by: ";
            this.colabora = "Support: ";
            this.contacto = "Contact: ";
            this.masinfo = "More info: ";
            this.outlook = "IFEMA ";
            this.todos = "All";            
        }
    },

    //[ALTIA] Carga dinámica de colores corporativos de la feria
    CargaColoresCorporativos: function () {
        var ruta = GetObjGlobales().getRutaFichero("R", "colores.xml");
        $.ajax({
            type: "GET",
            url: ruta,
            dataType: "xml",
            success: function (xml) {
                var theAgenda = GetObjAgenda();
                $(xml).find('c').each(function () {
                    GetObjAgenda().arrayEstilos[$(this).attr('id')] = $(this).attr('v');
                });

                if ($(xml).length == 0) {
                    theAgenda.arrayEstilos['contraste'] = "#2931b3";
                    theAgenda.arrayEstilos['textoOscuro'] = "#4e4e4e";
                    theAgenda.arrayEstilos['fondoCabecera'] = "#FFFFFF";
                    theAgenda.arrayEstilos['fondoPie'] = "#FFFFFF";
                    theAgenda.arrayEstilos['logoFeria'] = "none";
                    //theAgenda.arrayEstilos['logoIFEMA'] = "https://www.ifema.es/general/img/ifema-feria-madrid/color.png";

                    $('#logoCabecera').addClass('hidden');
                }
                else {
                    $('#logoCabecera').attr('src', theAgenda.arrayEstilos['logoFeria']);
                }
                $('#textoOscuro').attr('src', theAgenda.arrayEstilos['textoOscuro'] = "#4e4e4e");
                //$('#logoIFEMA').attr('src', theAgenda.arrayEstilos['logoIFEMA']);

                // script para el correcto funcionamiento de variables CSS en IExplorer
                $.getScript('https://cdn.jsdelivr.net/npm/css-vars-ponyfill@2', function () {
                    cssVars({
                        variables: {
                            'contrast-color': theAgenda.arrayEstilos['contraste'],
                            'dark-text-color': theAgenda.arrayEstilos['textoOscuro'],
                            'bg-header': theAgenda.arrayEstilos['fondoCabecera'],
                            'bg-footer': theAgenda.arrayEstilos['fondoPie']
                        }
                    });
                });
                // FIN script para el correcto funcionamiento de variables CSS en IExplorer
            },
            error: function (jqXHR, textStatus, errorThrown) {
                var theAgenda = GetObjAgenda();
                
                theAgenda.arrayEstilos['contraste'] = "#2931b3";
                theAgenda.arrayEstilos['textoOscuro'] = "#4e4e4e";
                theAgenda.arrayEstilos['fondoCabecera'] = "#FFFFFF";
                theAgenda.arrayEstilos['fondoPie'] = "#FFFFFF";
                theAgenda.arrayEstilos['logoFeria'] = "none";
                //theAgenda.arrayEstilos['logoIFEMA'] = "https://www.ifema.es/general/img/ifema-feria-madrid/color.png";

                $('#logoCabecera').addClass('hidden');
                //$('#logoIFEMA').attr('src', theAgenda.arrayEstilos['logoIFEMA']);

                // script para el correcto funcionamiento de variables CSS en IExplorer
                $.getScript('https://cdn.jsdelivr.net/npm/css-vars-ponyfill@2', function () {
                    cssVars({
                        variables: {
                            'contrast-color': theAgenda.arrayEstilos['contraste'],
                            'dark-text-color': theAgenda.arrayEstilos['textoOscuro'],
                            'bg-header': theAgenda.arrayEstilos['fondoCabecera'],
                            'bg-footer': theAgenda.arrayEstilos['fondoPie']
                        }
                    });
                });
                // FIN script para el correcto funcionamiento de variables CSS en IExplorer
            }
        })
    },
	//FIN [ALTIA]
    //[ALTIA] Carga dinámica de colores corporativos de la feria
    cargaLogoIfemaGetRecursos: function () {
        var urlLlamar = "getRecursoWeb.ashx";
        $.ajax({
            type: "GET",
            url: urlLlamar,
            success: function (response) {
                var theAgenda = GetObjAgenda();
                var data = response;
                theAgenda.arrayEstilos['logoIFEMA'] = data;
                theAgenda.arrayEstilos['logoIFEMApie'] = data;
                $('#logoIFEMA').attr('src', theAgenda.arrayEstilos['logoIFEMA']);
                $('#logoIFEMApie').attr('src', theAgenda.arrayEstilos['logoIFEMApie']);
            },
            error: function (response) {
                var theAgenda = GetObjAgenda();
                console.log(response);
                theAgenda.arrayEstilos['logoIFEMA'] = "https://www.ifema.es/general/img/ifema-feria-madrid/color.png";
                $('#logoIFEMA').attr('src', theAgenda.arrayEstilos['logoIFEMA']);
            }
        })
    },
    setExtensionFile: function (idAgenda) {
        this.agenda = idAgenda;
        this.almacenFavoritos = "AGENDA_" + this.feria + "_" + this.agenda;
        if (this.idio == "es") {
            this.extFile = "_" + this.agenda + "_ES.xml";
        } else if (this.idio == "pt") {
            this.extFile = "_" + this.agenda + "_PT.xml";
        }
        else {
            this.extFile = "_" + this.agenda + "_EN.xml";
        }
    },
    loadArrayFavoritos: function () {
        var storedFavoritos = $.DSt.get(this.almacenFavoritos);
        if (storedFavoritos != null) {
            this.arrayFavoritos = eval(JSON.parse(storedFavoritos));
        }
        //alert(storedFavoritos);
    },
    addEventoArrayFavoritos: function (evento) {
        var objAgenda = { 'feria': this.feria, 'agenda': this.agenda, 'evento': evento };
        this.arrayFavoritos.push(objAgenda);
        $.DSt.set(this.almacenFavoritos, JSON.stringify(this.arrayFavoritos));
        //alert(JSON.stringify(this.arrayFavoritos));
    },
    deleteEventoArrayFavoritos: function (evento) {
        for (var i = this.arrayFavoritos.length - 1; i >= 0; i--) {
            if (this.arrayFavoritos[i].feria == this.feria && this.arrayFavoritos[i].agenda == this.agenda &&
                this.arrayFavoritos[i].evento == evento)
                this.arrayFavoritos.splice(i, 1);
        }
        $.DSt.set(this.almacenFavoritos, JSON.stringify(this.arrayFavoritos));
        //alert(JSON.stringify(this.arrayFavoritos));
    },
    buscarEventoFavoritos: function (evento) {
        var flag = false;
        if (this.arrayFavoritos != null) {
            for (var i = this.arrayFavoritos.length - 1; i >= 0; i--) {
                if (this.arrayFavoritos[i].feria == this.feria && this.arrayFavoritos[i].agenda == this.agenda &&
                    this.arrayFavoritos[i].evento == evento)
                    flag = true;
            }
        }
        return flag;
    },
    setCodigoAgenda: function (idAgenda) {
        this.agenda = idAgenda;
        this.almacenFavoritos = "AGENDA_" + this.feria + "_" + idAgenda;
        if (this.idio == "es") {
            this.extFile = "_" + this.agenda + "_ES.xml";
        } else if (this.idio == "pt") {
            this.extFile = "_" + this.agenda + "_PT.xml";
        }
        else{
            this.extFile = "_" + this.agenda + "_EN.xml";            
        }
        GetObjAgenda().loadArrayFavoritos();
    }
}

//***************************** cambiar largo en iframe
function setAlto(alto) {
    if (GetObjAgenda().modoPIM == 'N') {
        //TODO Quitar cuando las web feriales incluyan la solucion CORS.
        //TODO INICIO.
        try {
            if (window.parent.document.getElementById("iframe_Agenda")) {
                window.parent.document.getElementById("iframe_Agenda").height = alto + 200;
                if (parent.ajusta_columna) parent.ajusta_columna(alto + 200);
            }
            return;
        }
        catch (err) {
            //TODO Pensar si hay que hacer algo.
        }
        //TODO FIN.
        window.parent.postMessage(alto, "*");
    }
}

//************************* INICIALIZACION *************************************************
function fechaUltimaCarga() {
    var fecha, txtFecha;
    if (GetObjGlobales().so == SO_WEB) {
        var ruta = GetObjGlobales().getRutaFichero("R", "configuracion.xml");
        $.ajax({
            type: "GET",
            url: ruta,
            dataType: "xml",
            success: function (xml) {
                $(xml).find('p').each(function () {
                    if (($(this).attr("id") == "fechaAgenda")) {
                        fecha = $(this).attr("v");
                        if (fecha) {
                            var diaCarga = getDiaFromFecha(fecha);
                            var mesCarga = getMesFromCarga(fecha, GetObjAgenda().idio);
                            var anioCarga = getAnioFromFecha(fecha);

                            if (GetObjAgenda().idio == "es")
                                txtFecha = "Datos actualizados a " + diaCarga + " de " + mesCarga + " de " + anioCarga;
                            else if (GetObjAgenda().idio == "pt")
                                txtFecha = "Dados atualizados para " + diaCarga + " de " + mesCarga + " de " + anioCarga;
                            else
                                txtFecha = "Last update on " + mesCarga + ", " + diaCarga + " " + anioCarga;

                            $("#txFechaCarga").html(txtFecha);
                            $(".lastUpdate").html(txtFecha);
                        }

                    }
                });
            }
        });
    }
}

function iniciarAgenda() {
    addeventoanalitics(GetObjGlobales().feria, "Tipo Acceso", "WEB", 0);
    fechaUltimaCarga();
    var ruta = GetObjGlobales().getRutaFichero("X", "Indice_Agenda" + GetObjAgenda().extIndiceAgenda);
    var cont = 0;
    var tituloAgenda, agenda, flag = false, tituloAgendaOpcion, nombreAnexo, pdf, link, tienedatos, miAgenda;
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TIndiceAgendas').each(function () {
                if ((GetObjAgenda().agenda != '') && ($(this).attr("id_agenda") == GetObjAgenda().agenda)) {
                    tituloAgendaOpcion = $(this).attr("ds_nombre");
                    flag = true;
                }

                tituloAgenda = $(this).attr("ds_nombre"); //$("#txTituloAgenda").html($(this).attr("ds_nombre"));
                agenda = $(this).attr("id_agenda");
                nombreAnexo = $(this).attr("Nombre_anexo");
                pdf = $(this).attr("docAnexo");
                link = $(this).attr("linkAnexo");
                tienedatos = $(this).attr("TieneDatos");

                cont++;
            });

            //si solo hay una agenda o si han entrado directamente a una agenda
            if ((cont == 1) || (flag)) {
                if (flag) {
                    GetObjAgenda().setCodigoAgenda(GetObjAgenda().agenda);
                    $("#txTituloAgenda").html(tituloAgendaOpcion);
                } else {
                    if ((tienedatos == 'S') || (typeof(tienedatos) === 'undefined')) {
                        GetObjAgenda().setCodigoAgenda(agenda);
                        $("#txTituloAgenda").html(tituloAgenda);
                        ExistenDocumentos(nombreAnexo, pdf, link);
                    } else {
                        if ((pdf != '')) {
                            var ruta = GetObjGlobales().getRutaFichero("R", pdf);
                            abreVentana(ruta);
                        } else {
                            abreVentana(link);
                        }
                    }
                }
                loadAgendaFecha();

                if (location.hash.search("AGENDA_FICHA_EVENTO") >= 0) 
                    locationAgendaHashChanged();
                else
                window.location.hash = 'AGENDA_FECHA';
            }
            else {
                if (cont > 1)
                    window.location.hash = "MENU";
            }
        }
    });
}


function loadAgendaFecha() {
    AsignarEventosAgenda();
    //CargarFecha();
    ExistenCategorias();
    ExistePonentes();
    $("#Listados").show();
    $("#IndiceAgendas").hide();
}

function AsignarEventosAgenda() {
    $("#btnDia").click(function () {
        GetObjAgenda().divActivo = "dvDia";
        window.location.hash = "AGENDA_FECHA";
        addeventoanalitics(GetObjAgenda().feria, 'Lista Actividades por Día', '', 0);
    });
    $("#btnCategoria").click(function () {
        GetObjAgenda().divActivo = "dvCategoria";
        window.location.hash = "AGENDA_CATEGORIA";
        addeventoanalitics(GetObjAgenda().feria, 'Lista Actividades por Tema', '', 0);
    });
    $("#btnUbicacion").click(function () {
        GetObjAgenda().divActivo = "dvUbicacion";
        window.location.hash = "AGENDA_UBICACION";
        addeventoanalitics(GetObjAgenda().feria, 'Lista de Actividades por Ubicación', '', 0);
    });
    $("#btnPonente").click(function () {
        window.location.hash = "AGENDA_PONENTE";
        addeventoanalitics(GetObjAgenda().feria, 'Lista de Ponentes', '', 0);
    });
    $("#Favoritos, .favtxt").click(function () {
        GetObjAgenda().divActivo = "dvFavotiro";
        //ListadoFavorito();
        window.location.hash = "AGENDA_FAVORITOS";
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();


    });
    $("#Documento").click(function () {
        GetObjAgenda().divActivo = "dvDocumento";
    });
    $("#btBuscar").click(function () {
        BuscarPonenteFiltro();
    });
    $("#btBuscarActividad").click(function () {
        BuscarActividadFiltro();
    });
    $("#txPonente").keypress(function (e) {
        if (e.which == 13) {
            BuscarPonenteFiltro();
            return false;    //<---- Add this line
        }
    });

    $("#txtActividad").keypress(function (e) {
        if (e.which == 13) {
            BuscarActividadFiltro();
            return false;    //<---- Add this line
        }
    });

    fijarIdioma();
}

function BuscarPonenteFiltro() {
    CargarPonente2();
    if (GetObjAgenda().eventoRefresh)
        GetObjAgenda().eventoRefresh();
}

function BuscarActividadFiltro() {
    if (location.hash.indexOf('#AGENDA_FECHA') != -1) { CargarFecha(); }
    else if (location.hash.indexOf('#AGENDA_CATEGORIA') != -1) { CargarCategoria(); }
    else if (location.hash.indexOf('#AGENDA_UBICACION') != -1) { CargarUbicacion(); }
    else { CargarFecha(); }

    if (GetObjAgenda().eventoRefresh)
        GetObjAgenda().eventoRefresh();
}

function fijarIdioma() {
    if (GetObjAgenda().idio == "es") {
        $("#lbPonente").text("Ponente: ");
        $("#lblActividad").text("Actividad: ");
        $("#Span1").text("Actividad: ");
        $("#btnPonente").attr('value', 'Ponente');
        $("#btnCategoria").attr('value', 'Temática');
        $("#btnUbicacion").attr('value', 'Ubicación');
        $("#btBuscar").attr('value', 'Buscar');
        $("#btBuscarActividad").attr('value', 'Buscar');
        $("#btnDia").attr('value', 'Día');
        $("#txtActividad").attr('placeholder', 'Buscar Actividad');
        $("#txPonente").attr('placeholder', 'Buscar Ponente');
        $(".favtxt").html("Mis favoritos");
        $("#linkAviso").text("Aviso Legal");
        $("#linkAviso").attr('href', 'https://www.ifema.es/aviso-legal');
        $("#privPolicy").text("Política de Privacidad");
        $("#privPolicy").attr('href', 'https://www.ifema.es/politica-privacidad');
        $("#cookiesPolicy").text("Política de cookies");
        $("#cookiesPolicy").attr('href', 'https://www.ifema.es/politica-cookies');
        $("#footerAC").text("Accesibilidad");
        $("#footerAC").attr('href', 'https://www.ifema.es/accesibilidad');
        $("#descargaActividades").text("Descargar");
        $("#calendarioLista").text("Agendar favoritos");
    }
    else if (GetObjAgenda().idio == "pt") {
        $("#btnPonente").attr('value', 'Orador');
        $("#lbPonente").text("Orador: ");
        $("#lblActividad").text("Atividade: ");
        $("#Span1").text("Atividade: ");
        $("#btnCategoria").attr('value', 'Temático');
        $("#btnUbicacion").attr('value', 'Localização');
        $("#btBuscar").attr('value', 'Procurar');
        $("#btBuscarActividad").attr('value', 'Procurar');
        $("#btnDia").attr('value', 'Dia');
        $("#txtActividad").attr('placeholder', 'Procurar Actividade');
        $("#txPonente").attr('placeholder', 'Procurar Orador');
        $(".favtxt").html("Meus favoritos");
        $("#linkAviso").text("Aviso Legal");
        $("#linkAviso").attr('href', 'https://www.ifema.es/en/legal-notice');
        $("#privPolicy").text("Política de privacidade");
        $("#privPolicy").attr('href', 'https://www.ifema.es/en/privacy-policy');
        $("#cookiesPolicy").text("Política de cookies");
        $("#cookiesPolicy").attr('href', 'https://www.ifema.es/en/cookies-policy');
        $("#footerAC").text("Acessibilidade");
        $("#footerAC").attr('href', 'https://www.ifema.es/en/accessibility');
        $("#descargaActividades").text("Descarregar");
        $("#calendarioLista").text("Programar favoritos");
    }
    else {
        $("#btnPonente").attr('value', 'Speaker');
        $("#lbPonente").text("Speaker: ");
        $("#lblActividad").text("Activity: ");
        $("#Span1").text("Activity: ");
        $("#btnCategoria").attr('value', 'Topic');
        $("#btnUbicacion").attr('value', 'Location');
        $("#btBuscar").attr('value', 'Search');
        $("#btBuscarActividad").attr('value', 'Search');
        $("#btnDia").attr('value', 'Day');
        $("#txtActividad").attr('placeholder', 'Search Activity');
        $("#txPonente").attr('placeholder', 'Search Speaker');
        $(".favtxt").html("My favourites");
        $("#linkAviso").text("Legal advice");
        $("#linkAviso").attr('href', 'https://www.ifema.es/en/legal-notice');
        $("#privPolicy").text("Privacy policy");
        $("#privPolicy").attr('href', 'https://www.ifema.es/en/privacy-policy');
        $("#cookiesPolicy").text("Cookies policy");
        $("#cookiesPolicy").attr('href', 'https://www.ifema.es/en/cookies-policy');
        $("#footerAC").text("Accessibility");
        $("#footerAC").attr('href', 'https://www.ifema.es/en/accessibility');
        $("#descargaActividades").text("Download");
        $("#calendarioLista").text("Schedule favourites");
    }
}

//************************* EVENTOS BOTONERA PRINCIPAL  *************************************************
function locationAgendaHashChanged() {
    $("#open-dialog-downloads").show();//ARG 
    if (location.hash == '#AGENDA_FECHA') {
        CargarFecha();
        $("#btnDia").focus();
        $("#descargaFavs").hide();
                    $("#open-dialog-downloads").show();
    }
    else {
        if (location.hash == '#AGENDA_CATEGORIA') {
            CargarCategoria();
            $("#btnCategoria").focus();
                    $("#open-dialog-downloads").show();
            $("#descargaFavs").hide();
        }
        else {
            if (location.hash == '#AGENDA_UBICACION') {
                CargarUbicacion();
                $("#btnUbicacion").focus();
        $("#descargaFavs").hide();
                    $("#open-dialog-downloads").show();
            }
            else {
                if (location.hash == '#AGENDA_PONENTE') {
                    CargarPonente();
                    $("#btnPonente").focus();
                    $("#descargaFavs").hide();
                    $("#open-dialog-downloads").show();
                }
                else {
                    if (location.hash == '#AGENDA_FAVORITOS') {
                        $("#Favoritos").focus();
                        ListadoFavorito();
                        $("#descargaFavs").show();
                            $("#open-dialog-downloads").hide();
                    }
                    else {
                        if (location.hash == '#MENU') {
                            if ($("#IndiceAgendas").is(":visible") == false) {
                                mostrarIndiceAgendas();
                                $("#IndiceAgendas").show();
                                $("#Listados").hide();

                            }
                        }
                        else {
                                $("#descargaFavs").hide();
                            $("#open-dialog-downloads").hide();
                            if (location.hash.search("AGENDA_FICHA_EVENTO") >= 0) {
                                var idevento = location.hash.toUpperCase().substring("AGENDA_FICHA_EVENTO_".length + 1, location.hash.length);
                                LoadEvento(idevento);
                                ConfigVentana();
                                $("#FichaEvento").show();
                                $('html, body', window.parent.document).animate({ scrollTop: 0 }, 'slow');
                            }
                            else {
                                if (location.hash.search("AGENDA_FICHA_PONENTE_") >= 0) {
                                    var idPonente = location.hash.toUpperCase().substring("AGENDA_FICHA_PONENTE_".length + 1, location.hash.length);
                                    LoadPonente(idPonente);
                                    ConfigVentana();
                                    $("#FichaPonente").show();
                                    $('html, body', window.parent.document).animate({ scrollTop: 0 }, 'slow');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

function btn_ListadoPonente() {
    CargarPonente();
}

function btn_ListadoUbicacion() {
    CargarUbicacion();
}

function ListadoFavorito() {
    $("#BusquedaPonente").hide();
    $("#BusquedaActividad").hide();
    ConfigVentana();
    $(".favtxt").addClass('btn-azul');
    LoadFavoritos();
}

function btn_ListadoDia() {
    CargarFecha();
}

function ListadoDia() {
    $("#BusquedaPonente").hide();
    $("#BusquedaActividad").show();
    $("#TituloAgenda").show();
    ConfigVentana();
    ActivarBoton($("#btnDia"));
    DescargaActividadesPDF('F');
    $("#dvDia").show();

    if ($("#dvDia").accordion) {
        if ($('#dvDia').hasClass('ui-accordion')) {
            $('#dvDia').accordion('refresh');
            if (GetObjAgenda().activoDia != false)
                $('#dvDia').accordion("option", "active", GetObjAgenda().activoDia);
        } else {
            $("#dvDia").accordion({
                active: 0,
                collapsible: true,
                heightStyle: "content",
                activate: function (event, ui) {
                    setAlto($("#dvDia").height() + 100);
                    GetObjAgenda().activoDia = $("#dvDia").accordion("option", "active")
                }
            });
        }
    }

    setAlto($("#dvDia").height() + 100);
}

function btn_Categoria() {
    CargarCategoria();

}

function ListadoCategoria() {
    $("#BusquedaPonente").hide();
    $("#BusquedaActividad").show();
    $("#TituloAgenda").show();
    ConfigVentana();
    ActivarBoton($("#btnCategoria"));
    DescargaActividadesPDF('C');
    $("#dvCategoria").show();

    if ($("#dvCategoria").accordion) {
        if ($('#dvCategoria').hasClass('ui-accordion')) {
            $('#dvCategoria').accordion('refresh');
            if (GetObjAgenda().activoTematica != false)
                $('#dvCategoria').accordion("option", "active", GetObjAgenda().activoTematica);

        } else {
            $("#dvCategoria").accordion({
                active: 0,
                collapsible: true,
                heightStyle: "content",
                activate: function (event, ui) {
                    setAlto($("#dvCategoria").height() + 100);
                    GetObjAgenda().activoTematica = $("#dvCategoria").accordion("option", "active");
                }
            });
        }
    }

    setAlto($("#dvCategoria").height() + 100);
}

function ListadoUbiacion() {
    $("#BusquedaPonente").hide();
    $("#BusquedaActividad").show();
    $("#TituloAgenda").show();
    ConfigVentana();
    ActivarBoton($("#btnUbicacion"));
    DescargaActividadesPDF('U');
    $("#dvUbicacion").show();

    if ($("#dvUbicacion").accordion) {
        if ($('#dvUbicacion').hasClass('ui-accordion')) {
            $('#dvUbicacion').accordion('refresh');
            if (GetObjAgenda().activoUbicacion != false)
                $('#dvUbicacion').accordion("option", "active", GetObjAgenda().activoUbicacion);

        } else {
            $("#dvUbicacion").accordion({
                active: 0,
                collapsible: true,
                heightStyle: "content",
                activate: function (event, ui) {
                    setAlto($("#dvUbicacion").height() + 100);
                    GetObjAgenda().activoUbicacion = $("#dvUbicacion").accordion("option", "active");
                }
            });
        }
    }
    setAlto($("#dvUbicacion").height() + 100);
}

function ListadoPonente() {
    $("#BusquedaPonente").show();
    $("#BusquedaActividad").hide();
    $("#TituloAgenda").show();
    ConfigVentana();
    ActivarBoton($("#btnPonente"));
    DescargaActividadesPDF('P');
    $("#dvPonente").show();
    setTimeout(function () { setAlto($("#dvPonente").height() + 120); }, 2000);
}
function ConfigVentana() {
    $("#btnDia").attr("class", "btn-gris");
    $("#btnCategoria").attr("class", "btn-gris");
    $("#btnUbicacion").attr("class", "btn-gris");
    $("#btnPonente").attr("class", "btn-gris");
    $(".favtxt").removeClass('btn-azul');

    $("#dvDia").hide();
    $("#dvCategoria").hide();
    $("#dvUbicacion").hide();
    $("#dvPonente").hide();
    $("#dvFavotiro").hide();

    $("#FichaPonente").hide();
    $("#FichaEvento").hide();
}

function ActivarBoton(btn) {
    btn.attr("class", "btn-azul");
}

function OcultaMuestraDivs(value) {
    if (value == true) {
        $("#dvDia").show();
        $("#dvCategoria").show();
        $("#dvUbicacion").show();
        $("#dvPonente").show();
        $("#dvFavotiro").show();
        $("#FichaPonente").show();
        $("#FichaEvento").show();
    }
    else {
        $("#dvDia").hide();
        $("#dvCategoria").hide();
        $("#dvUbicacion").hide();
        $("#dvPonente").hide();
        $("#dvFavotiro").hide();
        $("#FichaPonente").hide();
        $("#FichaEvento").hide();
    }
}

//******************************************* indice agendas
function mostrarIndiceAgendas() {
    var myObj = { txt: "" };
    $("#IndiceAgendas").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "Indice_Agenda" + GetObjAgenda().extIndiceAgenda);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            
            if (GetObjAgenda().idio == "es") {
                var TituloPrograma = "Programa de actividades";
            } else if (GetObjAgenda().idio == "pt") {
                var TituloPrograma = "Programa de atividades";
            }
            else {
                var TituloPrograma = "Programme of Activities";
            }

            if ($("#IndiceAgendas").children().length == 0) {
                myObj.txt = myObj.txt + "<div class='tituloCentro col-sm-12'>"+
                TituloPrograma+"</div>";
                $(xml).find('TIndiceAgendas').each(function () {
                    pintarIndiceAgenda(myObj, $(this));
                });
                $("#IndiceAgendas").append("<div class='BloqueHorizontal row'>" + myObj.txt + "</div>");
                eventosIndice("#IndiceAgendas");
                setAlto($("#IndiceAgendas").height());
            }
        }
    });
}

//dependiendo del SO vamos a cortar el titulo en 18 (iOS) o 25 (Android)
function takeNchars(titulo) {
    var title = titulo;
    var nchar = 0;
    if (title.length > 0) {
        if (GetObjGlobales().so == SO_WEB)
            nchar = titulo.length;
        else if (GetObjGlobales().so == SO_IOS)
            nchar = 18;
        else
            nchar = 25;

        if (title.length > nchar)
            title = title.substring(0, nchar) + "...";
    }
    else
        title = "";
    return title;
}

function eventosIndice(contenedor) {
    $(contenedor).find('.BloqueHorizontalIndiceAgenda').each(function () {
        $(this).click(function () {
            if (($(this).attr("datos") == "S") || (typeof ($(this).attr("datos")) === 'undefined')) {
                $("#txTituloAgenda").html($(this).attr("titulo"));       //takeNchars($(this).attr("titulo"), 65));
                GetObjAgenda().setExtensionFile($(this).attr("agenda"));
                GetObjAgenda().fotoGrande = $(this).attr("fotoGrande");
                GetObjAgenda().fotoMini = $(this).attr("fotoMini");
                AsignarEventosAgenda();
                //CargarFecha();
                DescargaActividadesPDF("F");
                ExistenDocumentos($(this).attr("nombreAnexo"), $(this).attr("docAnexo"), $(this).attr("linkAnexo"));
                window.location.hash = "AGENDA_FECHA";
                GetObjAgenda().loadArrayFavoritos();
                ExistenCategorias();
                ExistePonentes();
                $(contenedor).hide();
                $("#Listados").show();;
                setTimeout(function () { $("#Listados").show() }, 100)
            }
            else
            {                
                if (($(this).attr("linkAnexo") != '')) {
                    abreVentana($(this).attr("linkAnexo"));
                }
                else
                {
                    if (($(this).attr("docAnexo") != '')) {
                        var ruta = GetObjGlobales().getRutaFichero("R", $(this).attr("docAnexo"));
                        abreVentana(ruta);
                    } 
                }                
            }
        });
    });
}
  
function DescargaActividadesPDF(tipoPDF) {

    if (GetObjAgenda().modoPIM == "S") return;
    var btnTxt;
    if (GetObjAgenda().idio == "es") {
        $('#dialog-downloads').dialog('option', 'title', "Descarga de Ficheros");
        btnTxt = "Lista de actividades - ";
    } else if (GetObjAgenda().idio == "pt") {
        $('#dialog-downloads').dialog('option', 'title', "Descarregar do arquivos");
        btnTxt = "Lista de atividades - ";
    }
    else {
        $('#dialog-downloads').dialog('option', 'title', "Download");
        btnTxt = "List of activities - ";        
    }

    switch(tipoPDF) {
        case 'F':
            $("#divDescargaPDFTxt").html(btnTxt + $("#btnDia").attr('value'));
            break;
        case 'C':
            $("#divDescargaPDFTxt").html(btnTxt + $("#btnCategoria").attr('value'));
            break;
        case 'U':
            $("#divDescargaPDFTxt").html(btnTxt + $("#btnUbicacion").attr('value'));
            break;
        case 'P':
            $("#divDescargaPDFTxt").html(btnTxt + $("#btnPonente").attr('value'));
            break;
        default:
            $("#divDescargaPDFTxt").html("");
    }

    var RutaPdf = 'ExportaPDF.ashx?Feria=' + GetObjAgenda().feria + '&idAgenda=' + GetObjAgenda().agenda + '&extFich=' + 
        GetObjAgenda().extIndiceAgenda + '&tipoPDF=' + tipoPDF + '&idio=' + GetObjAgenda().idio + '&titulo=' +
        $("#txTituloAgenda").text() + '&fotoGrande=' + GetObjAgenda().fotoGrande;
    $("#divDescargaPDF").html("<a id='linkDescarga" + tipoPDF + "' href='" + RutaPdf + "' target='_blank'><img src='img/pdf.jpg'/></a>");
    $("#linkDescarga" + tipoPDF).click(function () {
        addEventoAnalyticsExport(tipoPDF);
    });
}

function ExistenDocumentos(nombreAnexo, anexo, link)
{
    if (GetObjAgenda().modoPIM == "S") return;
    var tagAnalytics = '';

    $("#divDocumentoTxt").html(nombreAnexo); 

    if ((typeof (anexo) !== 'undefined') && (anexo != "")) {
        var ruta = GetObjGlobales().getRutaFichero("R", anexo);
        tagAnalytics = anexo;
        $("#divDocumento").html("<a href='" + ruta + "' target='_blank'><img src='img/pdf.jpg'/></a>");
    } else if ((typeof (link) !== 'undefined') && (link != "")) {
        $("#divDocumento").html("<a href='" + link + "' target='_blank'><img src='img/internet.png'/></a>");
    } else {
        $("#divDocumento").html("");
    }

    //if ($("#divDocumento").is(':empty')) {
    //    $("#divDocumentoTxt").hide();
    //    $("#divDocumento").hide();
    //    $("#divDocumentoHr").hide();
    //} else {
    //    $("#divDocumentoTxt").show();
    //    $("#divDocumento").show();
    //    $("#divDocumentoHr").show();
    //}

    $("#divDocumento").click(function () {
        addeventoanalitics(GetObjAgenda().feria, 'Descarga de Programa Actividades', $("#txTituloAgenda").text(), 0);
    });
}

function pintarIndiceAgenda(obj, Indice) {
    
    if (($(Indice).attr("TieneDatos")=="N") && (GetObjAgenda().modoPIM=='S')) return;

    var txt = "<div class='BloqueHorizontalIndiceAgendaBG divBoxShadow col-xs-12 col-sm-6 col-md-4 col-lg-3'><div class='indexBox'><div>" +
              "<div class='BloqueHorizontalIndiceAgenda' titulo='" + $(Indice).attr("ds_nombre") + "' agenda='" + $(Indice).attr("id_agenda") +
              "' datos='" + $(Indice).attr("TieneDatos") + "' nombreAnexo='" + $(Indice).attr("Nombre_anexo") +
              "' linkAnexo='" + $(Indice).attr("linkAnexo") + "' docAnexo='" + $(Indice).attr("docAnexo") +
              "' fotoGrande='" + $(Indice).attr("foto_grande") + "' fotoMini='" + $(Indice).attr("foto_mini") + "' > " +
              "  <div class='DatosIzquierdaIndice' >  " +
              "      <div class='PanelDatosI' > ";
    var foto = "foto_mini";
    foto = "foto_grande";
    if ((typeof ((Indice).attr(foto)) !== 'undefined') && ((Indice).attr(foto) != ""))
    {
        var img = $(Indice).attr(foto);
        if (img.length > 0) {
            txt = txt + "<img src='" + GetObjGlobales().getRutaFichero("I", img) + "'/> ";
        }
    }
    else
    {
        txt = txt + "<img src='img/calendar.jpg'/> ";
    }
    txt = txt +
              "          </div>  " +
              "      </div>  " +
              "  <div class='DatosCentralIndice'>  " +
              "      <span";
            if (GetObjAgenda().modoPIM == 'S') {
                txt = txt + " style='font-size:28px !important;' ";
            }
              
            txt = txt + ">" + $(Indice).attr("ds_nombre") + "</span>  " +
              "  </div> " +
              " </div> " +
               " </div>" +
               " </div> " +
               
              " </div> ";
    obj.txt = obj.txt + txt;
}



//*************************** favoritos
function LoadFavoritos() {
    var myObj = { txt: "" };
   // if (GetObjGlobales().so == SO_WEB) {
   //     $("#dvFavotiro").html("<div class='BloqueHorizontalOutlook'><div class='imgDownload' id='calendarioLista'><img src='img/download.jpg' style='text-align:right;width:50px' /></div></div>");
   // } else {
   //     $("#dvFavotiro").html("");
   // }

    if (GetObjGlobales().so == SO_WEB) {
    } else {
        $("#descargaFavs").css("display", "none");
    }






    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceFecha" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TActividad').each(function () {
                if (GetObjAgenda().buscarEventoFavoritos($(this).attr('id_actividad'))) {
                    PintarActividadFecha(myObj, $(this));
                }
            });
            $("#dvFavotiro").append(myObj.txt);
            setTimeout(function () {
                CargarFavoritoAgenda($("#dvFavotiro"), ocultarEvento);
                EventoFavorito("#dvFavotiro");
                eventosAccesoFichaEvento("#dvFavotiro");
                eventoDownloadCalendario("#dvFavotiro");
                $("#dvFavotiro").show();
                setAlto($("#dvFavotiro").height() + 100);
                if (GetObjAgenda().eventoRefresh)
                    GetObjAgenda().eventoRefresh();

            }, 0);
        }
    });
}

function eventoDownloadCalendario(contenedor) {
    var txt = "";
    $("#calendarioLista").click(function () {
        txt = "";
        $(contenedor).find('.fav[favoriteado=1]').each(function () {
            txt = txt + $(this).parents().eq(0).children(":first").attr("idactividad") + ",";
        });
        ruta = GetObjGlobales().rutaroot + 'Citas.ashx?' + 'Feria=' + GetObjAgenda().feria + '&idAgenda=' + GetObjAgenda().agenda + '&idioma=' + GetObjAgenda().idio + '&lActividades=' + txt;
        window.open(ruta);
    });
}

// ************************** listado dia
function CargarFecha() {
    var myObj = { txt: "" };
    $("#dvDia").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceFecha" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TFechaActividades').each(function () {
                var iden = "FEC_" + $(this).attr('d_fecha');
                //crear Dia 
                var divDia = $("#dvDia");
                var dia = GetObjAgenda().dia + $(this).attr('d_fecha');
                PintaCabecera = 0;
                //crear eventos
                $(this).find('TActividad').each(function () {
                    FiltrarActividad(myObj, $(this), iden, divDia, dia, 'Fecha');
                });
                if (PintaCabecera == 1)
                { PintarCierre(myObj, divDia); }                
            });
            CargarFavoritoAgenda($("#dvDia"), OnOff);
            EventoFavorito("#dvDia");
            eventosAccesoFichaEvento("#dvDia");
            ListadoDia();
            if (GetObjAgenda().eventoAfterPintarIndice)
                GetObjAgenda().eventoAfterPintarIndice($('#dvDia'), 'FEC_');

            if (GetObjAgenda().eventoRefresh)
                GetObjAgenda().eventoRefresh();

        }
    });
}

//carga los favoritos en el contendedor actual
function CargarFavoritoAgenda(Contenedor, onTerminate) {
    //$(Contenedor).find('.fav').each(function () {
    $(Contenedor).find('[fav="favorito"]').each(function () {
        mo = new Object();
        mo.id = $(this).attr('idActividad');
        mo.agenda = GetObjAgenda().agenda;
        mo.feria = GetObjAgenda().feria;
        mo.tag = $(this);
        onTerminate(mo);
    });
}

function OnOff(myObj) {
    if (GetObjAgenda().buscarEventoFavoritos(myObj.id)) {
        $(myObj.tag).attr('src', 'img/fav_on.png');
        $(myObj.tag).removeClass('icon-star');
        $(myObj.tag).addClass('icon-star-fill');
        $(myObj.tag).attr('favoriteado', '1');
    }
    else {
        $(myObj.tag).attr('src', 'img/fav_off.png');
        $(myObj.tag).removeClass('icon-star-fill');
        $(myObj.tag).addClass('icon-star');
        $(myObj.tag).attr('favoriteado', '0');
    }
}

function ocultarEvento(myObj) {
    if (GetObjAgenda().buscarEventoFavoritos(myObj.id)) {
        $(myObj.tag).attr('src', 'img/fav_on.png');
        $(myObj.tag).removeClass('icon-star');
        $(myObj.tag).addClass('icon-star-fill');
        $(myObj.tag).attr('favoriteado', '1');
    }
    //else {
    //    $(myObj.tag).parents().eq(2).hide();
    //}
}

function EventoFavorito(contenedor) {
    //DDC ÑAPA. No sabemos la razón pero con este código siempre ejecuta un dobleclick en los móviles.
    //Por falta de tiempo no podemos investigar más y lo único que hemos podido hacer es esta chapu.

    //    $(contenedor + " .fav").unbind('click').bind('click',function (e) {   	   	
    $(contenedor + ' [fav="favorito"]').unbind('click').bind('click', function (e) {

        if (isDoubleClickBug(e)) {
            return true;
        }

        var localid = $(this).attr('idActividad');
        if ($(this).attr('favoriteado') == 1) {
            GetObjAgenda().deleteEventoArrayFavoritos(localid);
            $(this).attr('src', 'img/fav_off.png');
            $(this).removeClass('icon-star-fill');
            $(this).addClass('icon-star');
            $(this).attr('favoriteado', '0');
        }
        else {
            GetObjAgenda().addEventoArrayFavoritos(localid);
            $(this).attr('src', 'img/fav_on.png');
            $(this).removeClass('icon-star');
            $(this).addClass('icon-star-fill');
            $(this).attr('favoriteado', '1');
        }
    });

    var ruta = "";
    $(contenedor + " .outlook").click(function () {
        ruta = GetObjGlobales().rutaroot + 'Citas.ashx?' + 'Feria=' + GetObjAgenda().feria + '&idAgenda=' + GetObjAgenda().agenda + '&idioma=' + GetObjAgenda().idio + '&lActividades=' + $(this).attr('idactividad');
        window.open(ruta);
    });
}

function eventosAccesoFichaEvento(contenedor) {

    $(contenedor).find('.fEvento, .DatosCentralColor, .BloqueHorizontal, .BloqueIndices').each(function () {
        //            $(this).unbind('click');
        //            $(this).click(function () {
        idActividad = $(this).attr('idActividad');
        if (idActividad > 0) {
            $(this).unbind('click').bind('click', function (e) {

                if (isDoubleClickBug(e)) {
                    return true;
                }

                var idEvento = $(this).attr('idActividad');
                if (idEvento > 0)
                    //No quitar este setimeout que si no salta 2 clicks
                    setTimeout(function () { window.location.hash = "AGENDA_FICHA_EVENTO_" + idEvento }, 200);

                /*var current = new Date().getTime();
                var delta = current - lastClickTime;
                if (delta < 350) {
                    //$('.cabecerafecha span').text(0);
                    // This happens because of a bug, so we ignore it.
                    // http://code.google.com/p/android/issues/detail?id=38808
                } else {
                    var idEvento = $(this).attr('idActividad');
                    if (idEvento > 0)
                        //No quitar este setimeout que si no salta 2 clicks
                        setTimeout(function () { window.location.hash = "AGENDA_FICHA_EVENTO_" + idEvento }, 200);

                    //            if (GetObjAgenda().NavegarAgenda) {
                    //                GetObjAgenda().NavegarAgenda("AGENDA_FICHA_EVENTO_" + idEvento);
                    //            } else {
                    //                LoadEvento($(this).attr('idActividad'));
                    //            }

                    //            $(contenedor).hide();
                    //            $("#FichaEvento").show();
                    //            $('html, body', window.parent.document).animate({ scrollTop: 0 }, 'slow');
                }
                lastClickTime = current;
                e.stopPropagation();
                e.preventDefault();
                return false;*/

            });
        }
    });
}

function PintarActividadFecha(obj, Actividad, iden) {
    var ponentes = ""; var descrip = ""; var tmp = ""; var img = ""; var foto = "";
    $(Actividad).find('TPonente').each(function () {
        ponentes = ponentes + $(this).attr('ds_nombre') + " - " + $(this).attr('ds_cargo') + " / ";
    });
    if (ponentes.length > 0)
        ponentes = ponentes.slice(0, ponentes.length - 2);

    var titulo = '';
    if ($(Actividad).attr('ds_titulo') != undefined) {
        titulo = $(Actividad).attr('ds_titulo'); //.toUpperCase();
    }
    if ($(Actividad).attr('ds_descripcion') != undefined) {
        descrip = $(Actividad).attr('ds_descripcion');
        //if (tmp.length > 170) {
        //    descrip = tmp.slice(0, 170);
        //}
        //else {
        //    descrip = tmp;
        //}
    }

    if (GetObjAgenda().FotoIndiceGrande) {
        foto = "FotoActividad_grande";
    }
    else {
        foto = "FotoActividad_mini";
    }
    if ($(Actividad).attr(foto) != undefined) {
        img = $(Actividad).attr(foto);
        if (img.length > 0)
            img = "<img class='fEvento' src='" + GetObjGlobales().getRutaFichero("I", img) + "'  idActividad=" + $(Actividad).attr('id_actividad') + "  />";
        else
            img = "<img class='fEvento' src='img/sin-imagen.png' idActividad=" + $(Actividad).attr('id_actividad') + "  />";
        //img = "<img class='fEvento' src='' style='width:75px;display:none' idActividad='' />";
    }
    if (img == "")
        img = "&nbsp";

    var mostrarFecha = "";

    if (GetObjAgenda().divActivo !== "dvDia") {
        mostrarFecha = $(Actividad).attr('d_fecha') + " - ";
    }
    var horario = "";
    if ($(Actividad).attr('h_inicio') != "") {
        horario = $(Actividad).attr('h_inicio') + " - " + $(Actividad).attr('h_fin');
    }
    else {
        horario = $(Actividad).attr('duracion');
    }

    obj.txt = obj.txt +
        "<div class='BloqueIndices row' miacor='" + "DET_" + iden + "' idActividad=" + $(Actividad).attr('id_actividad') + ">" +
            "<div class='DatosIzquierda company-box-image col-xs-12 col-sm-2 col-md-2 col-lg-2 text-left'>" +
                "<div class='PanelDatosI'>        " +
                    img +
                "</div>" +
            "</div>" +
            "<div class='company-box-text col-xs-12 col-sm-10 col-md-10 col-lg-10'>" +
                "<div class='BloqueHorizontalTitulo'>" +
                        "<div class='DatosCentralColor'  >" +
                        "<span class='sm-event-date'>" + mostrarFecha + horario + "</span>" +
                                "<p class='company-place'>" + $(Actividad).attr('ds_ubicacion') + "</p>" +
                        "    <b>" + titulo + "</b></div>" +
                    "</div>" +
                    "<div class='BloqueHorizontal' >" +
                        "<div class='DatosCentral' >" 
                             +
                                descrip 
                             +
                        "</div>" +
                    
                "</div>" +
            "</div>" +
            "<div class='DatosDerecha company-box-links col-xs-12 col-sm-12 col-md-12 col-lg-12'>";
        if (GetObjAgenda().modoPIM == 'N')
            obj.txt = obj.txt +
                "   <div class='fav icon-star' fav='favorito' src='img/fav_off.png' idActividad=" + $(Actividad).attr('id_actividad') + "  favoriteado=''>" + "<label class='hidden-xs'>" + GetObjAgenda().favorito + "</label>" + "</div>";
    if (GetObjAgenda().hayoutlook) {
        obj.txt = obj.txt + "<div class='icon-calendar-5 outlook' src='img/outlook-icon.jpg'  idActividad='" + $(Actividad).attr('id_actividad') + "'>" + "<label class='hidden-xs'>" + GetObjAgenda().guardaCal + "</label>" + "</div>";
        }
    obj.txt = obj.txt + "<div class='pull-right hidden-xs hidden-sm'><span class='fEvento'>" + GetObjAgenda().vermas + "</span></div>";
        obj.txt = obj.txt +
            " </div>" +
        "</div>";


}

//************ listado Ubicación
function CargarUbicacion() {
    var myObj = { txt: "" };
    $("#dvUbicacion").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceUbicacion" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TUbicacionAgenda').each(function () {
                var iden = "UBI_" + $(this).attr('id_ubicacion_agenda');
                var divUbicacion = $("#dvUbicacion");
                var ubicacion = $(this).attr('ubicacion');
                PintaCabecera = 0;
                //crear Dia 
                //PintarCabecera($("#dvUbicacion"), $(this).attr('ubicacion'), iden);
                //crear eventos
                $(this).find('TActividad').each(function () {
                    FiltrarActividad(myObj, $(this), iden, divUbicacion, ubicacion, 'Ubicacion');
                    //PintarActividadUbicacion(myObj, $(this), iden);
                });
                if (PintaCabecera == 1) { PintarCierre(myObj, divUbicacion); }
            });
            CargarFavoritoAgenda($("#dvUbicacion"), OnOff);
            EventoFavorito("#dvUbicacion");
            eventosAccesoFichaEvento("#dvUbicacion");
            ListadoUbiacion();
            if (GetObjAgenda().eventoAfterPintarIndice)
                GetObjAgenda().eventoAfterPintarIndice($('#dvUbicacion'), 'UBI_');
            if (GetObjAgenda().eventoRefresh)
                GetObjAgenda().eventoRefresh();

        }
    });
}

function PintarActividadUbicacion(obj, Actividad, iden) {
    var ponentes = ""; var descrip = ""; var tmp = ""; var img = ""; var foto = "";
    $(Actividad).find('TPonente').each(function () {
        ponentes = ponentes + $(this).attr('ds_nombre') + " - " + $(this).attr('ds_cargo') + " / ";
    });
    if (ponentes.length > 0)
        ponentes = ponentes.slice(0, ponentes.length - 2);

    var titulo = '';
    if ($(Actividad).attr('ds_titulo') != undefined) {
        titulo = $(Actividad).attr('ds_titulo');//.toUpperCase();
    }
    if ($(Actividad).attr('ds_descripcion') != undefined) {
        descrip = $(Actividad).attr('ds_descripcion');
        //if (tmp.length > 170) {
        //    descrip = tmp.slice(0, 170);
        //}
        //else {
        //    descrip = tmp;
        //}
    }

    if (GetObjAgenda().FotoIndiceGrande) {
        foto = "FotoActividad_grande";
    }
    else {
        foto = "FotoActividad_mini";
    }

    if ($(Actividad).attr(foto) != undefined) {
        img = $(Actividad).attr(foto);
        if (img.length > 0)
            img = "<img class='fEvento' src='" + GetObjGlobales().getRutaFichero("I", img) + "'  idActividad=" + $(Actividad).attr('id_actividad') + " />";
        else
            //                img = "<img class='fEvento' src='' style='width:75px;display:none' idActividad='' />";
            img = "<img class='fEvento' src='img/sin-imagen.png'  idActividad=" + $(Actividad).attr('id_actividad') + " />";;
    }
    
    var horario = "";
    if ($(Actividad).attr('h_inicio') != "") {
        horario = $(Actividad).attr('h_inicio') + " - " + $(Actividad).attr('h_fin');
    }
    else {
        horario = $(Actividad).attr('duracion');
    }

    if (img == "")
        img = "&nbsp";
    obj.txt = obj.txt +
        "<div class='BloqueIndices row' miacor='" + "DET_" + iden + "' idActividad=" + $(Actividad).attr('id_actividad') + ">" +
            "<div class='DatosIzquierda company-box-image col-xs-12 col-sm-2 col-md-2 col-lg-2 text-left'>" +
                "<div class='PanelDatosI'>        " +
                img +
                "</div>" +
            "</div>" +
            "<div class='company-box-text col-xs-12 col-sm-10 col-md-10 col-lg-10'>" +
                "<div class='BloqueHorizontalTitulo'>" +
                    "<div class='DatosCentralColor'  >" +
                        "<span class='sm-event-date'>" + $(Actividad).attr('d_fecha') + " " + horario + "</span>" +
                        "<p class='company-place'>" + $(Actividad).attr('ds_ubicacion') + "</p>" +
                    "<b>" + titulo + "</b></div>" +
                "</div>" +
                "<div class='BloqueHorizontal' >" +
                    "<div class='DatosCentral'>" +
                        "<div>" +
                            "<p>" + descrip + "</p>" +
                        "</div>" +
                    "</div>" +
                "</div>" +
            "</div>" +
        "<div class='DatosDerecha company-box-links col-xs-12 col-sm-12 col-md-12 col-lg-12'>";
            if (GetObjAgenda().modoPIM == 'N') {
                obj.txt = obj.txt +
                    "   <div class='fav icon-star' fav='favorito' src='img/fav_off.png' idActividad=" + $(Actividad).attr('id_actividad') + "  favoriteado=''>" +
                    "<label class='hidden-xs'>Favorito</label>" + "</div>";
            }
            if (GetObjAgenda().hayoutlook) {
                obj.txt = obj.txt + "   <div class='icon-calendar-5 outlook' src='img/outlook-icon.jpg'  titulo='" + $(Actividad).attr('ds_titulo') +
                    "' hinicio=" + $(Actividad).attr('h_inicio') +
                    " hfin=" + $(Actividad).attr('h_fin') +
                    " duracion=" + $(Actividad).attr('duracion') +
                    " fecha=" + $(Actividad).attr('d_fecha') + "> " +
                    "<label class='hidden-xs'>" + GetObjAgenda().guardaCal + "</label>" + "</div>";
            }

    obj.txt = obj.txt + "<div class='pull-right hidden-xs hidden-sm'><span class='fEvento'>" + GetObjAgenda().vermas + "</span></div></div></div>";
}

//************ listado ponente
function CargarPonente() {
    var myObj = { txt: "" };
    $("#dvPonente").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "IndicePonentes" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TIndicePonente').each(function () {
                FiltrarPonente(myObj, $(this));
            });
            PintarCierre(myObj, $("#dvPonente"));
            eventosFichaPonente("#dvPonente");
            ListadoPonente();
            if (GetObjAgenda().eventoRefresh)
                GetObjAgenda().eventoRefresh();

        },
        error: errorAjax
    });
    //}
    //else {
    //    eventosFichaPonente("#dvPonente");
    //    ListadoPonente();
    //    if (GetObjAgenda().eventoRefresh)
    //        GetObjAgenda().eventoRefresh();
    //}
}

function CargarPonente2() {
    var myObj = { txt: "" };
    $("#dvPonente").html("");
    if ($("#txPonente").val().length > 0) {
        var ruta = GetObjGlobales().getRutaFichero("X", "IndicePonentes" + GetObjAgenda().extFile);
        $.ajax({
            type: "GET",
            url: ruta,
            dataType: "xml",
            success: function (xml) {
                $(xml).find('TIndicePonente').each(function () {
                    FiltrarPonente(myObj, $(this));
                });
                PintarCierre(myObj, $("#dvPonente"));
                eventosFichaPonente("#dvPonente");
                ListadoPonente();
                if (GetObjAgenda().eventoRefresh)
                    GetObjAgenda().eventoRefresh();

            },
            error: errorAjax
        });
    }
    else {
        CargarPonente();

    }
}

function errorAjax(result) {
    alert('ERROR ' + result.status + ' ' + result.statusText);
}


function FiltrarActividad(obj, Actividad, iden, divDia, dia, tipo) {
    if ($("#txtActividad").val().length == 0) {
        if (PintaCabecera == 0) {
            PintarCabecera(divDia, dia, iden);
            PintaCabecera = 1;
        }
        PintarActividadFecha(obj, Actividad, iden);
    }
    else {
        var n = $(Actividad).attr('ds_contacto_texto').toUpperCase();
        var p = $("#txtActividad").val().toUpperCase();
        var d = $(Actividad).attr('ds_descripcion').toUpperCase();
        var c = $(Actividad).attr('ds_titulo').toUpperCase();
        var e = $(Actividad).attr('ds_organiza').toUpperCase();

        if ((n.indexOf(p) > -1) || (d.indexOf(p) > -1) || (c.indexOf(p) > -1) || (e.indexOf(p) > -1)) {
            if (PintaCabecera == 0) {
                PintarCabecera(divDia, dia, iden);
                PintaCabecera = 1;
            }

            switch (tipo) {
                case 'Fecha':
                    PintarActividadFecha(obj, Actividad, iden);
                    break;
                case 'Categoria':
                    PintarActividadCategoria(obj, Actividad, iden);
                    break;
                case 'Ubicacion':
                    PintarActividadUbicacion(obj, Actividad, iden);
                    break;
            }
        }
    }
}

//function FiltrarPintaCategoria(obj, Actividad, iden, divCat, nomCat) {
//    if ($("#txtActividad").val().length == 0) {
//        PintarActividadCategoria(obj, Actividad, iden);
//    }
//    else {
//        var n = $(Actividad).attr('ds_contacto_texto').toUpperCase();
//        var p = $("#txtActividad").val().toUpperCase();
//        var d = $(Actividad).attr('ds_descripcion').toUpperCase();
//        var c = $(Actividad).attr('ds_titulo').toUpperCase();
//        var e = $(Actividad).attr('ds_organiza').toUpperCase();

//        if ((n.indexOf(p) > -1) || (d.indexOf(p) > -1) || (c.indexOf(p) > -1) || (e.indexOf(p) > -1)) {
//            PintarActividadCategoria(obj, Actividad, iden);
//        }
//    }
//}

function FiltrarPonente(obj, Actividad) {
    if ($("#txPonente").val().length == 0) {
        PintarPonente(obj, Actividad);
    }
    else {
        var n = $(Actividad).attr('ds_nombre').toUpperCase();
        var p = $("#txPonente").val().toUpperCase();
        var d = $(Actividad).attr('ds_descripcion').toUpperCase();
        var c = $(Actividad).attr('ds_cargo').toUpperCase();
        if ((n.indexOf(p) > -1) || (d.indexOf(p) > -1) || (c.indexOf(p) > -1)) {
            PintarPonente(obj, Actividad);
        }
    }
}
function eventosFichaPonente(contenedor) {
    $(contenedor).find('.fPonentes,.fCabPonentes, .BloqueIndices').each(function () {
        //          $(this).unbind('click');        	
        idPonente = $(this).attr('idPonente');
        if (idPonente > 0) {
            $(this).unbind('click').bind('click', function (e) {
                //	            $(this).click(function (e) {

                if (isDoubleClickBug(e)) {
                    return true;
                }

                idPonente = $(this).attr('idPonente');
                if (idPonente > 0) {
                    //No quitar este setimeout que si no salta 2 clicks	                    	
                    setTimeout(function () { window.location.hash = "AGENDA_FICHA_PONENTE_" + idPonente }, 200);
                }

                /*var current = new Date().getTime();
                var delta = current - lastClickTime;
                if (delta < 350) {
                    //$('.cabecerafecha span').text(0);
                    // This happens because of a bug, so we ignore it.
                    // http://code.google.com/p/android/issues/detail?id=38808
                } else {
                    idPonente = $(this).attr('idPonente');
                    if (idPonente > 0) {
                        //No quitar este setimeout que si no salta 2 clicks	                    	
                        setTimeout(function () { window.location.hash = "AGENDA_FICHA_PONENTE_" + idPonente }, 200);
                    }
                    //            if (GetObjAgenda().NavegarAgenda) {
                    //                GetObjAgenda().NavegarAgenda("AGENDA_FICHA_PONENTE_" + idPonente);
                    //            }
                    //            else {
                    //                LoadPonente($(this).attr('idPonente'));
                    //            }
                    //            $(contenedor).hide();
                    //            $("#FichaPonente").show();
                }
                lastClickTime = current;

                e.stopPropagation();
                e.preventDefault();
                return false;*/

            });
        }
    });
}

function PintarPonente(obj, Actividad) {
    var redes = ""; var target = ""; var i = 0;
    var descrip = ""; var tmp = ""; var img = "";
    var foto = "";
    if (GetObjAgenda().modoPIM == 'N') {
        $(Actividad).find('TRedSocial').each(function () {
            target = "";
            if (i < 2) {
                if ($(this).attr('link') != undefined) {
                    if ($(this).attr('link').indexOf("http") == -1) {
                        target = "http://" + $(this).attr('link');
                    }
                    else {
                        target = $(this).attr('link');
                    }
                }
                redes = redes + "<a href='" + target + "' target='_blank'> <img class='fredsocial' src='" + GetObjGlobales().getRutaFichero("I", $(this).attr('logo_mini')) + "' /> </a>";
            }
            i++;
        });
    }

    var nombre = '';
    if ($(Actividad).attr('ds_nombre') != undefined) {
        nombre = $(Actividad).attr('ds_nombre');//.toUpperCase();
    }
    var cargo = '';
    if ($(Actividad).attr('ds_cargo') != undefined) {
        cargo = $(Actividad).attr('ds_cargo');//.toUpperCase();
    }
    if ($(Actividad).attr('ds_descripcion') != undefined) {
        descrip = $(Actividad).attr('ds_descripcion');
    //    if (tmp.length > 170) {
    //        descrip = tmp.slice(0, 170);
    //    }
    //    else {
    //        descrip = tmp;
    //}
    }

    if (GetObjAgenda().FotoIndiceGrande) {
        foto = "foto_grande";
    }
    else {
        foto = "foto_mini";
    }

    if ($(Actividad).attr(foto) != undefined) {
        img = $(Actividad).attr(foto);
        if (img.length > 0)
            img = "<img src='" + GetObjGlobales().getRutaFichero("I", img) + "' idPonente=" + $(Actividad).attr('id_ponente') + " class='fPonentes' />";
        else {
            if (GetObjAgenda().FotoIndiceGrande) {
                foto = "user-profile.png";
            }
            else {
                foto = "user-profile.png";
        }

            img = "<img src='img/" + foto + "' class='fPonentes' idPonente=" + $(Actividad).attr('id_ponente') + " style='height: 100%!important; width: 100%!important' />";
    }
        //img = "<img src='' style='display:none' idPonente='' class='fPonentes' /> ";
    }

    if (img == "")
        img = "&nbsp";

        //obj.txt = obj.txt +
        //"<div class='BloqueIndices'> " +
        //    "    <div class='DatosIzquierda'> " +
        //    "      <div class='PanelDatosI'>         " +
        //               img +
        //    "      </div> " +
        //    "    </div> " +
        //    "    <div class='DatosCentral'> " +
        //    "        <div class='fCabPonentes' idPonente=" + $(Actividad).attr('id_ponente') + " ><p><b>" + nombre + " - </b> " + cargo + "</p></div> " +
        //    "        <p>" + descrip + "<span class='fPonentes' idPonente=" + $(Actividad).attr('id_ponente') + " > " + GetObjAgenda().vermas + "</span> </p> " +
        //    "    </div> " +
        //    "    <div class='DatosDerecha'> " +
        //          redes +
        //    "    </div> " +
        //    "</div> ";
    obj.txt = obj.txt +
   "<div class='BloqueIndices row' idPonente=" + $(Actividad).attr('id_ponente') + "> " +
       "    <div class='DatosIzquierda col-xs-12 col-sm-2 col-md-2 col-lg-2'> " +
       "      <div class='PanelDatosI'>         " +
                  img +
       "      </div> " +
       "    </div> " +
       "    <div class='DatosCentralOscuro col-xs-12 col-sm-8 col-md-8 col-lg-8'> " +
       "        <div class='fCabPonentes nombre-ponente'  ><b>" + nombre + " </b> </div> " +
       "        <div class='cargo-ponente'  >" + cargo + "</div> " +
        "        <div class='descrip-ponente'  >" + descrip + "</div> " +
        "       </div> " +
        "    <div class='DatosCentral hidden-xs col-sm-2 col-md-2 col-lg-2'> " +
        "       <span class='fPonentes'  > " + GetObjAgenda().vermas + "</span>  " +
        "    </div> " +
      "    <div class='DatosDerecha hidden'> " +
             redes +
       "    </div> " +
       "</div> ";
}

//************ listado categoria
function ExistenCategorias() {
    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceCategoria" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            //alert("C" + $(xml).find("ArrayOfTCategoriaActividades TCategoriaActividades").length);
            if ($(xml).find("ArrayOfTCategoriaActividades TCategoriaActividades").length > 0) {
                $("#dOpCategoria").show();
            }
        }
    });
}

function ExistePonentes() {
    var ruta = GetObjGlobales().getRutaFichero("X", "IndicePonentes" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            //alert("P " + $(xml).find("ArrayOfTIndicePonente TIndicePonente").length);
            if ($(xml).find("ArrayOfTIndicePonente TIndicePonente").length > 0) {
                $("#dOpPonente").show();
            }
        }
    });
}

function CargarCategoria() {
    var myObj = { txt: "" };
    //if (GetObjAgenda().updateTematica) { //$("#dvCategoria").children().length == 0) {
    $("#dvCategoria").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceCategoria" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {

            $(xml).find('ArrayOfTCategoriaActividades TCategoriaActividades:not(ArrayOfTCategoriaActividades TCategoriaActividades ListaCategoriasHijas TCategoriaActividades)').each(function () {
                var idcat = "CAT_" + $(this).attr("id_categoria");
                var divCat = $("#dvCategoria");
                var idCat = $(this).attr("id_categoria");
                var nomCat = $(this).attr("categoria");
                PintaCabecera = 0;

                if ($(this).find('ListaCategoriasHijas').children().length > 0) {
                    if (GetObjAgenda().EstiloCategoriaBotones == true)
                        PintarSubCategorias(myObj, $(this), idcat, divCat, nomCat);
                    else
                        PintarSubCategoriasEstiloLista(myObj, $(this), idcat, divCat, nomCat);
                    if (PintaCabecera == 1) { PintarCierreCategoria(myObj, divCat, idCat); }
                }
                else {
                    // pintar actividad
                    //PintarCabecera(divCat, nomCat, idcat);
                    $(this).find('TActividad').each(function () {
                        FiltrarActividad(myObj, $(this), idcat, divCat, nomCat, 'Categoria');
                        //PintarActividadCategoria(myObj, $(this), idcat);
                    });
                    if (PintaCabecera == 1) { PintarCierreCategoria(myObj, divCat, idCat); }
                        
                    // fin pintar actividad
                }
            });
            CargarFavoritoAgenda($("#dvCategoria"), OnOff);
            EventoFavorito("#dvCategoria");
            eventosAccesoFichaEvento("#dvCategoria");
            ListadoCategoria();
            CrearEventosBotonesSubCategorias($("#dvCategoria"));
            if (GetObjAgenda().eventoAfterPintarIndice) {
                GetObjAgenda().eventoAfterPintarIndice($('#dvCategoria'), 'CAT_');
            }
            if (GetObjAgenda().eventoRefresh)
                GetObjAgenda().eventoRefresh();
        }
    });
}

function CargarUbicacion() {
    var myObj = { txt: "" };
    $("#dvUbicacion").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceUbicacion" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TUbicacionAgenda').each(function () {
                var iden = "UBI_" + $(this).attr('id_ubicacion_agenda');
                var divUbicacion = $("#dvUbicacion");
                var ubicacion = $(this).attr('ubicacion');
                PintaCabecera = 0;
                //crear Dia 
                //PintarCabecera($("#dvUbicacion"), $(this).attr('ubicacion'), iden);
                //crear eventos
                $(this).find('TActividad').each(function () {
                    FiltrarActividad(myObj, $(this), iden, divUbicacion, ubicacion, 'Ubicacion');
                    //PintarActividadUbicacion(myObj, $(this), iden);
                });
                if (PintaCabecera == 1) { PintarCierre(myObj, divUbicacion); }
            });
            CargarFavoritoAgenda($("#dvUbicacion"), OnOff);
            EventoFavorito("#dvUbicacion");
            eventosAccesoFichaEvento("#dvUbicacion");
            ListadoUbiacion();
            if (GetObjAgenda().eventoAfterPintarIndice)
                GetObjAgenda().eventoAfterPintarIndice($('#dvUbicacion'), 'UBI_');
            if (GetObjAgenda().eventoRefresh)
                GetObjAgenda().eventoRefresh();

        }
    });
}




function CrearEventosBotonesSubCategorias(contenedor) {
    var sc = "";
    var myObj = { txt: "" };
    $(contenedor).find('.btn-gris-subcategoria').each(function () {
        $(this).click(function () {
            var myboton = "#" + $(this).attr('id');

            if ($(this).attr('class') == 'btn-azul-subcategoria') {
                //$("#dvCategoria").html("");
                //CargarCategoria();
            }
            else {
                var id_boton_seleccionado = $(this).attr('id');
                sc = $(this).attr('id').split("_");
                var botonera = $("#Botonera_" + sc[1]).html();
                $("#Categoria_" + sc[1]).html("<div class='BloqueHorizontalSubC' id = 'Botonera_" + sc[1] + "'> " + botonera + "</div>");
                $("#Categoria_" + sc[1]).find('.btn-gris-subcategoria, .btn-azul-subcategoria').each(function () {
                    if ($(this).attr('id') == id_boton_seleccionado) {
                        $(this).attr('class', 'btn-azul-subcategoria');
                    }
                    else {
                        $(this).attr('class', 'btn-gris-subcategoria');
                    }

                });
                // debugger
                //si pulsa en todos debemos cargar todos los eventos de esta categoria
                if (($(myboton).text().localeCompare('Todos') == 0) || ($(myboton).text().localeCompare('All') == 0)) {
                    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceCategoria" + GetObjAgenda().extFile);
                    $.ajax({
                        type: "GET",
                        url: ruta,
                        dataType: "xml",
                        success: function (xml) {
                            $(xml).find('ArrayOfTCategoriaActividades TCategoriaActividades[id_categoria=' + sc[1] + ']').each(function () {
                                $(this).find('ListaCategoriasHijas TCategoriaActividades Actividades TActividad').each(function () {
                                    PintarActividadCategoria(myObj, $(this), sc[1]);
                                    $("#Categoria_" + sc[1]).append(myObj.txt);
                                    myObj.txt = "";
                                });
                            });
                            CargarFavoritoAgenda($("#dvCategoria"), OnOff);
                            EventoFavorito("#dvCategoria");
                            eventosAccesoFichaEvento("#dvCategoria");
                        }
                    });
                }
                else {
                    //pintar en categoria activa todos los eventos que están en el xml 
                    var ruta = GetObjGlobales().getRutaFichero("X", "IndiceCategoria" + GetObjAgenda().extFile);
                    $.ajax({
                        type: "GET",
                        url: ruta,
                        dataType: "xml",
                        success: function (xml) {
                            $(xml).find('TActividad[id_categoria=' + sc[2] + ']').each(function () {
                                PintarActividadCategoria(myObj, $(this), sc[2]);
                                $("#Categoria_" + sc[1]).append(myObj.txt);
                                myObj.txt = "";
                            });
                            CargarFavoritoAgenda($("#dvCategoria"), OnOff);
                            EventoFavorito("#dvCategoria");
                            eventosAccesoFichaEvento("#dvCategoria");
                        }
                    });
                }
                CrearEventosBotonesSubCategorias($("#Categoria_" + sc[1]));
            }
        });
    });
}

function PintarSubCategorias(obj, categoria, iden, divCat, nomCat) {
    TieneSubCategorias = 0;
    TieneSubCategoriasF(categoria);
    if (TieneSubCategorias == 1) {
        PintarCabecera(divCat, nomCat, iden);
        PintaCabecera = 1;
        obj.txt = obj.txt + "<div class='BloqueHorizontalSubC' id = 'Botonera_" + $(categoria).attr("id_categoria") + "'> " +
                            " <div class='BloqueHorizontalTitulo'>" +
                            "   <div class='DatosCentralColorSubC'>";

        $(categoria).find('ListaCategoriasHijas TCategoriaActividades').each(function () {
            //obj.txt = obj.txt + "<input class='btn-gris-subcategoria' id='SC_" + $(categoria).attr("id_categoria") + "_" +
            //$(this).attr("id_categoria") + "'  value='" + $(this).attr("categoria") + "' style='margin-right:10px!important;' type='submit'>";
            obj.txt = obj.txt + "<div class='btn-gris-subcategoria' id='SC_" + $(categoria).attr("id_categoria") + "_" +
            $(this).attr("id_categoria") + "' style='margin-right:10px!important;' >" + $(this).attr("categoria") + "</div>";
        });
        //añado una categoria TODOS
        //obj.txt = obj.txt + "<input name='' class='btn-azul-subcategoria' id='SC_" + $(categoria).attr("id_categoria") + "_1' value='"+GetObjAgenda().todos+"' style='margin-right:10px!important;' type='submit'>";
        obj.txt = obj.txt + "<div class='btn-azul-subcategoria' id='SC_" + $(categoria).attr("id_categoria") + "_1' style='margin-right:10px!important;'>" + GetObjAgenda().todos + "</div>";

        obj.txt = obj.txt + " </div> </div> </div> ";

        //**** se debería ordenar las actividades por fecha/hora de inicio
        var idcat = $(categoria).attr("id_categoria");
        $(categoria).find('ListaCategoriasHijas').each(function () {
            $(this).find('TCategoriaActividades Actividades TActividad').each(function () {
                FiltrarActividad(obj, $(this), idcat, divCat, nomCat, 'Categoria');
            });
        });
    }
}

function PintarSubCategoriasEstiloLista(obj, categoria, iden, divCat, nomCat) {
    TieneSubCategorias = 0;
    TieneSubCategoriasEstiloLista(categoria);
    if (TieneSubCategorias == 1)
    {
        PintarCabecera(divCat, nomCat, iden);
        PintaCabecera = 1;
        obj.txt = obj.txt + "<div class='BloqueHorizontalSubC' id = 'Botonera_" + $(categoria).attr("id_categoria") + "'> " +
                            " <div class='BloqueHorizontalTitulo'>" +
                            "   <div class='DatosCentralColorSubC' miacor='DET_" + iden + "' >";
        $(categoria).find('ListaCategoriasHijas TCategoriaActividades').each(function () {
            var idsubcat = "SUB_" + iden + "_" + $(this).attr("id_categoria");
            obj.txt = obj.txt + "<div class='cabecerahijacomun' miacor='" + idsubcat + "' estado='C'><span class='spancabeceracomun'>" + $(this).attr("categoria") + "</span></div>";
            //**** se debería ordenar las actividades por fecha/hora de inicio

            $(this).find('Actividades TActividad').each(function () {
                FiltrarActividad(obj, $(this), idsubcat, divCat, nomCat, 'Categoria');
            });

        });
        obj.txt = obj.txt + "</div></div></div>";
    }
}

function TieneSubCategoriasF(categoria) {
    $(categoria).find('ListaCategoriasHijas').each(function () {
        $(this).find('TCategoriaActividades Actividades TActividad').each(function () {
            TieneActividadCategoria($(this));
        });
    });
}

function TieneSubCategoriasEstiloLista(categoria) {
    $(categoria).find('ListaCategoriasHijas TCategoriaActividades').each(function () {
        $(this).find('Actividades TActividad').each(function () {
            TieneActividadCategoria($(this));
        });
    });
}

function TieneActividadCategoria(Actividad) {
    var n = $(Actividad).attr('ds_contacto_texto').toUpperCase();
    var p = $("#txtActividad").val().toUpperCase();
    var d = $(Actividad).attr('ds_descripcion').toUpperCase();
    var c = $(Actividad).attr('ds_titulo').toUpperCase();
    var e = $(Actividad).attr('ds_organiza').toUpperCase();

    if ((n.indexOf(p) > -1) || (d.indexOf(p) > -1) || (c.indexOf(p) > -1) || (e.indexOf(p) > -1)) {
        TieneSubCategorias = 1;
    }
}

function PintarActividadCategoria(obj, Actividad, iden) {
    var ponentes = ""; var descrip = ""; var tmp = ""; var img = "";
    var foto = "";
    $(Actividad).find('TPonente').each(function () {
        ponentes = ponentes + $(this).attr('ds_nombre') + " - " + $(this).attr('ds_cargo') + " / ";
    });
    if (ponentes.length > 0)
        ponentes = ponentes.slice(0, ponentes.length - 2);

    var titulo = '';
    if ($(Actividad).attr('ds_titulo') != undefined) {
        titulo = $(Actividad).attr('ds_titulo');//.toUpperCase();
    }
    if ($(Actividad).attr('ds_descripcion') != undefined) {
        descrip = $(Actividad).attr('ds_descripcion');
        //if (tmp.length > 170) {
        //    descrip = tmp.slice(0, 170);
        //}
        //else {
        //    descrip = tmp;
        //}
    }
    if (GetObjAgenda().FotoIndiceGrande) {
        foto = "FotoActividad_grande";
    }
    else {
        foto = "FotoActividad_mini";
    }

    if ($(Actividad).attr(foto) != undefined) {
        img = $(Actividad).attr(foto);
        if (img.length > 0)
            img = "<img idActividad='" + $(Actividad).attr('id_actividad') + "' class='fEvento' src='" + GetObjGlobales().getRutaFichero("I", img) + "' />";
        else
            img = "<img idActividad='" + $(Actividad).attr('id_actividad') + "' class='fEvento' src='img/sin-imagen.png' />";
        //img = "<img idActividad='' class='fEvento' src='' style='width:75px;display:none' />";
    }

    var horario = "";
    if ($(Actividad).attr('h_inicio') != "") {
        horario = $(Actividad).attr('h_inicio') + " - " + $(Actividad).attr('h_fin');
    }
    else {
        horario = $(Actividad).attr('duracion');
    }
    
    if (img == "")
        img = "&nbsp";
    obj.txt = obj.txt +
        "<div class='BloqueIndices row' miacor='" + "DET_" + iden + "' idActividad=" + $(Actividad).attr('id_actividad') + ">" +
        "<div class='DatosIzquierda company-box-image col-xs-12 col-sm-2 col-md-2 col-lg-2 text-left'>" +
        "<div class='PanelDatosI'>        " +
        img +
        "</div>" +
        "</div>" +
        "<div class='company-box-text col-xs-12 col-sm-10 col-md-10 col-lg-10'>" +
        "<div class='BloqueHorizontalTitulo'>" +
            "<div idActividad='" + $(Actividad).attr('id_actividad') + "' class='DatosCentralColor'>" +
                "<span class='sm-event-date'>" + $(Actividad).attr('d_fecha') + ' - ' + horario + "</span>" +
                "<p class='company-place'>" + $(Actividad).attr('ds_ubicacion') + "</p>" +
                "<b>" + titulo + "</b>" +
            "</div>" +
        "</div>" +
        "<div class='BloqueHorizontal' >" +
        "<div class='DatosCentral' >" +
        "<div>" +
        "<p>" + descrip + " </p>" +
        "</div>" +
        "</div>" +

        "</div>" +
        "</div>" +
        "<div class='DatosDerecha company-box-links col-xs-12 col-sm-12 col-md-12 col-lg-12'>";
    
    if (GetObjAgenda().modoPIM == 'N') {
        obj.txt = obj.txt +
            "<div class='fav icon-star' fav='favorito' src='img/fav_off.png' idActividad=" + $(Actividad).attr('id_actividad') + "  favoriteado=''>" + "<label class='hidden-xs'>Favorito</label>" + "</div>";
    }
    if (GetObjAgenda().hayoutlook) {
        obj.txt = obj.txt +
            "<div class='icon-calendar-5 outlook'  src='img/outlook-icon.jpg'  titulo='" + $(Actividad).attr('ds_titulo') +
            "' hinicio=" + $(Actividad).attr('h_inicio') +
            " hfin=" + $(Actividad).attr('h_fin') +
            " duracion=" + $(Actividad).attr('duracion') +
            " fecha=" + $(Actividad).attr('d_fecha') + "> " + "<label class='hidden-xs'>" + GetObjAgenda().guardaCal + "</label>" + "</div>";
    }
    obj.txt = obj.txt + "<div class='pull-right hidden-xs hidden-sm'><span class='fEvento'>" + GetObjAgenda().vermas + "</span></div>";
    obj.txt = obj.txt +
        " </div>" +
        "</div>";
}


//****************************************************** COMUNES 
function PintarCabecera(Contenedor, fecha, iden) {
    //$(Contenedor).append("<span>" + fecha + "</span>");
    $(Contenedor).append("<div class='cabeceracomun' miacor=" + iden + " estado='C'><span class='spancabeceracomun'>" + fecha + "</span></div>");
}
function PintarCierre(obj, Contenedor) {
    $(Contenedor).append("<div>" + obj.txt + "</div>");
    obj.txt = "";
}

function PintarCierreCategoria(obj, Contenedor, idCategoria) {
    $(Contenedor).append("<div id='Categoria_" + idCategoria + "'>" + obj.txt + "</div>");
    obj.txt = "";
}

//***************************************************** FICHA PONENTE
function LoadPonente(idPonente) {
    $("#FichaPonente").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "FichasPonentes" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TPonente[id_ponente=' + idPonente + ']').each(function () {
                FichaPonente($(this), $("#FichaPonente"));
            });
            accesoFichaEvento("#FichaPonente");

            $("#BusquedaPonente").hide();
            $("#BusquedaActividad").hide();
            $("#TituloAgenda").hide();
            fechaUltimaCarga();

            if ($(".accordion-container").accordion) {
                if ($('.accordion-container').hasClass('ui-accordion')) {
                    $('.accordion-container').accordion('refresh');
                    //if (GetObjAgenda().activoUbicacion != false)
                    //    $('#dvUbicacion').accordion("option", "active", GetObjAgenda().activoUbicacion);

                } else {
                    $(".accordion-container").accordion({
                        active: 0,
                        collapsible: true,
                        heightStyle: "content",
                        activate: function (event, ui) {
                            setAlto($("#FichaPonente").height() + 150);
                            //GetObjAgenda().activoUbicacion = $("#dvUbicacion").accordion("option", "active");
                        }
                    });
                }
            }
            setAlto($("#FichaPonente").height() + 150);
        }
    });
}

function accesoFichaEvento(contenedor) {
    $(contenedor).find('.DatosCentralPonente').each(function () {
        $(this).unbind('click');
        $(this).click(function () {
            var idEvento = $(this).attr('idEvento');
            window.location.hash = "AGENDA_FICHA_EVENTO_" + idEvento;
            //            if (GetObjAgenda().NavegarAgenda) {
            //                GetObjAgenda().NavegarAgenda("AGENDA_FICHA_EVENTO_" + idEvento);
            //            }
            //            else {
            //                LoadEvento($(this).attr('idEvento'));
            //            }
            //            $("#FichaEvento").show();
            //            $("#FichaPonente").hide();
        });
    });
}

        


function FichaPonente(Ponente, contenedor) {
    var txt = ""; var redes = ""; var ponencias = "";
    var titulo = ""; var target = ""; var img = ""; var intervieneComo = "";

    //redes sociales
    if (GetObjAgenda().modoPIM == 'N') {
        $(Ponente).find('TRedSocial').each(function () {
            target = "";
            if ($(this).attr('link') != undefined) {
                if ($(this).attr('link').indexOf("http") == -1) {
                    target = "http://" + $(this).attr('link');
                }
                else {
                    target = $(this).attr('link');
                }
            }
            redes = redes + " <div class='opcionPonente'> <a href='" + target + "' target='_blank'>  <img class='fredsocial' src='" + GetObjGlobales().getRutaFichero("I", $(this).attr('logo_mini')) + "' /> </a> </div>";
        });
        if (redes.length > 0) {
            redes = "<div class='BloqueHorizontalRedesSociales'>  <div id='OpcionesPonente'>" + redes + "</div>  </div>";
        }
    }


        //ponencias
        var index_ponencias = 1;
        $(Ponente).find('TActividad').each(function () {
            intervieneComo = "";
            $(this).find('intervenciones TIntervieneComo').each(function () {
                intervieneComo = $(this).attr('ds_descripcion');
            });

        if ($(this).attr('ds_titulo') != undefined) {
            titulo = $(this).attr('ds_titulo');//.toUpperCase();
        }
        else {
            titulo = "";
        }
        var horario = "";
        if ($(this).attr('h_inicio') != "") {
            horario = $(this).attr('h_inicio') + " - " +$(this).attr('h_fin');
        }
        else {
            horario = $(this).attr('duracion');
        }
            ponencias = ponencias +
                "<div class='ponencia-title'>" +
                "<span class='ponencia-link'>" + GetObjAgenda().ponencia + " " + index_ponencias + "</span>" +
                "</div>" +
                "<div idEvento=" + $(this).attr('id_actividad') + " class='ponencia-content DatosCentralPonente'>" +
                "<span class='eventoPonente'>" + titulo + " (" + intervieneComo + ")" + "</span>" +
                "<div class='row'>" +
                "<div class='col-xs-12 col-sm-6 col-md-6 col-lg-6'><div class='fechaPonente'>" +
                "<div class='lbTituloPonencia icon-calendar-5'>" + GetObjAgenda().fecha + "</div>" +
                $(this).attr('d_fecha') + " " + horario +
                "</div></div>" +
                "<div class='col-xs-12 col-sm-6 col-md-6 col-lg-6'><div class='salaPonente'>" +
                "<div class='lbTituloPonencia icon-placeholder-3'>" + GetObjAgenda().lugar + "</div>" +
                $(this).attr('ds_ubicacion') +
                "</div></div>" +
                "</div>" +
                "</div>"; //.toUpperCase()
            index_ponencias++;
    });
    if (ponencias.length > 0) {
        ponencias = "<div class='BloqueHorizontalPonenteI'><div class='cabecerahijacomunficha'><span class='spancomun'>" + GetObjAgenda().ponenecias + "</span></div><div class='accordion-container'>" + ponencias + "</div></div>";
    }
    fotoponente = 'foto_grande';
    if ($(Ponente).attr(fotoponente) != undefined) {
        img = $(Ponente).attr(fotoponente);
        if (img.length > 1)
            img = "<img class='fotoPonente' src='" +GetObjGlobales().getRutaFichero("I", img) + "' onload='onLoadImagen()' />";
        else
            //img = "<img class='fotoPonente' style='display:none' src='' onload='onLoadImagen()' />";
            img = "<img class='fotoPonente' src='img/user-profile.png' onload='onLoadImagen()'/>";
    }
    if (img == "")
        img = "&nbsp";

    txt = "<div class='BloqueHorizontalCabeceraPonente'> " +
        
        "  <div class='DatosCentral2'> " +
        "      <div class='BloqueHorizontalPonente'> " +
        "         <p><span class='ponenteNombre'>" + $(Ponente).attr('ds_nombre') + " </span>" +
        "           <span class='ponenteCargo'>" + $(Ponente).attr('ds_cargo') + "</span> " +
        "</p>" +
        "       </div> " + redes +
        "  </div> " +



          "  <div class='DatosIzquierdaPonente'> " +
          "      <div class='PanelDatosI'> " +img + " </div> " +
          "  </div> " +

          "</div>" +
          "<div class='BloqueHorizontalPonenteI'>" +
                      '<div class="cabecerahijacomunficha"><span class="spancomun">' + GetObjAgenda().resumen + '</span></div>' +
                      $(Ponente).attr('ds_descripcion') +
                       " <br /> <br />" +
          "</div>" +
          ponencias;
        //          "<div class='BloqueHorizontalPonenteI'> "+
        //               "<img src='img/back.jpg' id='backPonente' style='width:75px'/>"+
        //          "</div> ";
        contenedor.append(txt);
        //    $("#backPonente").click(function () {
        //        $("#FichaPonente").hide();
        //        $("#dvPonente").show();
        //    });


        if (GetObjAgenda().eventoAfterPintarFichaPonente)
            GetObjAgenda().eventoAfterPintarFichaPonente();

        addeventoanalitics(GetObjAgenda().feria, 'Acceso a Ponente', $(Ponente).attr('ds_nombre'), 0);

}

//***************************************************** FICHA EVENTO
function LoadEvento(idEvento) {
    $("#FichaEvento").html("");
    var ruta = GetObjGlobales().getRutaFichero("X", "FichasActividades" + GetObjAgenda().extFile);
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {

            $(xml).find('TActividad[id_actividad=' + idEvento + ']').each(function () {
                FichaEvento($(this), $("#FichaEvento"));
            });

            $("#BusquedaPonente").hide();
            $("#BusquedaActividad").hide();
            $("#TituloAgenda").hide();
            CargarFavoritoAgenda($("#FichaEvento"), OnOff);
            EventoFavoritoBackground("#FichaEvento");
            accesoFichaPonente("#FichaEvento");
            setAlto($("#FichaEvento").height() + 150);
            fechaUltimaCarga();
            if (GetObjAgenda().eventoRefresh)
                GetObjAgenda().eventoRefresh();

        }
    });
}

function EventoFavoritoBackground(contenedor) {

    //DDC ÑAPA. No sabemos la razón pero con este código siempre ejecuta un dobleclick en los móviles.
    //Por falta de tiempo no podemos investigar más y lo único que hemos podido hacer es esta chapu.

    //    $(contenedor + " .fav").unbind('click').bind('click',function (e) {   	   	
    $(contenedor + ' [fav="favorito"]').unbind('click').bind('click', function (e) {

        if (isDoubleClickBug(e)) {
            return true;
        }

        var localid = $(this).attr('idActividad');
        if ($(this).attr('favoriteado') == 1) {
            GetObjAgenda().deleteEventoArrayFavoritos(localid);
            $(this).attr('src', 'img/fav_off.png');
            $(this).removeClass('icon-star-fill');
            $(this).addClass('icon-star');
            $(this).attr('favoriteado', '0');
        }
        else {
            GetObjAgenda().addEventoArrayFavoritos(localid);
            $(this).attr('src', 'img/fav_on.png');
            $(this).removeClass('icon-star');
            $(this).addClass('icon-star-fill');
            $(this).attr('favoriteado', '1');
        }

        /*var current = new Date().getTime();
        var delta = current - lastClickTime;
        if (delta < 350) {
            //            $('.cabecerafecha span').text(0);
            // This happens because of a bug, so we ignore it.
            // http://code.google.com/p/android/issues/detail?id=38808
        } else {
            var localid = $(this).attr('idActividad');
            if ($(this).attr('favoriteado') == 1) {
                GetObjAgenda().deleteEventoArrayFavoritos(localid);
                $(this).attr('src', 'img/fav_off.png');
                $(this).attr('favoriteado', '0');
            }
            else {
                GetObjAgenda().addEventoArrayFavoritos(localid);
                $(this).attr('src', 'img/fav_on.png');
                $(this).attr('favoriteado', '1');
            }
        }
        lastClickTime = current;

        e.stopPropagation();
        e.preventDefault();
        return false;*/
    });
}

function accesoFichaPonente(contenedor) {
    $(contenedor).find('.CargoPonenteFichaEvento, .fichaPonente, .PonenteFichaEvento').each(function () {
        $(this).unbind('click');
        $(this).click(function () {
            var idPonente = $(this).attr('idPonente');
            window.location.hash = "AGENDA_FICHA_PONENTE_" + idPonente;
        });
    });
    if (GetObjAgenda().modoPIM == "N") {
        $(contenedor).find('.BloqueHorizontalDocumentoEvento').each(function () {
            $(this).unbind('click');
            $(this).click(function () {
                if ($(this).attr('PublicarComoLink') == 'N') {
                    var url = GetObjGlobales().getRutaFichero("R", $(this).attr('documento'));
                }
                else {
                    var url = "";
                    if ((GetObjGlobales().so == SO_ANDROID) || (GetObjGlobales().so == SO_IOS))
                        url = 'https://seguro.ifema.es/waCatalogoMovil/getDocumento.ashx?iddocu=' + $(this).attr('documentoid') + '&idioma=' + GetObjAgenda().idio;
                    else
                        url = GetObjGlobales().rutaroot + 'getDocumento.ashx?iddocu=' + $(this).attr('documentoid') + '&idioma=' + GetObjAgenda().idio;
                }

                if (GetObjGlobales().so == SO_ANDROID) {
                    if ($(this).attr('PublicarComoLink') == 'N')
                        window.fileOpener([url]);
                    else
                        navigator.app.loadUrl(url, { openExternal: true });
                } else if (GetObjGlobales().so == SO_IOS) {
                    window.open(url, '_blank');
                } else {
                    window.open(url);
                }
            });
        });
    }
    if (GetObjAgenda().modoPIM == "N") {
        $(contenedor).find('.BloqueHorizontalLinkEvento').each(function () {
            $(this).unbind('click');
            $(this).click(function () {
                var lurl = $(this).attr('link');
                if (GetObjGlobales().so == SO_ANDROID) {
                    navigator.app.loadUrl(lurl, { openExternal: true })
                } else if (GetObjGlobales().so == SO_IOS) {
                    window.open(lurl, '_system');
                } else {
                    window.open(lurl);
                }
            });
        });
    }
}

function FichaEvento(Actividad, Contenedor) {
    var txt = ""; var nombre = ""; var cargo = ""; var rol = "";
    var ubicaciones = ""; var ponentes = ""; var documentos = ""; var pimg = "";
    var titulo = ""; var categoria = ""; var organizacion = ""; var desc = ""; var target = "";
    var colabora = ""; var contacto = ""; var tipo = ""; var img = ""; var masinfo = ""; var linkmasinfo = ""; var txcontacto = "";
    var foto = "";
    var limiteTam = "";
    //debugger
    //ponentes
    $(Actividad).find('TPonente').each(function () {
        nombre = ""; cargo = ""; rol = "";
        if ($(this).attr('ds_nombre') != undefined) {
            nombre = $(this).attr('ds_nombre');
        }
        else {
            nombre = "";
        }
        if ($(this).attr('ds_intervieneComo') != undefined) {
            rol = $(this).attr('ds_intervieneComo');
        }
        else {
            rol = "";
        }
        if ($(this).attr('ds_cargo') != undefined) {
            cargo = $(this).attr('ds_cargo');
        }
        else {
            cargo = "";
        }

        if (GetObjAgenda().FotoIndiceGrande) {
            foto = "foto_grande";
        }
        else {
            foto = "foto_mini";
        }

        if ($(this).attr(foto) != undefined) {
            pimg = $(this).attr(foto);
            if (pimg.length > 0)
                pimg = "<img class='fotofichaPonente' idPonente=" + $(this).attr('id_ponente') + "  src='" + GetObjGlobales().getRutaFichero("I", pimg) + "' onload='onLoadImagen()'/> ";
            else {
                if (GetObjAgenda().FotoIndiceGrande) {
                    foto = "user-profile.png";
                }
                else {
                    foto = "user-profile.png";
                }

                pimg = "<img class='fotofichaPonente' idPonente=" + $(this).attr('id_ponente') + "  src='img/" + foto + "' onload='onLoadImagen()' style='height: 100%; width: 100%'/> ";
            }
        }

        if (pimg == "")
            pimg = "&nbsp";

        ponentes = ponentes + "<div class='PonenteFichaEvento row' idPonente=" + $(this).attr('id_ponente') + ">" +
                                "    <div class='DatosIzquierda col-xs-12 col-sm-2 col-md-2 col-lg-2'> " +
                                "        <div class='PanelDatosI'> " +
                                          pimg +
                                "        </div> " +
                                "    </div> " +
            "    <div idPonente=" + $(this).attr('id_ponente') + " class='CargoPonenteFichaEvento col-xs-12 col-sm-10 col-md-10 col-lg-10'> " +
                                "       <p><b>" + nombre + " - " + rol + " </b> <br />  " + cargo + "</p> " +
                                "    </div> " +
                                "</div> ";
    });
    if (ponentes.length > 0) {
        ponentes = "<div class='BloqueHorizontal ponentes-evento'><div class='cabecerahijacomunficha'><span class='spancomun'>" + GetObjAgenda().ponentes + "</span></div>" + ponentes + "</div>";
    }

    //ubicaciones
    $(Actividad).find('TActividadUbicacion').each(function () {
        nombre = "";
        if ($(this).attr('ds_nombre') != undefined) {
            nombre = $(this).attr('ds_nombre');
        }
        else {
            nombre = "";
        }
        var horario = "";
        if ($(this).attr('h_inicio') != "") {
            horario = $(this).attr('h_inicio') + " - " + $(this).attr('h_fin');
        }
        else {
            horario = $(this).attr('duracion');
        }
        ubicaciones = ubicaciones + "<div id='lbLugar' class='lbLugar'>" + $(this).attr('d_fecha') + " " + horario + " <span class='ubicacionEvento'>" + nombre + "</span></div>";
    });
    if (ubicaciones.length > 0) {
        ubicaciones = "<div id='divLugar' class='col-xs-12 col-sm-6 col-md-6 col-lg-6'>"+
            "<div class='company-contact-box'>" +
            "<div id='lbTituloLugar' class='icon-placeholder-3'>" + GetObjAgenda().fechaylugar + "</div>" +
            ubicaciones + "</div></div>";
    }


    //documentos
    if (GetObjAgenda().modoPIM == "N") {
        $(Actividad).find('TDocumento').each(function () {
            if ($(this).attr('documento').length > 0) {
                nombre = ""; tipo = ""; img = "";
                if ($(this).attr('ds_nombre') != undefined) {
                    nombre = $(this).attr('ds_nombre');
                }
                else {
                    nombre = "";
                }
                if ($(this).attr('tipo') != undefined) {
                    tipo = $(this).attr('tipo').toUpperCase();
                }
                else {
                    tipo = "";
                }
                if (tipo == 'PDF') {
                    img = "img/pdf.jpg";
                }
                if (tipo == "DOC" || tipo == "DOCX") {
                    img = "img/word.gif";
                }
                if (tipo == "XLSX" || tipo == "XLS") {
                    img = "img/excel.png";
                }

                limiteTam = "N";
                if (($(this).attr('PublicarComoLink') == 'S') && (typeof ($(this).attr('PublicarComoLink')) !== 'undefined')) {
                    limiteTam = $(this).attr('PublicarComoLink');
                }

                documentos = documentos + "<div documento='" + $(this).attr('documento') + "' PublicarComoLink='" + limiteTam +
                "' documentoid='" + $(this).attr('id_documento') +
                "' class='BloqueHorizontalDocumentoEvento' onclick='addEventoAnalyticsDocumento(\"" + nombre + "\")'> " +
                                    "    <div class='DatosIzquierda icon-document'> " +
                                    //"        <div class='PanelDatosI'>   " +
                                    //"            <img src='" + img + "' style='width:40px' /> " +
                                    //"        </div> " +
                                    "    </div> " +
                                    "    <div class='DatosCentralDocumento'> " +
                                    "        <p>" + nombre + "</p> " +
                                    "        <a><p>" + GetObjAgenda().descargaArchivo + "</p></a> " +
                                    "    </div> " +
                                    "</div> ";
            }
            else {
                if ($(this).attr('link') != undefined && $(this).attr('link').length > 0) {
                    nombre = ""; tipo = "";
                    if ($(this).attr('ds_nombre') != undefined) {
                        nombre = $(this).attr('ds_nombre');
                    }
                    else {
                        nombre = "";
                    }
                    if ($(this).attr('link').indexOf("http") == -1) {
                        tipo = "http://" + $(this).attr('link');
                    }
                    else {
                        tipo = $(this).attr('link');
                    }
                    documentos = documentos + "<div link='" + tipo + "' class='BloqueHorizontalLinkEvento'> " +
                                    "    <div class='DatosIzquierda icon-worldwide'></div> " +
                                    "    <div class='DatosCentralDocumento prueba'> " +
                        "        <a><p>" + nombre + "</p></a> " +
                                    "    </div> " +
                        //<div class="row">
                        //    <div class="col-12 h-100 col-lg-6">
                        //        <div class="download-item card pointer">
                        //            <div class="download-type icon-document"></div>
                        //            <div class="download-title">
                        //                <span class="h6">Gran Distribución y Sanidad Vegetal</span>
                        //            </div>
                        //            <div class="download-footer">
                        //                <a href="https://www.ifema.es/general/doc/logo-ifema/logo-ifema.zip">Descargar archivo</a>
                        //            </div>
                        //        </div>
                        
                        //    </div>
                       
                        //</div>


                                    "</div> ";
                }
            }

        });
        if (documentos.length > 0) {
            //        documentos = "<div class='BloqueHorizontal'> <b><div class='cabecerahijacomun'><span class='spancomun'>" + GetObjAgenda().documentos + "</span></div></b><br /><br />" + documentos + "</div><hr class='hrclase'/>";
            //DDC quito los BR. si hiciesen falta controlarlo con estilos
            documentos = "<div class='BloqueHorizontal'><div class='cabecerahijacomunficha'><span class='spancomun'>" + GetObjAgenda().documentos + "</span></div>" + documentos + "</div>";
        }
    }
    if ($(Actividad).attr('ds_titulo') != undefined) {
        titulo = $(Actividad).attr('ds_titulo');
        if (titulo.length > 0) {
            titulo = "<div class='BloqueHorizontalEvento'>" +
                //"<div class='txIzquierda'>" + GetObjAgenda().titulo + "</div>" +
                "<div class='txDerecha eventTitle'> " + titulo + "</div>" +
                "<div class='lastUpdate'></div>" +
            "</div > ";
        }
    }
    else {
        titulo = "";
    }
    if ($(Actividad).attr('ds_categoria') != undefined) {
        categoria = $(Actividad).attr('ds_categoria');
        if (categoria.length > 0) {
            categoria = " <div class='BloqueHorizontalEvento'><div class='txIzquierda'>" + GetObjAgenda().categoria + "</div> <div class='txDerecha'> " + categoria + "</div></div> ";
        }
    }
    else {
        categoria = "";
    }
    if ($(Actividad).attr('ds_organiza') != undefined) {
        organizacion = $(Actividad).attr('ds_organiza');
        if (organizacion.length > 0) {
            organizacion = "     <div class='BloqueHorizontalEvento'><div class='txIzquierda'>" + GetObjAgenda().organizacion + "</div> <div class='txDerecha'> " + organizacion + "</div></div> ";
        }
    }
    else {
        organizacion = "";
    }
    if ($(Actividad).attr('ds_colabora') != undefined) {
        colabora = $(Actividad).attr('ds_colabora');
        if (colabora.length > 0) {
            colabora = "     <div class='BloqueHorizontalEvento'><div class='txIzquierda'>" + GetObjAgenda().colabora + "</div> <div class='txDerecha'> " + colabora + "</div></div> ";
        }
    }
    else {
        colabora = "";
    }

    if ($(Actividad).attr('ds_contacto_texto') != undefined) {
        txcontacto = $(Actividad).attr('ds_contacto_texto');
        if (txcontacto.length > 2) {
            if (GetObjAgenda().modoPIM == "S") {
                if ($(Actividad).attr('ds_contacto') != undefined)
                    contacto = $(Actividad).attr('ds_contacto');

                if (contacto.length > 1)
                    contacto = "<div id='divContacto' class='col-xs-12 col-sm-6 col-md-6 col-lg-6'><div class='company-contact-box' ><div id='lbTituloContacto' class='icon-contact'>" + GetObjAgenda().contacto + "</div> <div class='txDerecha' id='MyMail'> " + txcontacto + "  -  " + contacto + "</div></div></div> ";
                else
                    contacto = "<div id='divContacto' class='col-xs-12 col-sm-6 col-md-6 col-lg-6'><div class='company-contact-box' ><div id='lbTituloContacto' class='icon-contact'>" + GetObjAgenda().contacto + "</div> <div class='txDerecha' id='MyMail'> " + txcontacto + "</div></div></div> ";
            }
            else{
                if ($(Actividad).attr('ds_contacto') != undefined)
                    contacto = "'mailto:" + $(Actividad).attr('ds_contacto') + "'";

                if (contacto.length > 1)
                    contacto = "<div id='divContacto' class='col-xs-12 col-sm-6 col-md-6 col-lg-6'><div class='company-contact-box' ><div id='lbTituloContacto' class='icon-contact'>" + GetObjAgenda().contacto + "</div> <div class='txDerecha' id='MyMail'> <a href=" + contacto + ">" + txcontacto + "</a></div></div></div> ";
                else
                    contacto = "<div id='divContacto' class='col-xs-12 col-sm-6 col-md-6 col-lg-6'><div class='company-contact-box' ><div id='lbTituloContacto' class='icon-contact'>" + GetObjAgenda().contacto + "</div> <div class='txDerecha' id='MyMail' href=''> " + txcontacto + "</div></div></div> ";
            }
        }
    }


    if ($(Actividad).attr('ds_textomasinformacion') != undefined) {
        if ($(Actividad).attr('ds_textomasinformacion').length > 2)
        {
            masinfo = $(Actividad).attr('ds_textomasinformacion');

            if ($(Actividad).attr('ds_linkmasinformacion') != undefined)
            {
                if (GetObjAgenda().modoPIM == "S")
                {
                    masinfo = masinfo +" - "+ $(Actividad).attr('ds_linkmasinformacion');

                }
                else
                {
                    if ($(Actividad).attr('ds_linkmasinformacion').indexOf("http") == -1)
                        target = "http://" + $(Actividad).attr('ds_linkmasinformacion');
                    else
                        target = $(Actividad).attr('ds_linkmasinformacion');

                    masinfo = "<a class='linkMasInformacion' href=" + target + " target='_blank'>" + masinfo + "</a>";
                }
            }
            else
                masinfo = $(Actividad).attr('ds_textomasinformacion');

            if (masinfo.length > 0) {
                masinfo = "<div class='registrationContainer'> " + masinfo + "</div>";
            }
            else {
                masinfo = "";
            }
        }
    }

    pimg = "";
    if ($(Actividad).attr('FotoActividad_grande') != undefined) {

        pimg = $(Actividad).attr('FotoActividad_grande');
        if (pimg.length > 0)
            pimg = "<img class='icoficha' src='" + GetObjGlobales().getRutaFichero("I", pimg) + "' onload='onLoadImagen()'/> ";
        else
            pimg = "<img class='icoficha' src='img/sin-imagen.png' onload='onLoadImagen()'/> ";
    }
    if (pimg == "")
        pimg = "&nbsp";


    if ($(Actividad).attr('ds_descripcion') != undefined) {
        desc = $(Actividad).attr('ds_descripcion');
        if (desc.length > 0) {
            desc = "<div class='BloqueHorizontal'> " +
                   "<div class='cabecerahijacomunficha'><span class='spancomun'>" + GetObjAgenda().resumen + "</span></div>" +
                   "<div class='BloqueHorizontalDescripcionEvento'><p> " + desc + " </p> " +
                   "</div> " +
                   "</div> ";
        }
    }
    txt = "<div class='BloqueHorizontal'> ";

    txt = txt + titulo + " <div class='buttonsContainer'> " + masinfo;

    if (GetObjAgenda().modoPIM == 'N') {
        txt = txt +
            " <div class='favContainer'> " +
            "     <div fav='favorito' class='favficha icon-star-fill' src='img/fav_off.png' idActividad=" + $(Actividad).attr('id_actividad') + " favoriteado=''> " +
            "          <label>" + GetObjAgenda().guardafav + "</label>" +
            "     </div>" +
            " </div>";
    }

    txt = txt + "</div> ";

    txt = txt +
        " <div id='LogoActividadGrande'> " + pimg + "</div> " +
        "<div class='DatosCentralEvento'> " +
        categoria + organizacion + colabora +
        " </div> " +
        "<div class='ResumenEvento'> " + desc + "</div> ";

    txt = txt +
       " </div> " +
        "<div class='BloqueHorizontal row company-contact-container'>" +
            ubicaciones + contacto +
        "</div>" +
        ponentes + documentos;
    Contenedor.append(txt);

    if (GetObjAgenda().eventoAfterPintarFichaEvento)
        GetObjAgenda().eventoAfterPintarFichaEvento();

    addeventoanalitics(GetObjAgenda().feria, 'Acceso a Actividad', $(Actividad).attr('ds_titulo'), 0);
}


function onLoadImagen() {
    if (GetObjAgenda().eventoRefresh)
        GetObjAgenda().eventoRefresh();
}

function abreVentana(url)
{
    if (GetObjGlobales().so == SO_ANDROID) {
        ///window.fileOpener.open(url);
        window.fileOpener([url]);
    } else if (GetObjGlobales().so == SO_IOS) {
        window.open(url, '_blank');
    } else {
        window.open(url);
    }
}

function addEventoAnalyticsDocumento(nombre) {
    addeventoanalitics(GetObjAgenda().feria, 'Descarga de Documento', nombre, 0);
}

function addEventoAnalyticsExport(tipoPDF) {
    var etiqueta = 'Actividades';

    switch (tipoPDF) {
        case 'F':
            etiqueta = etiqueta + '_Día';
            break;
        case 'A':
            etiqueta = etiqueta + '_Temática';
            break;
        case 'U':
            etiqueta = etiqueta + '_Ubicación';
            break;
        case 'P':
            etiqueta = 'Ponenetes';
            break;
    }

    addeventoanalitics(GetObjAgenda().feria, 'Exportación a PDF', etiqueta, 0);
}

