var idBBDD = 0;

function iniciarDB() {
    try {
        if (window.openDatabase) {
            db = openDatabase("Localdb", "1.0", "BBDD", 200000);
            if (db) {
                db.transaction(function (tx) {
                    tx.executeSql("CREATE TABLE IF NOT EXISTS favoritos (id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT, id_objeto TEXT, tipo_objeto int, titulo TEXT, favorito int, fecha DATE, feria TEXT)", [], function (tx, result) {
                    });
                });
            } else {
            }
        } else {
            
        }
    } catch (e) {
    }
};

function iniciarAgendaDB() {
    try {
        if (window.openDatabase) {
            db = openDatabase("Localdb", "1.0", "BBDD", 200000);
            if (db) {
                db.transaction(function (tx) {
                    tx.executeSql("CREATE TABLE IF NOT EXISTS favoritosAgenda (id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT, id_objeto TEXT, tipo_objeto int, titulo TEXT, favorito int, fecha DATE, feria TEXT)", [], function (tx, result) {
                    });
                });
            } else {
            }
        } else {

        }
    } catch (e) {
    }
};


function primero() {
idBBDD = 0;
    db.transaction(function (tx) {
        tx.executeSql('SELECT id,id_objeto,tipo_objeto, favorito, titulo, fecha FROM favoritos WHERE favorito=1 and feria=? ORDER BY fecha desc', [GetObjGlobales().feria], function (tx, results) {
            if (results.rows && results.rows.length) {
                for (i = 0; i < results.rows.length; i++) {
                    idBBDD++
                }

            } else {
            }
        }, function (tx) {
        });
    });
}

function nFavorito(id_objeto, tipo_objeto, favorito, titulo) {
    db.transaction(function (tx) {
        tx.executeSql('DELETE FROM favoritos WHERE id_objeto=? and tipo_objeto=? and feria=? ', [id_objeto, parseInt(tipo_objeto), GetObjGlobales().feria], function (tx) {
            var fecha_string = new Date();
            var utc = Date.UTC(fecha_string.getFullYear(), fecha_string.getMonth(), fecha_string.getDate(), fecha_string.getHours() - 2, fecha_string.getMinutes(), 0, 0);
            db.transaction(function (tx) {
                var i;
                tx.executeSql('INSERT INTO favoritos (id_objeto,tipo_objeto,titulo,favorito,fecha,feria) values (?, ?, ?, ?, ?,?)', [id_objeto, parseInt(tipo_objeto), titulo, favorito, utc, GetObjGlobales().feria]);
                primero();
            });
        });
    });

}

function nFavoritoAgenda(id_objeto, tipo_objeto, favorito, titulo) {
    db.transaction(function (tx) {
        tx.executeSql('DELETE FROM favoritosAgenda WHERE id_objeto=? and tipo_objeto=? and feria=? ', [id_objeto, parseInt(tipo_objeto), GetObjGlobales().feria], function (tx) {
            var fecha_string = new Date();
            var utc = Date.UTC(fecha_string.getFullYear(), fecha_string.getMonth(), fecha_string.getDate(), fecha_string.getHours() - 2, fecha_string.getMinutes(), 0, 0);
            db.transaction(function (tx) {
                var i;
                tx.executeSql('INSERT INTO favoritosAgenda (id_objeto,tipo_objeto,titulo,favorito,fecha,feria) values (?, ?, ?, ?, ?,?)', [id_objeto, parseInt(tipo_objeto), titulo, favorito, utc, GetObjGlobales().feria]);
                primero();
            });
        });
    });

}

$(document).ready(function () {
    iniciarDB();
    $("#btAnadir").click(function () {
        nFavorito($("#tbId").val(), $("#tbTipo").val());
    });
});