function InicializarActividadesDeLaAgenda() {
    addeventoanalitics(GetObjGlobales().feria, 'Agenda', '', 0);
    if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Agenda'); } else { GetObjGlobales().titulaCabecera('Schedule'); }
    cargaFicheroHTMLConAccion(GetObjGlobales().feria, 'actividad.html', 'actividad_en.html', $('#conteen'), InicializaAgenda);
}

 function InicializaAgenda()
    {
        addEventoNavega('[rel="enlace"]', 'href');
        elScroll('wrapperActividades');
        $('.AgendaCategoria').die();
        $('.AgendaCategoria').live(GetTipoEventoParaClick(), ActivaDesactiva);
    }

    function ActivaDesactiva(e) {
        ActivaDesactivaListaActividadByElement($(this).next('ul').get(0));
    }

    function ActivaDesactivaListaActividadById(nombre) {
        if (document.getElementById(nombre).style.display == "none")
            document.getElementById(nombre).style.display = 'block';
        else
            document.getElementById(nombre).style.display = 'none';
        myScroll.refresh();
    }
    function ActivaDesactivaListaActividadByElement(elemento) {
        // alert(nombre);
        if (elemento.style.display == "none")
            elemento.style.display = 'block';
        else
            elemento.style.display = 'none';
        myScroll.refresh();
    }


