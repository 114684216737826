function CargaFichaEmpresaR() {
    GetObjGlobales().cargaIdioma();
    GetObjGlobales().InicializarHeader();
    var objfichaR = new ObjFichaEmpresaR();
    objfichaR.id = getParameterByName('i');
    objfichaR.feria = GetObjGlobales().feria;
    objfichaR.rutaficha = GetObjGlobales().rutaficha;
    objfichaR.rutarecurso = GetObjGlobales().rutarecurso;
    objfichaR.CargarFichaEmpresaR();
    setTimeout("elScroll('wrapperGaleria');", 1000);
}


function ObjFichaEmpresaR() { };
ObjFichaEmpresaR.prototype = {
    id: 0,
    feria: '',
    rutaficha: '',
    rutarecurso: '',
    CargarFichaEmpresaR: function () {
        $('#btnCierraAmpliar').click(function () {
            //$('#contenedorAmpliar').hide();
            $('#contenedor_paginas').addClass('contenedorDown');
            $('#contenedor_paginas').removeClass('contenedorUp');
        });
        this.CargaSeccionesR();
    },
    CargaSeccionesR: function () {
        var localobj = this;
        if (GetObjGlobales().EsOffline()) {
            localobj.CargaDetallesR(GetObjFichaEmpresas().GetFichaEmpresaById(this.id));
        } else {
            $.ajax({
                type: "GET",
                url: this.rutaficha + 'Feria=' + this.feria + '&Codigo=' + this.id,
                data: "{}",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                cache: false,
                success: function (response) {
                    var Empresa = eval(response.d);
                    localobj.CargaDetallesR(Empresa);
                },
                error: errorAjax_EmpresaR
            });                                              //ajax       
        }
    },
    CargaDetallesR: function (pEmpresa) {
        this.CargarCabeceraR(pEmpresa);
        this.CargarPaisesRepresentadosR(pEmpresa);
        this.CargaEmpresasRepresentadasR(pEmpresa);
        addEventoNavega('[rel="enlace"]', 'href');
        //myScroll.refresh();
    },
    CargarCabeceraR: function (pEmpresa) {
        GetObjGlobales().titulaCabecera(pEmpresa.no);
        $('#nombrer').text(pEmpresa.no);
        addeventoanalitics(GetObjGlobales().feria, 'Ficha empresa R', pEmpresa.no, 0);
        //Ver como hacer el tema de los idiomas
        var tipoexp = pEmpresa.te;
        $('#tipoexpr').text(GetObjGlobales().DameTraduccion(GetObjGlobales().DameIdTipoExp(tipoexp)));
    },
    CargarPaisesRepresentadosR: function (pEmpresa) {
        var tienePaisRep = false;
        if (pEmpresa.lprs != null) {
            tienePaisRep = true;
            var divsect = $('#PaisesRepresentadosr');
            for (var i = 0; i < pEmpresa.lprs.length; i++) {
                if (GetObjGlobales().EsSpanish())
                    divsect.append('<span class="titDatos">' + pEmpresa.lprs[i].v + '</span>');
                else
                    divsect.append('<span class="titDatos">' + pEmpresa.lprs[i].vi + '</span>');
            };
        }
        if (tienePaisRep) { $('#contenedorPaisesRepresentadosr').show(); }
    },
    CargaEmpresasRepresentadasR: function (pEmpresa) {
        var tieneRepres = false;
        if (pEmpresa.ltrs != null) {
            var divrepres = $('#representadasr');
            tieneRepres = true;
            for (i = 0; i < pEmpresa.ltrs.length; i++) {
                divrepres.append('<li class="concepto" rel="enlace" href="ajax_ficha.html?i=' +
				    pEmpresa.ltrs[i].id + '">' + cortaPalabrasListaEmpresas(pEmpresa.ltrs[i].v, 0) + '</li>');
            }
        }
        if (tieneRepres) { $('#contendorEmpresasRepresentadasr').show(); }
    }
}

//DDC no veo muy claro esto hay que revisarlo
function errorAjax_EmpresaR(result) {
	mostrarError();
}
