var mlastClickTime = 0;


function NavegarAgenda(target)
{
    window.location.hash = target;
}

function CargarAgenda() {
    var idagenda = getParameterByName('idagenda');
    
    if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Agenda'); } else { GetObjGlobales().titulaCabecera('Schedule'); }
    GetObjAgenda().InicializaAgenda(GetObjGlobales().idioma, GetObjGlobales().feria, idagenda, false);
    AsignarEventosAgenda();
    GetObjAgenda().eventoRefresh = RefrescarScrollAgenda;
    GetObjAgenda().EstiloCategoriaBotones = false;
    GetObjAgenda().FotoIndiceGrande = false;
    GetObjAgenda().eventoAfterPintarIndice = AfterPintarIndiceMovil;
    GetObjAgenda().eventoAfterPintarFichaEvento = AfterPintarFichaEvento;
    GetObjAgenda().eventoAfterPintarFichaPonente = AfterPintarFichaPonente;
    GetObjAgenda().NavegarAgenda = NavegarAgenda;
    InicializaAgendaMovil();
    InicializaDocumentos(idagenda);
    //iniciarAgenda();
    CargarFecha();    

    //ExistenDocumentos();
    //si es que no hay ponentes/categorias no se debe mostrar la opción correspondiente
    ExistenCategorias();
    ExistePonentes();
    GetObjAgenda().loadArrayFavoritos();
    //La primera vez
    RefrescarScrollAgenda();
}


function AfterPintarIndiceMovil(contenedor, pref) {
    $(contenedor).find("[miacor^='" + pref + "']").each(function () {
        $('[miacor="DET_' + $(this).attr('miacor') + '"]').hide();
        $(this).attr('estado', 'C');
        //        $('[miacor^="SUB_' + $(this).attr('miacor') + '"]').hide();
        //        $('[miacor^="SUB_' + $(this).attr('miacor') + '"]').attr('estado', 'C');

    });
    $(contenedor).find("[miacor^='" + "SUB_" + pref + "']").each(function () {
        $('[miacor="DET_' + $(this).attr('miacor') + '"]').hide();
        $(this).attr('estado', 'C');
    });

    $(contenedor).find("[miacor^='" + pref + "']").bind('click', function (e) {
        
        if (isDoubleClickBug(e)) {
        	return true;
        }
        
        $(this).attr('class', 'cabeceracomunselected');
        setTimeout(function () { $("[miacor^='" + pref + "']").attr('class', 'cabeceracomun'); }, 200);

        if ($(this).attr('estado') == 'A') {
            $('[miacor="DET_' + $(this).attr('miacor') + '"]').hide();
            $(this).attr('estado', 'C');
        }
        else {
            $('[miacor="DET_' + $(this).attr('miacor') + '"]').show();
            $(this).attr('estado', 'A');
        }
        
        /*var current = new Date().getTime();
        var delta = current - mlastClickTime;
		
        if (delta < 350) {
            //$('.cabecerafecha span').text(0);
            // This happens because of a bug, so we ignore it.
            // http://code.google.com/p/android/issues/detail?id=38808
        } else {
            $(this).attr('class', 'cabeceracomunselected');
            setTimeout(function () { $("[miacor^='" + pref + "']").attr('class', 'cabeceracomun'); }, 200);

            if ($(this).attr('estado') == 'A') {
                $('[miacor="DET_' + $(this).attr('miacor') + '"]').hide();
                $(this).attr('estado', 'C');
                //                $('[miacor^="SUB_' + $(this).attr('miacor') + '"]').hide();
                //                $('[miacor^="SUB_' + $(this).attr('miacor') + '"]').attr('estado', 'C');
                //                $('[miacor^="SUB_DET_' + $(this).attr('miacor') + '"]').hide();
            }
            else {
                $('[miacor="DET_' + $(this).attr('miacor') + '"]').show();
                $(this).attr('estado', 'A');
                //                $('[miacor^="SUB_' + $(this).attr('miacor') + '"]').show();
                //                $('[miacor^="SUB_' + $(this).attr('miacor') + '"]').attr('estado', 'C'); //sigue comprimido
            }
        }
        mlastClickTime = current;
        e.stopPropagation();
        e.preventDefault();*/
        
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        return false;
        
        
    });

    $(contenedor).find("[miacor^='" + "SUB_" + pref + "']").bind('click', function (e) {
        
        if (isDoubleClickBug(e)) {
        	return true;
        }
        
        if ($(this).attr('estado') == 'A') {
            $('[miacor="DET_' + $(this).attr('miacor') + '"]').hide();
            $(this).attr('estado', 'C');
        }
        else {
            $('[miacor="DET_' + $(this).attr('miacor') + '"]').show();
            $(this).attr('estado', 'A');
        }
        
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
     
        /*var current = new Date().getTime();
        var delta = current - mlastClickTime;
        if (delta < 350) {
            //$('.cabecerafecha span').text(0);
            // This happens because of a bug, so we ignore it.
            // http://code.google.com/p/android/issues/detail?id=38808
        } else {
            if ($(this).attr('estado') == 'A') {
                $('[miacor="DET_' + $(this).attr('miacor') + '"]').hide();
                $(this).attr('estado', 'C');
            }
            else {
                $('[miacor="DET_' + $(this).attr('miacor') + '"]').show();
                $(this).attr('estado', 'A');
            }
        }
        mlastClickTime = current;
        e.stopPropagation();
        e.preventDefault();
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        return false;*/
    });
    
    if (GetObjAgenda().eventoRefresh)
        GetObjAgenda().eventoRefresh();
}



function AfterPintarFichaEvento() {
    RefrescarScrollAgenda();
    setTimeout("myScroll.scrollTo(0,0,0);", 0);


    $('#MyMail').click(function (e) {
        
        if (isDoubleClickBug(e)) {
        	return true;
        }
        
        if ($(this).attr('href').length > 1)
              window.location.href = $(this).attr('href');
                
        /*var current = new Date().getTime();
        var delta = current - mlastClickTime;

        if (delta < 350) {
            //$('.cabecerafecha span').text(0);
            // This happens because of a bug, so we ignore it.
            // http://code.google.com/p/android/issues/detail?id=38808
        } else {
            if ($(this).attr('href').length > 1)
                window.location.href = $(this).attr('href');
        }
        mlastClickTime = current;
        e.stopPropagation();
        e.preventDefault();
        return false;*/
    });

}

function AfterPintarFichaPonente() {
    RefrescarScrollAgenda();
    setTimeout("myScroll.scrollTo(0,0,0);", 0);
}
function InicializaAgendaMovil() {
    elScroll('wrapperAgenda');
}

function RefrescarScrollAgenda() {
    setTimeout("myScroll.refresh();", 0);
}


function InicializaDocumentos(indice) {

    addeventoanalitics(GetObjGlobales().feria, "Tipo Acceso", "WEB", 0);

    var ruta = GetObjGlobales().getRutaFichero("X", "Indice_Agenda" + GetObjAgenda().extIndiceAgenda);
    var misdatos = [];
    $.ajax({
        type: "GET",
        url: ruta,
        dataType: "xml",
        success: function (xml) {
            $(xml).find('TIndiceAgendas').each(function () {
                if ((GetObjAgenda().agenda != '') && ($(this).attr("id_agenda") == indice)) {
                    misdatos[0] = $(this).attr("TieneDatos");
                    misdatos[1] = $(this).attr("docAnexo");
                    misdatos[2] = $(this).attr("linkAnexo");
                }
            });

            pintaDivDocumentos(misdatos[0], misdatos[1], misdatos[2]);
        }
    });
}

function pintaDivDocumentos(tieneDatos, doc, link)
{
    var divdocu = $("#divDocumento");
    divdocu.html("");
    if (typeof(tieneDatos) !== 'undefined')
    {
        if (doc != "") 
        {
    	    divdocu.html("<img src='img/download.jpg'/>");    	
            var ruta = GetObjGlobales().getRutaFichero("R", doc);        
            divdocu.unbind('click');
            divdocu.click(function () {        	      
	            if (GetObjGlobales().so == SO_ANDROID) {
	                window.fileOpener([ruta]);
	            } else if (GetObjGlobales().so == SO_IOS) {
	                window.open(ruta, '_blank');
	            } else {
	                window.open(ruta);
	            }
	
	        });
        }
        else
        {
    	    if (link != "")
    	    {	    	
	    	    divdocu.html("<img src='img/internet.png'/>");   	
	    	    divdocu.unbind('click');
	    	    divdocu.click(function () {
		            if (GetObjGlobales().so == SO_ANDROID) {
		                navigator.app.loadUrl(link, { openExternal: true })
		            } else if (GetObjGlobales().so == SO_IOS) {
		                window.open(link, '_system');
		            } else {
		                window.open(link);
		            }
		
		        });
    	    }
        }    
    }
}