/// <reference path="kendo/shared/Navegacion.js" />
/// <reference path="utilidades.js" />
/// <reference path="interfazCatalogoNativa.js" />
/// <reference path="../scripts/rsvp.js" />
/// <reference path="Mapas.js" />

var myScroll;
var SO_ANDROID = 'ANDROID';
var SO_IOS = 'IOS';
var SO_WEB = 'WEB';
var TIEMPO_PUBLICIDAD = 10;

//var myScrollPlano, myScrollAmplia= new Array();//, arrayEmpresas
//var actualizacionesPendientes = 0;
var oglobales; //NO USAR DESDE FUERA DE ESTA JS. En su lugar utilizar GetObjGlobales()

function GetObjGlobales() {
    if (oglobales == undefined) {
        oglobales = new ObjGlobales();
    }
    return oglobales;
}

function ObjGlobales() {

    this.arrayMenuIDOld = new Array();
    this.arrayMenuIDOld['magenda'] = 'menuagenda';
    this.arrayMenuIDOld['majustes'] = 'menuajustes';
    this.arrayMenuIDOld['mayuda'] = 'menuayuda';
    this.arrayMenuIDOld['mcomollega'] = 'menucomollegar';
    this.arrayMenuIDOld['mlegal'] = 'menucreditos';
    this.arrayMenuIDOld['mexpos'] = 'menuexpositores';
    this.arrayMenuIDOld['mfavoritos'] = 'menufavoritos';
    this.arrayMenuIDOld['mifemainst'] = 'menuifemainst';
    this.arrayMenuIDOld['mifemaplus'] = 'menuifemaplus';
    this.arrayMenuIDOld['minfoifema'] = 'menuinfoifema';
    this.arrayMenuIDOld['minforma'] = 'menuinformacion'; 
    this.arrayMenuIDOld['mmapa'] = 'menumapa';
    this.arrayMenuIDOld['macercade'] = 'menumas';
    this.arrayMenuIDOld['mmapastand'] = 'menumapastand';
    this.arrayMenuIDOld['mpatrocina'] = 'menupatrocinadores';
    this.arrayMenuIDOld['mrestauran'] = 'menurestaurantes';
    this.arrayMenuIDOld['mhoteles'] = 'menuhoteles';
    this.arrayMenuIDOld['msocial'] = 'menusocial';
    this.arrayMenuIDOld['actiOS'] = 'actiOS';


    //getQueryVariable es un metodo de utilidades.js.
    this.offline = getQueryVariable('offline').toUpperCase();
    this.so = getQueryVariable('so').toUpperCase();
    if (this.so == "") this.so = SO_WEB;
    this.feria = getQueryVariable('feria').toUpperCase();
    this.almacenFavoritos = "CATALOGO_" + this.feria;
    this.feriaPrincipal = this.feria;
    this.FeriasMultiples = new Array();
};
ObjGlobales.prototype = {
    that: null,

    /************
     * PROMESAS *
     ************/
    //promesaIndiceTraducciones: null,
    //promesaIndiceConfiguracion: null,

    arrayMenuIDOld: [],
    //rutaroot: 'Ferias/FA15',//offline
    rutaroot: '', //online
    rutaficha: '',
    rutarecurso: '',
    idioma: 'es',
    mostrarAyuda: 'S',
    feria: '',
    splashTiempo: null,
    mostrarPatrocinadores: 'N',
    cargaAppFinalizada: false,

    //* variables version nativa *//
    offline: '',
    modoConexion: '',
    so: '', //sistema operativo
    wsListaExpositoresPath: "./wsListaExpositores.svc/",
    ActualizacionPendiente: false,
    numFicherosActualizar: 0,
    numFicherosDescargados: 0,
    tamFicherosDescargar: 0,
    numFicherosTotalAssets: 0,
    numFicherosProcesadosAssets: 0,
    mostrarMsgActualizacion: true,
    arrayFavoritos: '',
    almacenFavoritos: '',
    numFavoritos: 0,
    mostrarFavoritos: false,
    //* fin variables version nativa *//

    //variables ferias múltiples//
    rutarootsinferia: '',
    feriaPrincipal: '',
    FeriasMultiples: [],
    existenFeriasMultiples: false,
    paginaInicial: '',
    paginaInicialSinAlmohadilla: '',
    descFeriaActual: '',

    arrayParametros: [],
    arrayParametrosFM: [],
    arrayTraducciones: [],
    InicializarHeader: function () {
        $('#header').show();
        var btnheader = $('.btnHeader');
        btnheader.show();
    },
    EsRecintoPersonalizado: function () {
        var flag;
        if (GetObjGlobales().existenFeriasMultiples) {
            flag = this.arrayParametrosFM['recintoper'];
        }
        else {
            flag = this.arrayParametros['recintoper'];
        }

        if (flag == 'S')
            return true;
        else
            return false;

    },
    MostrarMensajeActualizacion_iOS: function () {
        var flag;
        if (GetObjGlobales().existenFeriasMultiples) {
            flag = this.arrayParametrosFM['actiOS'];
        }
        else {
            flag = this.arrayParametros['actiOS'];
        }

        if (flag == 'S')
            return true;
        else
            return false;
    },
    MostrarAyuda: function() {
    	if (this.mostrarAyuda=='S')
    		return true;
    	else 
    		return false;
    },
    SetMostrarAyuda: function () {
    	if (this.mostrarAyuda=='S')
    		this.mostrarAyuda='N';
    	else this.mostrarAyuda='S';
    },
    EsSpanish: function () {
        if (this.idioma == 'es')
            return true;
        else
            return false;
    },
    EsOffline: function () {
        if (this.offline == 'S')
            return true
        else
            return false;
    },
    setTituloFeria: function () {
        this.titulaCabecera(this.descFeriaActual);
    },
    SetFeriaActiva: function (feriaNueva) {
        //debugger;
        this.feria = feriaNueva.id;
        this.descFeriaActual = feriaNueva.innerText;
        this.almacenFavoritos = "CATALOGO_" + this.feria;
        if (this.EsOffline()) {
            this.rutaroot = this.rutarootsinferia + this.feria;
        }

        addeventoanalitics(this.feriaPrincipal, "Tipo Acceso Feria", this.feria, 0);
        CargasGenerales();
        return true;
    },
    //********************************************** variables version nativa *******************************//
    getRutaFichero: function (tipo, nombreFichero, feria) {
        var lferia = "";
        var ruta = "";

        if (feria == null) {
            lferia = this.feria;
        } else {
            lferia = feria;
        }

        var rutatmp = this.rutarootsinferia + lferia;

        if (this.EsOffline()) {
            if (tipo == "R")
                ruta = rutatmp + "/recurso/" + nombreFichero;
            if (tipo == "I")
                ruta = rutatmp + "/img/" + nombreFichero;
            if (tipo == "X")
                ruta = rutatmp + "/xml/" + nombreFichero;
        }
        else {
            ruta = this.rutarecurso + 'Feria=' + lferia + '&Tipo=' + tipo + '&Fichero=' + nombreFichero;
        }
        return ruta;
    },
    //********************************************* fin variables version nativa *****************************//

    incializaglobales: function () {
        this.rutaficha = this.rutaroot + 'wsListaExpositores.svc/GetFichaEmpresa?';
        this.rutarecurso = this.rutaroot + 'getrecurso.ashx?';
    },











    cargaMenusAgenda: function () {

        function objagenda(idagenda, dsagenda, dsagenda_en, dsTieneDatos, dsDocu, dsLink) {
            this.idagenda = idagenda;
            this.dsagenda = takeNchars(dsagenda);  //takeNchars(dsagenda, 25);
            this.dsagenda_en = takeNchars(dsagenda_en); //takeNchars(dsagenda_en, 25);
            this.dsTieneDatos = dsTieneDatos;
            this.dsDocu = dsDocu;
            this.dsLink = dsLink;
        }
        var aagenda = new Array();
        rutaes = this.getRutaFichero("X", "Indice_Agenda_ES.xml");
        rutaen = this.getRutaFichero("X", "Indice_Agenda_EN.xml");
        $.ajax({
            type: "GET",
            url: rutaes,
            dataType: "xml",
            success: function (xml) {
                $(xml).find('TIndiceAgendas').each(function () {
                    aagenda.push(new objagenda($(this).attr('id_agenda'), $(this).attr('ds_nombre'), '', $(this).attr('TieneDatos'), $(this).attr('docAnexo'), $(this).attr('linkAnexo')));
                    //agendas_es[$(this).attr('id_agenda')] = $(this).attr('ds_nombre');
                });
                $.ajax({
                    type: "GET",
                    url: rutaen,
                    dataType: "xml",
                    success: function (xml1) {
                        $(xml1).find('TIndiceAgendas').each(function () {
                            for (var i = 0; i < aagenda.length; i++) {
                                if (aagenda[i].idagenda == $(this).attr('id_agenda')) {
                                    aagenda[i].dsagenda_en = takeNchars($(this).attr('ds_nombre')); //takeNchars($(this).attr('ds_nombre'), 25);
                                }
                            };
                        });
                        var nombreAgenda;
                        for (var i = 0; i < aagenda.length; i++) {

                            if (!$('#menuagenda_' + aagenda[i].idagenda).length) {
                                if (GetObjGlobales().EsSpanish()) {
                                    nombreAgenda = aagenda[i].dsagenda;
                                } else {
                                    nombreAgenda = aagenda[i].dsagenda_en;
                                }
                                var referencia;
                                if ((aagenda[i].dsTieneDatos == "S") || (typeof (aagenda[i].dsTieneDatos) === 'undefined'))
                                    referencia = 'ajax_agenda.html?idagenda=' + aagenda[i].idagenda;
                                else {
                                    if (aagenda[i].dsLink != "")
                                        referencia = aagenda[i].dsLink;
                                    else if (aagenda[i].dsDocu != "")
                                        referencia = GetObjGlobales().getRutaFichero("R", aagenda[i].dsDocu);
                                }

                                cadena = '<li id="' + 'menuagenda_' + aagenda[i].idagenda + '" men="menu" href="' + referencia + '" rel="enlace" ' +
                                        //' onclick=" if (GetObjGlobales().so != \'WEB\') { addeventoanalitics(GetObjGlobales().feria, \'Agenda\', \'' + aagenda[i].dsagenda + '\', 0); } ">' +
                                        ' onclick=" addeventoanalitics(GetObjGlobales().feria, \'Agenda\', \'' + aagenda[i].dsagenda + '\', 0);">' +
                                        '<img src="img/actividades_celeste.png" alt="Icono" border="0" />' +
                                        '<span rel="idioma" idioma="es">' + aagenda[i].dsagenda + '</span>' +
                                        '<span rel="idioma" idioma="en">' + aagenda[i].dsagenda_en + '</span>' +
                                        '</li>';

                                //$('#mifemainst').before(cadena);
                                //$('#contenido_feria ul').append(cadena);
                                $("#contenido_feria ul").find('#mifemainst').before(cadena);

                            }
                        }
                        addEventoNavega('[rel="enlace"]', 'href');
                        GetObjGlobales().cargaIdioma();

                        $('#contenido_feria ul').find('li.vacio').appendTo('#contenido_feria ul');



                        myScroll.refresh();
                    }
                });
            }
        });



    },
    establecerPaginaInicial: function () {
        if (this.existenFeriasMultiples) {
            this.paginaInicial = '#ajax_feria_multiple.html';
            this.paginaInicialSinAlmohadilla = 'ajax_feria_multiple.html';
        } else {
            this.paginaInicial = '#ajax_feria.html';
            this.paginaInicialSinAlmohadilla = 'ajax_feria.html';
        }
    },
    getIndiceConfiguracion: function () {
        //GetObjGlobales().promesaIndiceConfiguracion = GetObjGlobales().promesaIndiceConfiguracion ||
        //    new RSVP.Promise(...
        return new RSVP.Promise(function (resolve, reject) {
            $.ajax({
                type: "GET",
                url: GetObjGlobales().getRutaFichero("R", "configuracion.xml"),
                dataType: "xml",
                success: function (xml) {
                    resolve(xml);
                },
                error: function (reason) {
                    reject(reason);
                }
            });
        });;
    },
    CargarConfiguracion: function () {
        return new RSVP.Promise(function (resolve, reject) {
            GetObjGlobales().arrayParametros = new Array();
            GetObjGlobales().getIndiceConfiguracion().then(function (xml) {
                $(xml).find('p').each(function () {
                    GetObjGlobales().arrayParametros[$(this).attr('id')] = $(this).attr('v');
                    //console.log(">>> " + GetObjGlobales().arrayParametros[$(this).attr('id')] + " - " + $(this).attr('id'));
                });

                if (!GetObjGlobales().cargaAppFinalizada) {
                    $(xml).find('ferias feria').each(function () {
                        GetObjGlobales().existenFeriasMultiples = true;
                        var Feria = new Object();
                        Feria.cod = $(this).attr('v');
                        Feria.desc = $(this).attr('d');
                        GetObjGlobales().FeriasMultiples.push(Feria);
                        //GetObjGlobales().arrayParametros[$(this).attr('id')] = $(this).attr('v');
                        //console.log(">>> " + $(this).attr('id') +'  ' + Feria.cod +' ' + Feria.desc);
                    });

                    if (GetObjGlobales().existenFeriasMultiples) {
                        GetObjGlobales().arrayParametrosFM = new Array();
                        $(xml).find('p').each(function () {
                            GetObjGlobales().arrayParametrosFM[$(this).attr('id')] = $(this).attr('v');
                        });
                    }

                    GetObjGlobales().establecerPaginaInicial();

                    //maem - globales usado desde agenda no usa listaempresas.js
                    try {
                        GetObjListaEmpresas().tipolistado = GetObjGlobales().arrayParametros['Lista_Logo'];
                        var tiempoPublicidad = GetObjGlobales().arrayParametros['tiempoPub'];
                        GetObjGlobales().splashTiempo = (tiempoPublicidad) ? parseInt(tiempoPublicidad) : TIEMPO_PUBLICIDAD;

                        if (GetObjGlobales().existenFeriasMultiples) {
                            var flagPatrocinadores = GetObjGlobales().arrayParametrosFM['mpatrocina'];
                            GetObjGlobales().mostrarPatrocinadores = (flagPatrocinadores) ? flagPatrocinadores : 'N';
                        }
                        else {
                            var flagPatrocinadores = GetObjGlobales().arrayParametros['mpatrocina'];
                            GetObjGlobales().mostrarPatrocinadores = (flagPatrocinadores) ? flagPatrocinadores : 'N';
                        }
                    }
                    catch (err) {
                        //console.log(err.message);
                    }
                }
                resolve('');
            });
        });
    },
    getFeria: function () {
        return this.feria;
    },
    getOldIdMenu: function (id) {
        if (this.arrayMenuIDOld[id])
            return this.arrayMenuIDOld[id];
        else
            return id;
    },
    cargaMenusPrincipalesFMultiples: function () {
        //debugger;
        $('[men="menu"]').each(function () {
            var menu = $(this);

            //console.log('menu.attr(id) '+menu + ' - '+GetObjGlobales().so );
            if (GetObjGlobales().arrayParametrosFM[menu.attr('id')] == 'S')
                menu.show();


            if (GetObjGlobales().so == SO_WEB && menu.attr('id') == 'mqrfree') {
                menu.hide();
            }
        })
    },
    cargaMenusPrincipales: function () {
        $('[men="menu"]').each(function () {
            var menu = $(this);
            if (GetObjGlobales().arrayParametros[menu.attr('id')] == 'S')
                menu.show();
            else {
                if (GetObjGlobales().arrayParametros[GetObjGlobales().getOldIdMenu(menu.attr('id'))] == 'S') {
                    menu.show();
                }
            }

            if (GetObjGlobales().existenFeriasMultiples && (menu.attr('id') == 'mifemainst' || menu.attr('id') == 'minforma' || menu.attr('id') == 'mqrfree')) {
                menu.hide();
            }

            if (GetObjGlobales().so == SO_WEB && menu.attr('id') == 'mqrfree') {
                menu.hide();
            }
        })
    },
    cargarMenusFeriasMultiples: function () {
        //debugger;
        var Feria = new Object();
        var cadena = '';
        for (var i = 0; i < GetObjGlobales().FeriasMultiples.length; i++) {
            Feria = GetObjGlobales().FeriasMultiples[i];
            //relFerias="enlace"
            cadena = '<li id="' + Feria.cod + '" men="menu" href="ajax_feria.html" rel="enlace" onclick=" GetObjGlobales().SetFeriaActiva(' + Feria.cod + ')" >' +
                        '<img src="img/otros_expositores_celeste.png" alt="Icono" border="0" />' +
                        //'<span rel="idioma" idioma="es">' + Feria.desc + '</span>' +
                        '<span>' + takeNchars(Feria.desc) + '</span>' +
                     '</li> ';
            //$('#minforma').before(cadena);
            $("#contenido_feria_multiple").find('#minforma').before(cadena);
        }
        //addEventoNavega('[relFerias="enlace"]', 'href');
        addEventoNavega('[rel="enlace"]', 'href');
        myScroll.refresh();
    },

    cargaMenusDinamicos: function () {
        //debugger;
        $.ajax({
            type: "GET",
            url: this.getRutaFichero("R", "enlaces.xml"),
            dataType: "xml",
            success: function (xml) {
                var n = 1;
                $(xml).find('Enlace').each(function () {
                    var id = 'menlace' + n;


                    var logo = GetObjGlobales().getRutaFichero("R", $(this).attr('Logo'));
                    var opcionEnlace = $(this).attr('TextoEnlace');
                    if ($(this).attr('enlace') == "ext") {
                        //DDC IMPORTANTE
                        //el rel=enlaceExt no me lo quites que si no el estilo de opcion presionada no sale(el estilo se quita y se pone en el metodo elscroll())
                        var cadena = '<li relIdioDin="idioma" idiomaDin="es"  rel="enlaceExt" onclick="addeventoanalitics(GetObjGlobales().feria,\'Enlaces Externos\',\'' + opcionEnlace +
	                                    '\', 0);" id="' + id + '" men="menu"  href="' + $(this).attr('UrlEnlace') + '">' +
		                                    '<img src="' + logo + '" alt="Icono" border="0" />' +
		                                    '<span style="display: inline;">' + $(this).attr('TextoEnlace') + '</span>' +
	                                 '</li>' +
                            ' <li relIdioDin="idioma" idiomaDin="en"  rel="enlaceExt" onclick="addeventoanalitics(GetObjGlobales().feria,\'Enlaces Externos\',\'' + opcionEnlace +
	                                '\', 0);" id="' + id + '" men="menu"  href="' + $(this).attr('UrlEnlaceIngles') + '">' +
		                                    '<img src="' + logo + '" alt="Icono" border="0" />' +
	                                        '<span style="display: inline;">' + $(this).attr('TextoEnlaceIngles') + '</span>' +
	                                 '</li>';

                    }
                    else {
                        //DDC IMPORTANTE
                        //el rel=enlace no me lo quites que si no el estilo de opcion presionada no sale(el estilo se quita y se pone en el metodo elscroll())
                        var cadena = '<li  relIdioDin="idioma" idiomaDin="es" rel="enlace" onclick="addeventoanalitics(GetObjGlobales().feria,\'Enlaces Externos\',\'' + opcionEnlace +
	                                    '\', 0);" id="' + id + '" men="menu"  href="' + $(this).attr('UrlEnlace') + '">' +
		                                    '<img src="' + logo + '" alt="Icono" border="0" />' +
		                                    '<span style="display: inline;">' + $(this).attr('TextoEnlace') + '</span>' +
	                                 '</li>' +
                            ' <li relIdioDin="idioma" idiomaDin="en" rel="enlace" onclick="addeventoanalitics(GetObjGlobales().feria,\'Enlaces Externos\',\'' + opcionEnlace +
	                                '\', 0);" id="' + id + '" men="menu"  href="' + $(this).attr('UrlEnlaceIngles') + '">' +
		                                    '<img src="' + logo + '" alt="Icono" border="0" />' +
	                                        '<span style="display: inline;">' + $(this).attr('TextoEnlaceIngles') + '</span>' +
	                                 '</li>';
                    }
                    //$('#mifemainst').before(cadena);
                    //$('#contenido_feria ul').append(cadena);
                    $("#contenido_feria ul").find('#mifemainst').before(cadena);


                    n++;
                });
                //                addEventoNavega('[relEnlaDin="enlace"]', 'href');
                addEventoNavegaExterno('[rel="enlaceExt"]', 'href');
                addEventoNavega('[rel="enlace"]', 'href');

                $('[relIdioDin="idioma"]').hide();
                $('[idiomaDin="' + GetObjGlobales().idioma + '"]').show();

                $('#contenido_feria ul').find('li.vacio').appendTo('#contenido_feria ul');
                myScroll.refresh();
            }

        })
    },
    DameTraduccion: function (valor, textoPorDefecto) {
        try {
            if (this.EsSpanish()) {
                return this.arrayTraducciones[valor - 1][1];
            } else {
                return this.arrayTraducciones[valor - 1][2];
            }
        } catch (err) {
            //console.log(err.message);
            return (textoPorDefecto) ? textoPorDefecto : '';
        }
    },
    getIndiceTraducciones: function () {
        //GetObjGlobales().promesaIndiceTraducciones = GetObjGlobales().promesaIndiceTraducciones ||
        //    new RSVP.Promise(function (resolve, reject)
        return new RSVP.Promise(function (resolve, reject) {
            $.ajax({
                type: "GET",
                url: GetObjGlobales().getRutaFichero("R", "traducciones_fijas.xml"),
                dataType: "xml",
                success: function (xml) {
                    resolve(xml);
                },
                error: function (reason) {
                    reject(reason);
                }
            });
        });;
    },
    creaArrayTraducciones: function () {
        return new RSVP.Promise(function (resolve, reject) {
            GetObjGlobales().arrayTraducciones = new Array();
            GetObjGlobales().getIndiceTraducciones().then(function (xml) {
                $(xml).find('secciones').each(function () {
                    $(xml).find('sec').each(function () {
                        var traduccion = new Array();
                        traduccion = [$(this).attr('id'), $(this).attr('val'), $(this).attr('vali')];
                        GetObjGlobales().arrayTraducciones.push(traduccion);
                    })
                });
                resolve('');
            });
        });
    },
    cargaIdioma: function () {
        $('[rel="idioma"]').hide();
        $('[idioma="' + this.idioma + '"]').show();
        
        var enlaces = $('[rel="enlace"]');
        for (var i = 0; i < enlaces.length; i++) {
            var destino = enlaces[i].getAttribute('href_' + this.idioma);
            if (destino) {
                enlaces[i].setAttribute('href', destino);
            }
        }
    },
    cambiaIdioma: function (pidioma) {
        //this.idioma = pidioma;
        localStorage.setItem(this.feria + '_lang', pidioma);
        this.cambiarIdiomaCabecera(pidioma);
    },
    cambiarIdiomaCabecera: function (pidioma) {
        this.idioma = pidioma;
        if (this.EsSpanish()) { $('.btnHeader').html('<span>Atr&aacute;s</span>'); }
        else { $('.btnHeader').html('<span>Back</span>'); }
        $('[rel="idioma"]').hide();
        $('[idioma="' + this.idioma + '"]').fadeIn();
    },
    InicializarIdioma: function () {
        //tratamos de recuperar el idioma del localstorage 
        var lang;
        var idioma_guardado = localStorage.getItem(this.feria + '_lang');
        if (idioma_guardado != undefined && idioma_guardado != null) {
            lang = idioma_guardado;
        }
        else {
            if (navigator
                    && navigator.userAgent
                    && (lang = navigator.userAgent
                            .match(/android.*\W(\w\w)-(\w\w)\W/i))) {
                lang = lang[1];
            }

            if (!lang && navigator) {
                if (navigator.language) {
                    lang = navigator.language;
                } else if (navigator.browserLanguage) {
                    lang = navigator.browserLanguage;
                } else if (navigator.systemLanguage) {
                    lang = navigator.systemLanguage;
                } else if (navigator.userLanguage) {
                    lang = navigator.userLanguage;
                }
                lang = lang.substr(0, 2);
            }
        }

        if (lang == "es") {
            this.cambiarIdiomaCabecera("es")
        }
        else {
            this.cambiarIdiomaCabecera("en")
        }
        this.cargaIdioma();
    },
    DameIdTipoExp: function (tipoexp) {
        switch (tipoexp) {
            case 'E':
                return '8';
            case 'C':
                return '9';
            case 'R':
                return '10';
            default:
                return '';
        }
    },

    titulaCabecera: function (pnombre) {
        $('.titHeader').html('<h1>' + cortaPalabras(pnombre, 140) + '</h1>');
    },

    loadArrayFavoritos: function () {
        var storedFavoritos = $.DSt.get(this.almacenFavoritos);
        if (storedFavoritos != null) {
            this.arrayFavoritos = eval(JSON.parse(storedFavoritos));
        } else {
            this.arrayFavoritos = new Array();
        }

        return this.arrayFavoritos;
    },

    addFavorito: function (idFav, idFeria, texto) {
        var objFavorito = { 'feria': idFeria, 'id': idFav, 'texto': texto };
        this.arrayFavoritos.push(objFavorito);
        $.DSt.set(this.almacenFavoritos, JSON.stringify(this.arrayFavoritos));
    },

    deleteFavorito: function (idFav, idFeria) {
        for (var i = this.arrayFavoritos.length - 1; i >= 0; i--) {
            if (this.arrayFavoritos[i].feria == idFeria && this.arrayFavoritos[i].id == idFav)
                this.arrayFavoritos.splice(i, 1);
        }
        $.DSt.set(this.almacenFavoritos, JSON.stringify(this.arrayFavoritos));
    },

    buscarFavorito: function (idFav, idFeria) {
        var flag = false;
        if (this.arrayFavoritos != null) {
            for (var i = this.arrayFavoritos.length - 1; i >= 0; i--) {
                if (this.arrayFavoritos[i].feria == idFeria && this.arrayFavoritos[i].id == idFav) {
                    flag = true;
                    break;
                }
            }
        }
        return flag;
    },
    hayConexion: function () {
        obtenerModoConexion();

        if ((GetObjGlobales().modoConexion.length > 0) && (GetObjGlobales().modoConexion != 'Conexion desconocida') && (GetObjGlobales().modoConexion != 'Sin conexion')) {
            return true;
        } else {
            return false;
        }
    }
}

hoverClassRegEx = new RegExp('(^|\\s)iScrollHover(\\s|$)'),
	removeClass = function () {
	    if (this.hoverTarget) {
	        clearTimeout(this.hoverTimeout);
	        this.hoverTarget.className = this.hoverTarget.className.replace(hoverClassRegEx, '');
	        this.target = null;
	    }
	};
function elScroll(quien) {

    if ($('#' + quien).length > 0) {
        myScroll = new iScroll(quien, {
            useTransform: false,
            onBeforeScrollStart: function (e) {
                var target = e.target;
                clearTimeout(this.hoverTimeout);
                if ((e.target.getAttribute("rel") != "enlace") &&
		            (e.target.parentElement.getAttribute("rel") != "enlace") &&
		            (e.target.getAttribute("rel") != "enlaceExt") &&
		            (e.target.parentElement.getAttribute("rel") != "enlaceExt") &&		            
		            (e.target.getAttribute("rel") != "enlaceB") &&
		            (e.target.parentElement.getAttribute("rel") != "enlaceB") &&
		            (e.target.getAttribute("rel") != "boton") &&
		            (e.target.parentElement.getAttribute("rel") != "boton"))
                { 
                	return false; /*e.preventDefault(); return false;*/ 
                }
                if (target.tagName == 'SELECT' && target.tagName != 'INPUT' && target.tagName != 'TEXTAREA') {
                    return false;
                }

                if (target.getAttribute("rel") != "enlace" && target.parentNode.getAttribute("rel") == "enlace") { target = target.parentNode; }
                if (target.getAttribute("rel") != "enlaceExt" && target.parentNode.getAttribute("rel") == "enlaceExt") { target = target.parentNode; }                
                if (target.getAttribute("rel") != "enlaceB" && target.parentNode.getAttribute("rel") == "enlaceB") { target = target.parentNode; }
                if (target.getAttribute("rel") != "boton" && target.parentNode.getAttribute("rel") == "boton") { target = target.parentNode; }
                this.hoverTimeout = setTimeout(function () { if (!hoverClassRegEx.test(target.className)) target.className = target.className ? target.className + ' iScrollHover' : 'iScrollHover'; }, 20);
                this.hoverTarget = target; e.preventDefault();
            },
            onScrollMove: removeClass,
            onBeforeScrollEnd: removeClass,
            onScrollEnd: removeClass
        });
        $('#capaCargador').fadeOut();
    }
};

function elScrollSinHover(quien) { myScroll = new iScroll(quien, { useTransform: false }); $('#capaCargador').fadeOut(); };

function LoadCabeceraFeriaMultiple() {
    if (myScroll != "undefined" && myScroll != null) {
        $('#contenido_feria ul').append("<li class='vacio'>&nbsp;</li>");
        GetObjGlobales().cargaMenusPrincipalesFMultiples();
        GetObjGlobales().cargarMenusFeriasMultiples();
        myScroll.refresh();

        // Se comprueba si hay actualizaciones
        comprobarActualizacionPendiente();
    }
}

var primera = true; var paginaCargada = false; var transicion; var nombreCapa; var nombreCapaAnterior; var urlG;
var navegacion = Array();


var rutaAnterior = GetObjGlobales().paginaInicialSinAlmohadilla;
//var rutaAnterior = 'ajax_feria.html';
var rutaAnterior2 = '';
function carga(url) {
    $("#BusquedaPonente").hide();
    $("#BusquedaActividad").hide();
    $("#Opciones").hide();
    ConfigVentana();
    OcultaMuestraDivs(false);
    if (url.toUpperCase().search("AGENDA_FECHA") >= 0) {
        $("#Opciones").show();
        navegacion.push(url);
        CargarFecha();
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        rutaAnterior = url;
        return;
    }
    if (url.toUpperCase().search("AGENDA_CATEGORIA") >= 0) {
        $("#Opciones").show();
        navegacion.push(url);
        CargarCategoria();
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        rutaAnterior = url;
        return;
    }

    if (url.toUpperCase().search("AGENDA_UBICACION") >= 0) {
        $("#Opciones").show();
        navegacion.push(url);
        CargarUbicacion();
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        rutaAnterior = url;
        return;
    }

    if (url.toUpperCase().search("AGENDA_PONENTE") >= 0) {
        $("#Opciones").show();
        navegacion.push(url);
        CargarPonente();
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        rutaAnterior = url;
        return;
    }

    if (url.toUpperCase().search("AGENDA_FICHA_EVENTO") >= 0) {
        $("#Opciones").show();
        url = url.toUpperCase().replace('?GO=BACK', '');
        navegacion.push(url);
        var idevento = url.toUpperCase().substring("AGENDA_FICHA_EVENTO".length + 1, url.length);
        LoadEvento(idevento);
        OcultaMuestraDivs(false);
        $("#FichaEvento").show();
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        rutaAnterior = url;
        return;
    }

    if (url.toUpperCase().search("AGENDA_FICHA_PONENTE") >= 0) {
        $("#Opciones").show();
        url = url.toUpperCase().replace('?GO=BACK', '');
        navegacion.push(url);

        var idponente = url.toUpperCase().substring("AGENDA_FICHA_PONENTE".length + 1, url.length);
        LoadPonente(idponente);
        OcultaMuestraDivs(false);
        $("#FichaPonente").show();
        if (GetObjAgenda().eventoRefresh)
            GetObjAgenda().eventoRefresh();
        rutaAnterior = url;
        return;
    }


    if (url.toUpperCase().search("AGENDA_FAVORITOS") >= 0) {
        $("#Opciones").show();
        navegacion.push(url);
        ListadoFavorito();
        if (GetObjAgenda().eventoRefresh) {
            GetObjAgenda().eventoRefresh();
        }
        rutaAnterior = url;
        return;
    }

    $('#capaCargador').fadeIn(300,
        function () {
            setTimeout(function () { carga2(url); }, 100);
        });
}
function carga2(url) {
    //debugger;
    // cambiamos la carga de pagina si es una prueba de  ui
    if (url.indexOf("kendo/ui/acreditaciones/ajax_") != -1) {
        kendoNameSpace.navegacion.cargaURL(url);
        return;
    }
    else {
        kendoNameSpace.navegacion.OcultarParteNueva();
        kendoNameSpace.navegacion.MostrarParteAntigua();
    }
    urlG = url;
    nombreCapa = url.replace('.html', '').replace('kendo/ui/acreditaciones/ajax_', '').replace('ajax_', '');
    if (nombreCapa.indexOf('?') > 0) {
        nombreCapa = nombreCapa.substring(0, nombreCapa.indexOf('?'));
        nombreCapa += '_' + getParameterByName('i');

    }
    nombreCapaAnterior = rutaAnterior.replace('.html', '').replace('kendo/ui/acreditaciones/ajax_', '').replace('ajax_', '');
    if (nombreCapaAnterior.indexOf('?') > 0) {
        var par = sacaParametro('i', nombreCapaAnterior)
        nombreCapaAnterior = nombreCapaAnterior.substring(0, nombreCapaAnterior.indexOf('?'));
        nombreCapaAnterior += '_' + par;
    }
    navegacion.push(url);
    //    if (myScrollPlano) { myScrollPlano.destroy(); myScrollPlano = null; }
    if (myScroll) { myScroll.destroy(); myScroll = null; }
    //    if (myScrollAmplia) { myScrollAmplia.destroy(); myScrollAmplia = null; }

    $.ajax({
        url: url, success: function (data) {
            if (primera) {
                $('<div id="contenido_' + nombreCapa + '"   class="absoluta"></div>').appendTo('#contenedor_paginas');
                rutaAnterior = url; $("#contenido_" + nombreCapa).html(data);
                $("#contenido_" + nombreCapa).fadeIn();
                addEventoNavega('[rel="enlace"]', 'href');
                primera = false; return false;
            }
            else {
                if (nombreCapa === "agenda_") {
                    if (document.getElementById("contenido_agenda_") === null)
                        $('<div id="contenido_' + nombreCapa + '" style="display:none;"  class="absoluta"></div>').appendTo('#contenedor_paginas');
                }
                else
                    $('<div id="contenido_' + nombreCapa + '" style="display:none;"  class="absoluta"></div>').appendTo('#contenedor_paginas');
            }

            if (getParameterByName('go') == 'back') {
                transicion = 'atras';
                $("#contenido_" + nombreCapa).html(data);
            } else {
                $("#contenido_" + nombreCapa).html(data);
                transicion = 'alante';
            }
            cargadoB();
        }

    });
    return false;
}
function cargadoB() {
    //debugger;
    localStorage.setItem(GetObjGlobales().feriaPrincipal + '_lastURL', location.hash);
    switch (transicion) {
        case 'alante':
            //if ((location.hash == '') || (location.hash.indexOf('#ajax_feria.html') >= 0) || (location.hash.indexOf('#ajax_feria_multiple.html') >= 0)) {
            if (((GetObjGlobales().existenFeriasMultiples) && ((location.hash == '') || (location.hash.indexOf('#ajax_feria_multiple.html') >= 0))) || ((!GetObjGlobales().existenFeriasMultiples) && ((location.hash == '') || (location.hash.indexOf('#ajax_feria.html') >= 0)))) {
                $('#header').addClass('saleAtras');
                $('#header').hide();
                $('#header').removeClass('entra');
            } else {
                $('#header').removeClass('saleAtras');
                $('#header').addClass('entra');
                $('#header').show();
            }

            $("#contenido_" + nombreCapa).show();
            $("#contenido_" + nombreCapa).addClass('entra');
            $("#contenido_" + nombreCapaAnterior).addClass('sale');
            if (nombreCapa != nombreCapaAnterior) {
                if (nombreCapaAnterior.indexOf("AGENDA_") > -1) //si es que damos a HOME desde alguna opci�n interna de Agenda
                    setTimeout("$('#contenido_agenda_').remove();", 100);
                else
                    setTimeout("$('#contenido_' + nombreCapaAnterior).remove();", 100);
            }

            $('.btnHeader').show();
            addEventoNavega('[rel="enlace"]', 'href');
            break;
        case 'atras':
            //if (location.hash == '' || location.hash.indexOf('#ajax_feria.html') >= 0) {
            //if ((location.hash == '') || (location.hash.indexOf('#ajax_feria.html') >= 0) || (location.hash.indexOf('#ajax_feria_multiple.html') >= 0)) {
            if (((GetObjGlobales().existenFeriasMultiples) && ((location.hash == '') || (location.hash.indexOf('#ajax_feria_multiple.html') >= 0))) || ((!GetObjGlobales().existenFeriasMultiples) && ((location.hash == '') || (location.hash.indexOf('#ajax_feria.html') >= 0)))) {
                $('#header').addClass('saleAtras');
                $('#header').hide();
                $('#header').removeClass('entra');
            } else {
                $('#header').removeClass('saleAtras');
                $('#header').addClass('entra');
                $('#header').show();
            }
            $("#contenido_" + nombreCapa).show();
            $("#contenido_" + nombreCapa).addClass('entraAtras');
            $("#contenido_" + nombreCapaAnterior).addClass('saleAtras');
            setTimeout("$('#contenido_' + nombreCapaAnterior).remove();", 100);
            $('.btnHeader').show();
            addEventoNavega('[rel="enlace"]', 'href');
            break;

    }
    rutaAnterior2 = rutaAnterior;
    rutaAnterior = urlG;

    GetObjGlobales().cargaIdioma();
}

$.extend($.expr[':'], {
    'containsi': function (elem, i, match, array) {
        return (elem.textContent || elem.innerText || '').toLowerCase()
                                                 .indexOf((match[3] || "").toLowerCase()) >= 0;
    }
});

function addEventoNavega(busquedaelementos, atributo) {
    $(busquedaelementos).unbind("click");
    $(busquedaelementos).click(function (e) {
        if (isDoubleClickBug(e)) {
            return true;
        }
        navega($(this).attr(atributo));
        return false;
    });
}

function addEventoNavegaExterno(busquedaelementos, atributo) {
    $(busquedaelementos).unbind("click");
    $(busquedaelementos).click(function (e) {
        if (isDoubleClickBug(e)) {
            return true;
        }
        navegaExterno($(this).attr(atributo));
        return false;
    });
}


function navegaExterno(url) {
    //DDC como me imaginaba al final hay que dejar este codigo. En ios nos han tirado la app al abrir los enlaces en otro navegador
    //Es decir, que la configuracion de externo para IOS no vale x lo que tiene que funcionar como interno
    if (GetObjGlobales().so == SO_IOS) {
        if (GetObjGlobales().EsSpanish()) {
            window.open(url, '_blank', 'closebuttoncaption=Volver');
        } else {
            window.open(url, '_blank', 'closebuttoncaption=Back');
        }
    }
    else {
        if (GetObjGlobales().so == SO_ANDROID)//Esto se mete por el problema con youtube en googleplay
            window.open(url, '_system');
        else
            window.open(url);
    }
	//Con los problemas que tiene el inappbrowser para descarga de archivos se decide cambiar la estrategia.
	//Ahora si es rel=EnlaceExt siempre abrirá el navegador independientemente de si es ios o android.
	//Sera a nivel de creacion de los xml donde se decida si la navegacion es en el inappbrowser o en un navegador

    /*if ((GetObjGlobales().so == SO_ANDROID) || (GetObjGlobales().so == SO_IOS)) 
        window.open(url, '_system');            	
    else
    	window.open(url);    	*/

    
}


function addEventoNavegaNoClick(cadena) {
    navega(cadena);
    return false;
}

function navega(url) {
    //debugger;
    //console.log("Navega " + url);
    if (url == '') {
        //url = "ajax_feria.html";
        url = GetObjGlobales().paginaInicialSinAlmohadilla;
        //console.log('navega ' + GetObjGlobales().paginaInicialSinAlmohadilla);
    }
    if (url == rutaAnterior) {
        return false;
    }
    if ((url.toLowerCase().search('http') >= 0) ||
        (url.toLowerCase().search('getrecurso') >= 0) ||
        (url.toLowerCase().search('/recurso/') >= 0) ||
        (url.toLowerCase().search('/img/') >= 0) ||
        (url.toLowerCase().search('/xml/') >= 0)) 
    {
        if (url.toLowerCase().search('file') >= 0) 
        {
            if (GetObjGlobales().so == SO_ANDROID) {
                window.fileOpener([url]);
            } else if (GetObjGlobales().so == SO_IOS) {
                window.open(url, '_blank');
            } else {
                window.open(url);
            }
        }
        else {
            if ((GetObjGlobales().so == SO_ANDROID) || (GetObjGlobales().so == SO_IOS)) {
                if (GetObjGlobales().EsSpanish()) {
                    window.open(url, '_blank', 'closebuttoncaption=Volver');
                } else {
                    window.open(url, '_blank', 'closebuttoncaption=Back');
                }
            } else {
                window.open(url);
            }

        }

        if (url.toLowerCase().search('appinstitucional.ifema.es') >= 0) {
            addeventoanalitics(GetObjGlobales().feriaPrincipal, 'IFEMA', '', 0);
        }
    } else {
        window.location.hash = url;
    }
}

function locationHashChanged() {
    //if (location.hash == '' || location.hash.indexOf('#ajax_feria.html') >= 0) {
    if (location.hash == '') {
        carga(GetObjGlobales().paginaInicialSinAlmohadilla);
    }
    else {
        if (location.hash.indexOf('#ajax_feria.html') >= 0) {
            carga('ajax_feria.html');
        } else {
            if (location.hash.indexOf('#ajax_feria_multiple.html') >= 0) {
                carga('ajax_feria_multiple.html');
            }
            else {
                carga(hashea());
            }
        }
    }
}
function hashea() {
    var strHash = location.hash.substring(0, location.hash.length);
    if (strHash.indexOf("#") > -1) {
        strHash = strHash.substring(1, strHash.length);
    }
    return strHash;
}


function errorAjax(result) {
    //alert('ERROR ' + result.status + ' ' + result.statusText);
}

function cargaFicheroHTML(pferia, fichero_es, fichero_en, contenedor) {
    var cadena = '';
    var fichero = '';
    if (GetObjGlobales().EsSpanish()) {
        fichero = fichero_es;
    }
    else {
        fichero = fichero_en;
    }
    $.ajax({
        type: "GET",
        url: GetObjGlobales().getRutaFichero("R", fichero), //GetObjGlobales().rutarecurso + 'Feria=' + pferia + '&Tipo=R&Fichero=' + fichero,
        dataType: "html",
        success: function (html) {
            contenedor.html(html);
            addEventoNavega('[rel="enlace"]', 'href');
            setTimeout("myScroll.refresh();", 300);
        }
    });
}

function cargaFicheroHTMLConAccion(pferia, fichero_es, fichero_en, contenedor, onTerminaBien) {
    var cadena = '';
    var fichero = '';
    if (GetObjGlobales().EsSpanish()) {
        fichero = fichero_es;
    }
    else {
        fichero = fichero_en;
    }
    $.ajax({
        type: "GET",
        url: GetObjGlobales().getRutaFichero("R", fichero, pferia), //GetObjGlobales().rutarecurso + 'Feria=' + pferia + '&Tipo=R&Fichero=' + fichero,
        dataType: "html",
        success: function (html) {
            contenedor.html(html);
            onTerminaBien();
        }
    });
}

function LoadCabeceraFeria() {
    if (myScroll != "undefined" && myScroll != null) {
        $('#contenido_feria ul').append("<li class='vacio'>&nbsp;</li>");
        GetObjGlobales().cargaMenusPrincipales();
        GetObjGlobales().cargaMenusAgenda();
        GetObjGlobales().cargaMenusDinamicos();
        myScroll.refresh();

        // Se comprueba si hay actualizaciones
        //comprobarActualizacionPendiente es un metodo de interfazCatalogoNativa.js
        comprobarActualizacionPendiente();
    }
}

function CargasGenerales() {
    //CargarRecinto es un metodo de Mapas.js.
    GetObjGlobales().CargarConfiguracion().then(
        function (resultado) {
            //CargarRecinto es un metodo de Mapas.js.
            GetObjGlobales().cargaMenusPrincipales();
            if (GetObjGlobales().arrayParametros['mmapa'] === 'S') {
                return CargarRecinto();
            }
        }
    ).then(
        function (resultado) {
            if (GetObjGlobales().arrayParametros['mmapastand'] === 'S') {
                //CargarPabellon es un metodo de Mapas.js.
                return CargarPabellon();
            }
        }
    ).then(
        function (resultado) {
            setTimeout('InicializaListaEmpresas(GetObjGlobales().feria);', 100);

            //cargamos las listas completas de empresa
            if (GetObjGlobales().EsOffline()) {
                setTimeout('InicializaFichaEmpresas();', 100);
            }
        }
    ).catch(
        function (error) {
            console.log("ERROR", error);
        }
    );
}

function EsCompatibleNavegador() {
    if (!window.addEventListener) {
        return false;
    }
    else {
        return true;
    }
}


function MsgSiNo(titulo, flujoOk, flujoCancel) {
    if (GetObjGlobales().EsSpanish()) {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Aceptar": function () {
                    $(this).dialog("close");
                    flujoOk();
                },
                "Cancelar": function () {
                    $(this).dialog("close");
                    if (flujoCancel != null) {
                        flujoCancel();
                    }
                }
            }
        });
    }
    else {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Ok": function () {
                    $(this).dialog("close");
                    flujoOk();
                },
                "Cancel": function () {
                    $(this).dialog("close");
                    if (flujoCancel != null) {
                        flujoCancel();
                    }
                }
            }
        });
    }
}

function MsgSi(titulo, flujoOk) {
    if (GetObjGlobales().EsSpanish()) {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Aceptar": function () {
                    $(this).dialog("close");
                    if (flujoOk != null) {
                        flujoOk();
                    }
                }
            }
        });
    }
    else {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Ok": function () {
                    $(this).dialog("close");
                    if (flujoOK != null) {
                        flujoOk();
                    }
                }
            }
        });
    }

}

function MsgSiNoCrearContacto(titulo,newContact){
	if (GetObjGlobales().EsSpanish()) {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Aceptar": function () {
                	$(this).dialog("close");
                	newContact.save(function(c_obj){
    	            	$("#contactoCreado").show();
    	            	setTimeout(function() {
    	            		$("#contactoCreado").fadeOut(1000);
    	            	}, 5000);
                	}, function(error){
                    	alert("Ha ocurrido un error: " + error);
                    }) 
    	       	
                },
                "Cancelar": function () {
                	$(this).dialog("close");
                    return false;
                }
            }
        });
    } else {
    	 $("#dialog-confirm").dialog({
             resizable: false,
             height: 190,
             modal: true,
             title: titulo,
             buttons: {
                 "Ok": function () {
                	 	$(this).dialog("close");
                 		newContact.save(function(c_obj){
     	            		$("#contactoCreado").show();
     	            		setTimeout(function() {
     	                		$("#contactoCreado").fadeOut(1000);
     	                	}, 5000);
     	            		
     	                }, function(error){
     	                	alert("There was an error: " + error);
     	                }) 
                 },
                
                 "Cancel": function () {
                 	$(this).dialog("close");
                     return false;
                 }
             }
         });
    	
    	
    }

}
function MsgSiNoActualizarContacto(titulo, oldContact, newContact) {
    if (GetObjGlobales().EsSpanish()) {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Aceptar": function () {
                	$(this).dialog("close");
                	oldContact.remove(function(){
                		newContact.save(function(c_obj){
    	            		
    	            		$("#contactoActualizado").show();
    	            		setTimeout(function() {
    	                		$("#contactoActualizado").fadeOut(1000);
    	                	}, 5000);
    	            		
    	                }, function(error){
    	                	alert("Ha ocurrido un error: " + error);
    	                }); 
                	});
                },
                "Cancelar": function () {
                	$(this).dialog("close");
                    return false;
                }
            }
        });
    }
    else {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Ok": function () {
                	$(this).dialog("close");
                	oldContact.remove(function(){
                		newContact.save(function(c_obj){
    	            		
    	            		$("#contactoActualizado").show();
    	            		setTimeout(function() {
    	                		$("#contactoActualizado").fadeOut(1000);
    	                	}, 5000);
    	            		
    	                }, function(error){
    	                	alert("There was an error: " + error);
    	                }); 
                	});
                },
                "Cancel": function () {
                	$(this).dialog("close");
                    return false;
                }
            }
        });
    }
}

function MsgSiNoEnviarCorreo(titulo, base64data){
	if (GetObjGlobales().EsSpanish()) {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Aceptar": function () {
                	$(this).dialog("close");
                	  cordova.plugins.email.isAvailable(
				        	    function (isAvailable) {
				        	    	if (isAvailable) {
				        	    		cordova.plugins.email.open({
								    	    subject:     'Contactos CSV',
								    	    attachments: ['base64:ListaAcreditaciones.csv//' + base64data]
								    	    //attachments: systemURI + 'ListaAcreditaciones.csv'
								    	});
				        	    	} else {
			        	    			alert('El servicio de envío de email no es compatible con su dispositivo.');
				        	    	}
				        	    }
				        	);
    	       	
                },
                "Cancelar": function () {
                	$(this).dialog("close");
                    return false;
                }
            }
        });
    } else {
    	 $("#dialog-confirm").dialog({
             resizable: false,
             height: 190,
             modal: true,
             title: titulo,
             buttons: {
                 "Ok": function () {
                	 	$(this).dialog("close");
                  	  cordova.plugins.email.isAvailable(
  				        	    function (isAvailable) {
  				        	    	if (isAvailable) {
  				        	    		cordova.plugins.email.open({
  								    	    subject:     'Contacts CSV',
  								    	    attachments: ['base64:AccreditationList.csv//' + base64data]
  								    	    //attachments: systemURI + 'ListaAcreditaciones.csv'
  								    	});
  				        	    	} else {
  				        	    		alert('E-mail service is unavailable.');
  				        	    	}
  				        	    }
  				        	);
                 		
                 },
                
                 "Cancel": function () {
                 	$(this).dialog("close");
                     return false;
                 }
             }
         });
    	
    	
    }
}
function MsgSiEnviarCorreo(titulo, base64data){
	
	if (GetObjGlobales().EsSpanish()) {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: 190,
            modal: true,
            title: titulo,
            buttons: {
                "Aceptar": function () {
                	$(this).dialog("close");
                	  cordova.plugins.email.isAvailable(
				        	    function (isAvailable) {
				        	    	if (isAvailable) {
				        	    		cordova.plugins.email.open({
								    	    subject:     'Contactos CSV',
								    	    attachments: ['base64:ListaAcreditaciones.csv//' + base64data]
								    	    //attachments: systemURI + 'ListaAcreditaciones.csv'
								    	});
				        	    	} else {
				        	    		alert('El servicio de envío de email no es compatible con su dispositivo.');
				        	    	}
				        	    }
				        	);
    	       	
                }
            }
        });
    } else {
    	 $("#dialog-confirm").dialog({
             resizable: false,
             height: 190,
             modal: true,
             title: titulo,
             buttons: {
                 "Ok": function () {
                	 	$(this).dialog("close");
                  	  cordova.plugins.email.isAvailable(
  				        	    function (isAvailable) {
  				        	    	if (isAvailable) {
  				        	    		cordova.plugins.email.open({
  								    	    subject:     'Contacts CSV',
  								    	    attachments: ['base64:AccreditationList.csv//' + base64data]
  								    	    //attachments: systemURI + 'ListaAcreditaciones.csv'
  								    	});
  				        	    	} else {
  				        	    		alert('E-mail service is unavailable.');
  				        	    	}
  				        	    }
  				        	);
                 		
                 }
             }
         });
    	
    	
    }
}

function MsgLeerAcreditacionHelp(titulo, leerAcreditacionFunction) {
    if (GetObjGlobales().EsSpanish()) {
        $("#dialog-confirm-help").dialog({
            resizable: false,
            modal: true,
            width:"85%",
            height:"auto",
            title: "Ayuda lectura acreditacion",
            buttons: {
                "Leer acreditacion": function () {
                	$(this).dialog("close");
                	leerAcreditacionFunction();
                }
            }
        });
    }
    else {
        $("#dialog-confirm-help").dialog({
            resizable: false,
            modal: true,
            width:"85%",
            height:"auto",
            title: "Acreditation help",
            buttons: {
                "Read acreditation": function () {
                	$(this).dialog("close");
                	leerAcreditacionFunction();
                }
            }
        });
    }
}

function MsgLeerAcreditacionHelpButton(titulo) {
    if (GetObjGlobales().EsSpanish()) {
        $("#dialog-help").dialog({
            resizable: false,
            modal: true,
            width:"85%",
            height:"auto",
            title: "Ayuda lectura acreditacion",
          
        });
    }
    else {
        $("#dialog-help").dialog({
            resizable: false,
            modal: true,
            width:"85%",
            height:"auto",
            title: "Acreditation help",
          
        });
    }
}

