/// <reference path="C:\apps\Catalogo Movil\waCatalogoMovil-KENDOUI\waCatalogoMovil\Scripts/kendo/kendo.ui.core.js" />

(function (kendoNameSpace) {
    'use strict';
    //below you place anything private you don't want exposed in the viewModel
    //below we create the viewModel
    kendoNameSpace.listaModel =
    {//create viewModel namespace in global i.e. namespace.[viewModel Name], to expose to global
        dataSource: new kendo.data.DataSource({
            offlineStorage: 'listaModel',
            data: [],
            sort: { field: "allData", dir: "asc" },
            group: { field: "letter" },
            fixedHeaders: true
        }),
        getPersonId: function (id) {

            for (var i = 0; i < this.dataSource.data().length; i++) {
                if (this.dataSource.data()[i].id === id)
                    return this.dataSource.data()[i]
            }
        },
        deleteLectura: function (codigo,callback){
            kendoNameSpace.DBUtils.openDatabase();        	
    		var query = "DELETE FROM codigosAcreditacion WHERE codigoBarras = '" + codigo+ "'" ;    		
            kendoNameSpace.DBUtils.db.transaction(function(tx) {
                tx.executeSql(query,[],function(){callback(0);},function(err){callback(-1);});
            })
        },
        getAllQRCodesFirstLetterPromise : function(Letra, feria) {
    		return new RSVP.Promise(function(resolve, reject) {		
    	        kendoNameSpace.DBUtils.openDatabase();
    	        //var query = "SELECT * FROM codigosQR WHERE (substr(datosCodigoQR,12,1) = '" + Letra.toLowerCase() + "' or substr(datosCodigoQR,12,1) = '" + Letra.toUpperCase() + "') and codigoFeria = '" + feria + "' and estado = 1 order by fecha desc";
    	        var query = "SELECT * FROM codigosAcreditacion WHERE (substr(datosAcreditacion,12,1) = '" + Letra.toLowerCase() + "' or substr(datosAcreditacion,12,1) = '" + Letra.toUpperCase() + "') and codigoFeria = '" + feria + "' order by fecha desc";
    	        kendoNameSpace.DBUtils.db.transaction(function(tx) {
    		        tx.executeSql(
    		                query,
    		            [],
    		            function(tx, res) {    		                	
    		            	if(res.rows.length > 0){
    		                		resolve(res.rows);
    		                	}else{
    		                		resolve(0);
    		            	}
    		            },
    		            function(tx, res) {
    		                	resolve(-1);
    		            });
    		        });
    		});	        
    	},
        getAllQRCodesFeriaPromise : function(feria) {
        	//alert("getAllQRCodesFeriaPromise");
    		return new RSVP.Promise(function(resolve, reject) {
                kendoNameSpace.DBUtils.openDatabase();
                //var query = "SELECT * FROM codigosQR WHERE codigoFeria = '" + feria + "' order by fecha desc";
                var query = "SELECT * FROM codigosAcreditacion WHERE codigoFeria = '" + feria + "' order by fecha desc";
               //alert("query"+query.toString())
                kendoNameSpace.DBUtils.db.transaction(function(tx) {
        	        tx.executeSql(
        	                query,
        	            [],
        	            function(tx, res) {       
        	                	
        	            	if(res.rows.length > 0){
        	                		resolve(res.rows);
        	                	}else{
        	              		    resolve(0);        	                		
        	            	}
        	            },
        	            function(tx, res) {
        	                	reject(-1);
        	            });
        	        });       
    		});
        }    	    	
    },

    kendoNameSpace.DBUtils = {
    		db: null,
    		openDatabase: function() {
				var path = 0;				
				if(GetObjGlobales().so == 'ANDROID'){
					path = 1;
				}			
			    this.db = window.sqlitePlugin.openDatabase(
			        {
			          name: "CatalogoDB",
			          location: path
			        },
			        // success callback
			        function (msg) {
			            //alert("success: " + JSON.stringify(msg));
			        },
			        // error callback
			        function (msg) {
			            //alert("error: " + msg);
			        }
			    );
			},
			checkOpenedDatabase: function() {
			    			if (this.db == null) {
			    					setTimeout(function() {
			    							alert("open the database first");
			    					});
			    					return false;
			    			}
			    			return true;
			}
    }  	
})(kendoNameSpace); //pass in global namespace
