var OFichaEmpresas; //¡¡¡NO USAR ESTA VARIABLE DIRECTAMENTE. UTILIZAR EL MÉTODO GetObjFichaEmpresas()!!!

/*function anyadereventoanalitics() {
 addeventoanalitics(GetObjGlobales().feria, 'WEB EMPRESA', GetObjFichaEmpresas().Empresa.no, 0);
 }*/

function NavegaWebEmpresa(e,destino) {
    //if (isDoubleClickBug(e)) {
    //    return true;
    //}
    addeventoanalitics(GetObjGlobales().feria, 'WEB EMPRESA', GetObjFichaEmpresas().Empresa.no, 0);
    //    addEventoNavegaNoClick(destino);
    return false;
}

//function sleep(milliseconds) {
//	  var start = new Date().getTime();
//  for (var i = 0; i < 1e7; i++) {
//  if ((new Date().getTime() - start) > milliseconds){
//  break;
// }
// }
//}

function ampliarImagenProdDestacado(img,e) {
    
    //sleep(200);
    
    if (isDoubleClickBug(e)) {
        return true;
    }
    
    if (img != undefined) {
        var imgProd = $("#" + img.id).attr("data-imgprod");
        var res = imgProd.replace("p_", "pg_");
        var rutaImg = GetObjGlobales().getRutaFichero("I", res);
        $('#imagepreview').attr('src', rutaImg);
        $('#imageProdDestacadoHD').dialog("open");
    }
}

function GetObjFichaEmpresas() {
    if (OFichaEmpresas == undefined) {
        OFichaEmpresas = new ObjFichaEmpresa();
    }
    return OFichaEmpresas;
}

function InicializaFichaEmpresas() {
    var objFichaEmpresa = GetObjFichaEmpresas();
    objFichaEmpresa.ObtenerFichaEmpresaCompleta();
}

function CargaFichaEmpresa() {
    GetObjGlobales().cargaIdioma();
    GetObjGlobales().InicializarHeader();
    var objficha = GetObjFichaEmpresas();  //new ObjFichaEmpresa();
    objficha.id = getParameterByName('i');
    objficha.feria = GetObjGlobales().feria;
    objficha.objlistaempr = GetObjListaEmpresas();
    objficha.rutaficha = GetObjGlobales().rutaficha;
    objficha.rutarecurso = GetObjGlobales().rutarecurso;
    objficha.CargarFichaEmpresa($("#contenido_" + nombreCapa), getParameterByName('s'));
    setTimeout("elScroll('wrapperGaleria');", 200);
}

function ObjFichaEmpresa() { };
ObjFichaEmpresa.prototype = {
id: 0,
feria: '',
objlistaempresas: '',
rutaficha: '',
rutarecurso: '',
fichaEmpresas: '',
favorito: false,
Empresa: '',
contenido_ficha_empresa:null,
setFichaEmpresas: function (data) {
    fichaEmpresas = data;
},
GetFichaEmpresaById: function (id, st) {
    var fichaActual = null;
    var fichaPorDefecto = null;
    for (var i = 0; i < fichaEmpresas.length; i++) {
        if (fichaEmpresas[i].id.indexOf(id) != -1) {
            fichaPorDefecto = fichaEmpresas[i]; //Si no encuentro otra cosa devuelvo ésta.Puede que no sea la misma que devuelva en la parte web
            if (st != "") {
                if (fichaEmpresas[i].st.indexOf(st) != -1)
                    fichaActual = fichaEmpresas[i];
            }
            else {
                fichaActual = fichaEmpresas[i];
            }
            break;
        }
    }
    if ((fichaActual == null) && (fichaPorDefecto != null)) { fichaActual = fichaPorDefecto };
    return fichaActual;
},
ObtenerFichaEmpresaCompleta: function () {
    var localobj = this;
    $.ajax({
           type: "GET",
           url: GetObjGlobales().getRutaFichero("X", "expositores_fichas.json"),
           data: "{}",
           contentType: "application/json; charset=utf-8",
           dataType: "json",
           cache: false,
           success: function (response) {
           var data = eval(response.d);
           localobj.setFichaEmpresas(data);
           },
           error: errorAjax_Empresa
           });
},
CargarFichaEmpresa: function (contenedor,stand) {
    this.contenido_ficha_empresa = contenedor;
    this.contenido_ficha_empresa.find("#btnCierraAmpliar").click(function () {
                                                                 //GetObjFichaEmpresas().contenido_ficha_empresa.find("#contenedorAmpliar").hide();
                                                                 $("#contenedor_paginas").addClass('contenedorDown');
                                                                 $("#contenedor_paginas").removeClass('contenedorUp');
                                                                 });
    
    //Aquí habrá que controlar si trabamos offline u online
    //De momento supongo que estamos siempre online
    this.CargarSeccionesFicha(stand);
    this.CargarFavorito();
},
CargarFavorito: function () {
    var localid = this.id;
    var localferia = this.feria;
    var localfavorito = this.favorito;
    
    var arrayFavs = GetObjGlobales().loadArrayFavoritos();
    var esFavorito = GetObjGlobales().buscarFavorito(localid, localferia);
    if (esFavorito) {
        $(".fav img").attr('src', 'img/fav_on.png');
        localfavorito = true;
    } else {
        $(".fav img").attr('src', 'img/fav_off.png');
        localfavorito = false;
    }
    
    this.contenido_ficha_empresa.find("#botFav").unbind('click').bind('click', function (e)
                                                                      {
                                                                      
                                                                      if (isDoubleClickBug(e)) {
                                                                      return true;
                                                                      }
                                                                      
                                                                      if (localfavorito) {
                                                                      GetObjGlobales().deleteFavorito(localid, localferia);
                                                                      //DDC lo dejo como estaba
                                                                      GetObjFichaEmpresas().contenido_ficha_empresa.find("#botFav img").attr('src', 'img/fav_off.png');
                                                                      //$("#botFav img").attr('src', 'img/fav_off.png');
                                                                      localfavorito = false;
                                                                      }
                                                                      else {
                                                                      //DDC lo dejo como estaba
                                                                      GetObjGlobales().addFavorito(localid, localferia, GetObjFichaEmpresas().contenido_ficha_empresa.find("#nombre").text());
                                                                      GetObjFichaEmpresas().contenido_ficha_empresa.find("#botFav img").attr('src', 'img/fav_on.png');
                                                                      //GetObjGlobales().addFavorito(localid, localferia, $('#nombre').text());
                                                                      //$("#botFav img").attr('src', 'img/fav_on.png');
                                                                      
                                                                      localfavorito = true;
                                                                      }
                                                                      });
},
CargarDetallesFicha: function (pEmpresa) {
    this.Empresa = pEmpresa;
    if (pEmpresa != null && pEmpresa != "undefined") {
        this.contenido_ficha_empresa.find("#contenedorContactos").show();
        this.contenido_ficha_empresa.find("#destacar").show();
        this.CargarCabecera(pEmpresa);
        this.CargarDatosContacto(pEmpresa);
        this.CargarSectores(pEmpresa);
        this.CargarGenericos(pEmpresa);
        this.CargarCoexpos(pEmpresa);
        this.CargarActividad(pEmpresa);
        this.CargarProducto(pEmpresa);
        this.CargarPaisesRepresentados(pEmpresa);
        this.CargarAdicionales(pEmpresa);
        this.CargaMarcas(pEmpresa);
        this.CargaProductosDestacados(pEmpresa);
        this.CargaEmpresasRepresentadas(pEmpresa);
        this.CargaRedesSociales(pEmpresa);
    }
    else {
        this.contenido_ficha_empresa.find("#contenedorContactos").hide();
        this.contenido_ficha_empresa.find("#destacar").hide();
        GetObjGlobales().titulaCabecera(GetObjGlobales().DameTraduccion(15));
        this.contenido_ficha_empresa.find("#tipoexp").append(GetObjGlobales().DameTraduccion(15));
    }
    addEventoNavega('[rel="enlace"]', 'href');
},
CargarSeccionesFicha: function (stand) {
    this.objlistaempr.EmpresaSeleccionada = '#Empresa_' + this.id;
    var localobj = this;
    if (GetObjGlobales().EsOffline()) {
        localobj.CargarDetallesFicha(this.GetFichaEmpresaById(this.id,stand));
    } else {
        $.ajax({
               type: "GET",
               url: this.rutaficha + 'Feria=' + this.feria + '&Codigo=' + this.id+'&stand='+stand,
               data: "{}",
               contentType: "application/json; charset=utf-8",
               dataType: "json",
               cache: false,
               success: function (response) {
               var Empresa = eval(response.d);
               localobj.CargarDetallesFicha(Empresa);
               },
               error: errorAjax_Empresa
               });
    }
    },
CargarCabecera: function (pEmpresa) {
    GetObjGlobales().titulaCabecera(pEmpresa.no);
    this.contenido_ficha_empresa.find('#nombre').text(pEmpresa.no);
    
    addeventoanalitics(GetObjGlobales().feria, 'Ficha empresa', pEmpresa.no, 0);
    var pabellon = pEmpresa.pb.trim();
    var stand = pEmpresa.st.trim();
    var tipoexp = pEmpresa.te;
    var tieneLogo = false;
    
    this.contenido_ficha_empresa.find('#contenedorLogo').append('<span>' +
                                                                '<img src="" alt="atras" border="0" />' +
                                                                '</span>');
    if (pEmpresa.log != null) {
        tieneLogo = true;
    }
    
    var url;
    if (tieneLogo) {
        url = GetObjGlobales().getRutaFichero("I", pEmpresa.log.url);
    } else {
        url = "img/f_no_LOGO.jpg";
    }
    this.contenido_ficha_empresa.find('#contenedorLogo span img').attr("src", url);
    this.contenido_ficha_empresa.find('#contenedorLogo').show();
    
    this.contenido_ficha_empresa.find('#contenerdorActividad').append('<p id="descripcionEmp" class="descEmpresa"></p>');
    if (pEmpresa.dc != "" ||  pEmpresa.dci != "")
    {
        if (GetObjGlobales().EsSpanish()) {
            this.contenido_ficha_empresa.find('#descripcionEmp').text(pEmpresa.dc);
        }   else {
            this.contenido_ficha_empresa.find('#descripcionEmp').text(pEmpresa.dci);
        }
        this.contenido_ficha_empresa.find('#contenerdorActividad').show();
    }
    
    
    this.contenido_ficha_empresa.find('#tipoexp').text(GetObjGlobales().DameTraduccion(GetObjGlobales().DameIdTipoExp(tipoexp)));
    
    var tPabellon = pabellon.split(';');
    var tstand = stand.split(';');
    for (i = 0; i < tstand.length; i++) {
        if (GetObjGlobales().EsSpanish()) {
            this.contenido_ficha_empresa.find('#stand').append('<div class="donde" href="ajax_plano.html?S=' + tstand[i].trim() + '" rel="enlace"><span>Pabellón ' + tPabellon[i] + ', ' + tstand[i] + '</span></div>');
        }
        else {
            this.contenido_ficha_empresa.find('#stand').append('<div class="donde" href="ajax_plano.html?S=' + tstand[i].trim() + '" rel="enlace"><span>Hall ' + tPabellon[i] + ', ' + tstand[i] + '</span></div>');
        }
    }
},
CargarDatosContacto: function (pEmpresa) {
    ///CONTACTO/////
    var cpypoblacion;
    if (pEmpresa.cp == null)
        cpypoblacion = pEmpresa.po
        else
            cpypoblacion = pEmpresa.cp + ' - ' + pEmpresa.po;
    var provpais;
    var pais;
    if (GetObjGlobales().EsSpanish())
        pais = pEmpresa.pa;
    else
        pais = pEmpresa.pai;
    provpais = pEmpresa.pr + '(' + pais + ')';
    
    var telfax;
    var tel = pEmpresa.tl;
    var fax = pEmpresa.fa;
    telfax = '<span>';
    if (tel != '') { telfax = telfax + '<a rel="boton"  href="tel:' + tel + '"> Tl: ' + tel + '</a>' };
    if (fax != '') { telfax = telfax + '<a rel="boton"  href="fax:' + fax + '"> Fax: ' + fax + '</a>' };
    telfax = telfax + '</span>';
    
    var web;
    if (pEmpresa.we) {
        if (pEmpresa.we.indexOf("http") == -1)
            web = "http://" + pEmpresa.we;
        else
            web = pEmpresa.we;
    }
    else
        web = "";

    var email = ""; //'   <span><a href="mailto:' + pEmpresa.em + '">' + pEmpresa.em + '</a></span>' +
    if (pEmpresa.em) {
        email = '<span> <a href="mailto:' + pEmpresa.em + '"> <img src="img/email.png"> </a> </span>';
    }
    
    this.contenido_ficha_empresa.find('#contactos').append(
                                                           '<div class="datos">' +
                                                           '</div> ' +
                                                           '<div class ="datos">' +
                                                           '   <span class="titDatos">' + pEmpresa.di + '</span>' +
                                                           '   <span class="stitDatos">' + cpypoblacion + '</span>' +
                                                           '   <span class="titDatos">' + provpais + '</span>' + telfax +
                                                           '   <span><a rel="enlace" href="'+web+'" target="_blank" onclick="NavegaWebEmpresa(event,\'' + web + '\');return false;">'
                                                           + web + '</a></span> '+email+'</div>');
},
CargaEmpresasRepresentadas: function (pEmpresa) {
    var tieneRepres = false;
    if (pEmpresa.ltrs != null) {
        var divrepres = this.contenido_ficha_empresa.find('#representadas');
        tieneRepres = true;
        
        pEmpresa.ltrs.sort(this.GetSortOrder("v"));
        
        for (i = 0; i < pEmpresa.ltrs.length; i++) {
            divrepres.append('<li class="concepto" rel="enlace" href="ajax_fichaR.html?i=' +
                             pEmpresa.ltrs[i].id + '">' + cortaPalabrasListaEmpresas(pEmpresa.ltrs[i].v, 0) + '</li>');
        }
    }
    if (tieneRepres) { this.contenido_ficha_empresa.find('#contendorEmpresasRepresentadas').show(); }
},
CargarSectores: function (pEmpresa) {
    var tieneSectores = false;
    if (pEmpresa.lse != null) {
        tieneSectores = true;
        var divsect = this.contenido_ficha_empresa.find('#sectores');
        
        if (GetObjGlobales().EsSpanish()) {
            pEmpresa.lse.sort(this.GetSortOrder("v"));
        }else {
            pEmpresa.lse.sort(this.GetSortOrder("vi"));
        }
        
        for (var i = 0; i < pEmpresa.lse.length; i++) {
            if (GetObjGlobales().EsSpanish())
                divsect.append('<span class="titDatos">' + pEmpresa.lse[i].v + '</span>');
            else
                divsect.append('<span class="titDatos">' + pEmpresa.lse[i].vi + '</span>');
        };
    }
    if (tieneSectores) { this.contenido_ficha_empresa.find('#contenedorSectores').show(); }
},
CargarGenericos: function (pEmpresa) {
    var tieneGenericos = false;
    var contenedorGenericos = this.contenido_ficha_empresa.find('#contenedorGenericos');
    if (pEmpresa.ltge != null) {
        tieneGenericos = true;
        
        if (GetObjGlobales().EsSpanish()) {
            pEmpresa.ltge.sort(this.GetSortOrder("v"));
        }else {
            pEmpresa.ltge.sort(this.GetSortOrder("vi"));
        }
        
        for (var i = 0; i < pEmpresa.ltge.length; i++) {
            if (GetObjGlobales().EsSpanish())
                contenedorGenericos.append(
                                           '<div class="separador">' +
                                           '<span>' + pEmpresa.ltge[i].v + '</span></div>' +
                                           '<div class="datos" id="genericos' + i + '" </div>');
            else
                contenedorGenericos.append(
                                           '<div class="separador">' +
                                           '<span>' + pEmpresa.ltge[i].vi + '</span></div>' +
                                           '<div class="datos" id="genericos' + i + '" </div>');
            if (pEmpresa.ltge[i].lge != null) {
                
                var divgen = this.contenido_ficha_empresa.find('#genericos' + i);
                
                /* (GetObjGlobales().EsSpanish()) {
                    pEmpresa.ltge[i].sort(this.GetSortOrder("v"));
                }else {
                    pEmpresa.ltge[i].sort(this.GetSortOrder("vi"));
                }*/
                
                for (var x = 0; x < pEmpresa.ltge[i].lge.length; x++) {
                    if (GetObjGlobales().EsSpanish())
                        divgen.append('<span class="titDatos">' +
                                      pEmpresa.ltge[i].lge[x].v + '</span>');
                    else
                        divgen.append('<span class="titDatos">' +
                                      pEmpresa.ltge[i].lge[x].vi + '</span>');
                }
            }
        }
    }
    if (tieneGenericos) { contenedorGenericos.show(); }
},
CargarCoexpos: function (pEmpresa) {
    var tieneCoexpos = false;
    if (pEmpresa.lco != null) {
        var divcoex = this.contenido_ficha_empresa.find('#coexpos');
        tieneCoexpos = true;
        
        pEmpresa.lco.sort(this.GetSortOrder("v"));
        
        for (i = 0; i < pEmpresa.lco.length; i++) {
            divcoex.append('<li class="concepto" rel="enlace" href="ajax_ficha.html?i=' +
                           pEmpresa.lco[i].id + '">' + cortaPalabrasListaEmpresas(pEmpresa.lco[i].v, 0) + '</li>');
            
            /*                if (GetObjGlobales().EsSpanish()) {
             divcoex.append('<li class="concepto" rel="enlace" href="ajax_ficha.html?i=' +
             pEmpresa.lco[i].id + '">' + cortaPalabrasListaEmpresas(pEmpresa.lco[i].v, 0) + '</li>');
             }
             else {
             divcoex.append('<li class="concepto" rel="enlace" href="ajax_ficha.html?i=' +
             pEmpresa.lco[i].id + '">' + cortaPalabrasListaEmpresas(pEmpresa.lco[i].vi, 0) + '</li>');
             }*/
        }
    }
    if (tieneCoexpos) { this.contenido_ficha_empresa.find('#contenedorCoexpos').show(); }
},
CargarActividad: function (pEmpresa) {
    var tieneActividad = false;
    if (pEmpresa.lac != null) {
        tieneActividad = true;
        var divsect = this.contenido_ficha_empresa.find('#actividades');
        
        if (GetObjGlobales().EsSpanish()) {
            pEmpresa.lac.sort(this.GetSortOrder("v"));
        }else {
            pEmpresa.lac.sort(this.GetSortOrder("vi"));
        }
        
        for (var i = 0; i < pEmpresa.lac.length; i++) {
            if (GetObjGlobales().EsSpanish())
                divsect.append('<span class="titDatos">' + pEmpresa.lac[i].v + '</span>');
            else
                divsect.append('<span class="titDatos">' + pEmpresa.lac[i].vi + '</span>');
        };
    }
    if (tieneActividad) { this.contenido_ficha_empresa.find('#contenedorActividad').show(); }
},
CargarProducto: function (pEmpresa) {
    var tieneProducto = false;
    if (pEmpresa.lpr != null) {
        tieneProducto = true;
        var divsect = this.contenido_ficha_empresa.find('#productos');
        
        if (GetObjGlobales().EsSpanish()) {
            pEmpresa.lpr.sort(this.GetSortOrder("v"));
        }else {
            pEmpresa.lpr.sort(this.GetSortOrder("vi"));
        }
        
        for (var i = 0; i < pEmpresa.lpr.length; i++) {
            if (GetObjGlobales().EsSpanish())
                divsect.append('<span class="titDatos">' + pEmpresa.lpr[i].v + '</span>');
            else
                divsect.append('<span class="titDatos">' + pEmpresa.lpr[i].vi + '</span>');
        };
    }
    if (tieneProducto) { this.contenido_ficha_empresa.find('#contenedorProductos').show(); }
},
CargarPaisesRepresentados: function (pEmpresa) {
    var tienePaisRep = false;
    if (pEmpresa.lprs != null) {
        tienePaisRep = true;
        var divsect = this.contenido_ficha_empresa.find('#PaisesRepresentados');
        
        if (GetObjGlobales().EsSpanish()) {
            pEmpresa.lprs.sort(this.GetSortOrder("v"));
        }else {
            pEmpresa.lprs.sort(this.GetSortOrder("vi"));
        }
        
        for (var i = 0; i < pEmpresa.lprs.length; i++) {
            if (GetObjGlobales().EsSpanish())
                divsect.append('<span class="titDatos">' + pEmpresa.lprs[i].v + '</span>');
            else
                divsect.append('<span class="titDatos">' + pEmpresa.lprs[i].vi + '</span>');
        };
    }
    if (tienePaisRep) { this.contenido_ficha_empresa.find('#contenedorPaisesRepresentados').show(); }
},
CargarAdicionales: function (pEmpresa)
    {
        var tieneAdicionales = false;
        if (pEmpresa.lcc != null)
        {
            tieneAdicionales = true;
            var divsect = this.contenido_ficha_empresa.find('#adicionales');
            
            //if (GetObjGlobales().EsSpanish()) {
            //    pEmpresa.lcc.sort(this.GetSortOrder("no"));
            //}else {
            //    pEmpresa.lcc.sort(this.GetSortOrder("noi"));
            //}
            
            for (var i = 0; i < pEmpresa.lcc.length; i++)
            {
                var campoConfigurableNombre;
                if (GetObjGlobales().EsSpanish()) campoConfigurableNombre = pEmpresa.lcc[i].no;
                else campoConfigurableNombre = pEmpresa.lcc[i].noi;
                
                if ("tc" in pEmpresa.lcc[i] && pEmpresa.lcc[i].tc == 'S')
                {
                    // Titulos configurables. Ej.: ARCO.
                    divsect.append('<span class="tituloConfigurable">' + campoConfigurableNombre + '</span>');
                }
                else
                {
                    divsect.append(
                                   '<span id="campoConfigurable">' +
                                   '<span class="campoConfigurableNombre">' + campoConfigurableNombre + '</span>' +
                                   '<span class="campoConfigurableValor">' + pEmpresa.lcc[i].v + '</span>' +
                                   '</span>'
                                   );
                }
            };
        }
        if (tieneAdicionales) { this.contenido_ficha_empresa.find('#contenedorAdicionales').show(); }
    },
CargaMarcas: function (pEmpresa) {
    var tieneMarcas = false;
    var contenedorMarcas = this.contenido_ficha_empresa.find('#contenedorMarcas');
    if (pEmpresa.ltma != null) {
        var objmarcas = this.contenido_ficha_empresa.find('#marcas');
        tieneMarcas = true;
        
        pEmpresa.ltma.sort(this.GetSortOrder("v"));
        
        for (var i = 0; i < pEmpresa.ltma.length; i++) {
            objmarcas.append('<span class="titDatos">' + cortaPalabrasListaEmpresas(pEmpresa.ltma[i].v, 0) + '</span>');
            if (pEmpresa.ltma[i].lpa != null) {
                
                if (GetObjGlobales().EsSpanish()) {
                    pEmpresa.ltma[i].lpa.sort(this.GetSortOrder("v"));
                }else {
                    pEmpresa.ltma[i].lpa.sort(this.GetSortOrder("vi"));
                }
                
                
                for (var x = 0; x < pEmpresa.ltma[i].lpa.length; x++) {
                    if (GetObjGlobales().EsSpanish())
                        objmarcas.append('<span class="titDatos1">' +
                                         pEmpresa.ltma[i].lpa[x].v + '</span>');
                    else
                        objmarcas.append('<span class="titDatos1">' +
                                         pEmpresa.ltma[i].lpa[x].vi + '</span>');
                }
            }
        }
    }
    if (tieneMarcas) { contenedorMarcas.show(); }
},
CargaRedesSociales: function (pEmpresa) {
    if (pEmpresa.soc != null) {
        var divRedesSociales = this.contenido_ficha_empresa.find('#contenedorSocial');
        var divSup = '<div style="float:left;width:50px;height:30px;">';
        var urlSocial;
        var RedesSociales = "";
        
        for (var i = 0; i < pEmpresa.soc.length; i++) {
            
            urlSocial = pEmpresa.soc[i].url;
            if (urlSocial.indexOf("http") == -1)
                urlSocial = "http://" + urlSocial;
            
            if (pEmpresa.soc[i].nom.toLowerCase() == "facebook") {
                if (urlSocial == "") {
                    urlSocial = "http://wwww.facebook.com";
                }
            } else if (pEmpresa.soc[i].nom.toLowerCase() == "twitter") {
                if (urlSocial == "") {
                    urlSocial = "https://twitter.com/";
                }
            } else if (pEmpresa.soc[i].nom.toLowerCase() == "linkedin") {
                if (urlSocial == "") {
                    urlSocial = "http://www.linkedin.com/";
                }
            }
            else if (pEmpresa.soc[i].nom.toLowerCase() == "youtube") {
                if (urlSocial == "") {
                    urlSocial = "http://www.youtube.com/";
                }
            }
            //                RedesSociales = RedesSociales + '<div style="display:inline-block;"> <a href="#" onclick="addEventoNavegaNoClick(\'' + urlSocial + '\');"' + '" title ="' + pEmpresa.soc[i].nom + '" target="_blank">' + divSup + '<img style="width:30px;height:30px;" src="' +
            //                             GetObjGlobales().getRutaFichero("I", pEmpresa.soc[i].img) + '" /></div></a></div>';
            RedesSociales = RedesSociales + '<div style="display:inline-block;"> <a href="' + urlSocial + '"  title ="' + pEmpresa.soc[i].nom + '" target="_blank" rel="enlace">' + divSup + '<img style="width:30px;height:30px;" src="' +
            GetObjGlobales().getRutaFichero("I", pEmpresa.soc[i].img) + '" /></div></a></div>';
            
        }
        if (RedesSociales != "") {
            divRedesSociales.append(RedesSociales);
            divRedesSociales.show();
        }
    } else {
        // Se oculta la sección de red social
        var divRedesSociales = this.contenido_ficha_empresa.find('#contenedorSocial');
        divRedesSociales.hide();
    }
},
CargaProductosDestacados: function (pEmpresa) {
    var tieneProdDestacados = false;
    if (pEmpresa.lpd != null) {
        tieneProdDestacados = true;
        var divsect = this.contenido_ficha_empresa.find('#contenedorProductosDestacados');
        var Pdes, Ddes, dImagen, DivContenedor, DivContenedorJ;
        
        if (GetObjGlobales().EsSpanish()) {
            pEmpresa.lpd.sort(this.GetSortOrder("dc"));
        }else {
            pEmpresa.lpd.sort(this.GetSortOrder("dci"));
        }
        
        for (var i = 0; i < pEmpresa.lpd.length; i++) {
            DivContenedor = 'DivContenedorPD' + i;
            divsect.append('<div id=' + DivContenedor + ' class="datos" ></div>');
            DivContenedorJ = this.contenido_ficha_empresa.find("#" + DivContenedor);
            if (GetObjGlobales().EsSpanish())
                DivContenedorJ.append('<span class="titDatos">' + pEmpresa.lpd[i].dc + '</span>');
            else
                DivContenedorJ.append('<span class="titDatos">' + pEmpresa.lpd[i].dci + '</span>');
            
            Ddes = 'DDes' + i;
            DivContenedorJ.append('<div id=' + Ddes + ' class="contCarrusel" class="datos" ></div>');
            dImagen = this.contenido_ficha_empresa.find("#" + Ddes);
            var url = pEmpresa.lpd[i].url;
            if (url != null) {
                Pdes = 'ProdDes' + i;
                dImagen.append(
                               '<span class="ImgProductos">' +
                               '<img id=' + Pdes + ' src="" alt="atras" border="0" data-imgprod=' + url + ' onclick="ampliarImagenProdDestacado(this,event);" />' +
                               '</span>');
                this.contenido_ficha_empresa.find("#" + Pdes).attr("src", GetObjGlobales().getRutaFichero("I", url));
            }
            DivContenedorJ.append(dImagen);
        }
    }
    if (tieneProdDestacados) { this.contenido_ficha_empresa.find('#contenedorProductosDestacados').show(); }
},
GetSortOrder: function (prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }
}

//DDC no veo muy claro esto hay que revisarlo
function errorAjax_Empresa(result) {
    mostrarError();
}

