/// <reference path="C:\apps\Catalogo Movil\waCatalogoMovil-KENDOUI\waCatalogoMovil\Scripts/kendo/kendo.ui.core.js" />

(function (kendoNameSpace) {
    'use strict';
    //below you place anything private you don't want exposed in the viewModel
    //below we create the viewModel
    kendoNameSpace.navegacion =
    {
        promesaDefaultLayout: null,
        appKendo: null,
        NavegaBack: function () {
            navegacion.pop();
            var cadena = navegacion.pop();
            if (cadena.indexOf('?') > 0) {
                cadena += '&go=back'
            }
            else {
                cadena += '?go=back'
            }
            navega(cadena);
        },
        NavegaHome: function () {
            //navega('#ajax_feria.html');
            navega(GetObjGlobales().paginaInicial);
        },
        NavegaFicha: function (e) {
        	addeventoanalitics(GetObjGlobales().feria, "Acreditacion Visualizar", e.data.id, 0);
        	navega('kendo/ui/acreditaciones/ajax_fichaAcreditacion.html?id=' + e.data.id);
        }, 
        NavegaLey: function(){
        	navega('kendo/ui/acreditaciones/ajax_leyProteccionDatos.html');
        },
        getDefaultLayout: function () {
            this.promesaDefaultLayout = this.promesaDefaultLayout ||
                new RSVP.Promise(function (resolve, reject) {
                    $.ajax({
                        type: "GET",
                        url: 'kendo/shared/layouts/default.html',
                        success: function (data) {
                            resolve(data);
                        },
                        error: function (reason) {
                            reject(reason);
                        }
                    });
                });

            return this.promesaDefaultLayout;
        },
        cargaURL: function (url) {
            urlG = url;
            nombreCapa = url.replace('.html', '').replace('kendo/ui/acreditaciones/ajax_', '');
            if (nombreCapa.indexOf('?') > 0) {
                nombreCapa = nombreCapa.substring(0, nombreCapa.indexOf('?'));
                nombreCapa += '_' + getParameterByName('i');

            }
            nombreCapaAnterior = rutaAnterior.replace('.html', '').replace('kendo/ui/acreditaciones/ajax_', '').replace('ajax_', '');
            if (nombreCapaAnterior.indexOf('?') > 0) {
                var par = sacaParametro('i', nombreCapaAnterior)
                nombreCapaAnterior = nombreCapaAnterior.substring(0, nombreCapaAnterior.indexOf('?'));
                nombreCapaAnterior += '_' + par;
            }
            navegacion.push(url);
            if (myScroll) { myScroll.destroy(); myScroll = null; }

            $.ajax({
                url: url,
                success: function (data) {
                    kendoNameSpace.navegacion.OcultarParteAntigua();
                    rutaAnterior = url;
                    kendoNameSpace.navegacion.getDefaultLayout().then(function (htmlDefault) {
                        $('#contenedor_paginas_kendo').html(htmlDefault + data);
                        setTimeout(function () { kendoNameSpace.navegacion.InitAppKendo() }, 100);
                        $('#contenedor_paginas_kendo').fadeIn();
                        kendoNameSpace.navegacion.cargadoB();
                    });
                }
            });
            return false;
        },
        OcultarParteAntigua: function () {
            $('#header').hide();
            $('#capaCargador').hide();
            $('#CapaProgressBar').hide();
            $('#contenedor_paginas').hide();
        },
        MostrarParteAntigua: function () {
            $('#contenedor_paginas').show();
        },
        OcultarParteNueva: function () {
            $('#contenedor_paginas_kendo').hide();
        },
        cargadoB: function () {
            localStorage.setItem(GetObjGlobales().feria + '_lastURL', location.hash);
            rutaAnterior2 = rutaAnterior;
            rutaAnterior = urlG;
            if (nombreCapa != nombreCapaAnterior) {
                if (nombreCapaAnterior.indexOf("AGENDA_") > -1) //si es que damos a HOME desde alguna opci�n interna de Agenda
                    setTimeout("$('#contenido_agenda_').remove();", 100);
                else
                    setTimeout("$('#contenido_' + nombreCapaAnterior).remove();", 100);
            }
        },
        InitAppKendo: function () {
            this.appKendo = new kendo.mobile.Application($("#contenedor_paginas_kendo"), { browserHistory: false, skin: 'flat' });
        }

    }
})(kendoNameSpace); //pass in global namespace

