
var _tBusquedaNormal = 1; //constante
var _tBusquedaContiene = 2; //constante
var _tBusquedaEmpiezaPor = 3;  //constante
var OListaEmpresas; //¡¡¡NO USAR ESTA VARIABLE DIRECTAMENTE. UTILIZAR EL MÉTODO GetObjListaEmpresas()!!!

function GetObjListaEmpresas() {
    if (OListaEmpresas == undefined) {
        OListaEmpresas = new ObjListaEmpresas();
    }
    return OListaEmpresas;
}

function InicializaListaEmpresas(pferia) {
    var objlista = GetObjListaEmpresas();
    objlista.feria = pferia;
    objlista.ObtenerListaCompleta();
}

function ObjListaEmpresas() {};
ObjListaEmpresas.prototype = {
    ListaCompleta: '',
    Lista_OK: false,
    NumPagina: 0,
    NEmpresas: 20,
    ListaInicial: '',
    ultimaletra: '',
    TipoBusqueda: 1, //1 - Normal, 2 - Filtro, 3 - Letra
    ListaPintada: '',
    tipolistado: '',
    txtBuscar: '',
    btnBuscar: '',
    borrartxt: '',
    feria: '',
    myScrollListaEmpr: '',
    EmpresaSeleccionada: '',
    DameTipoBusqueda: function () {
        switch (this.TipoBusqueda) {
            case _tBusquedaNormal:
                return "TODO"
                break;
            case _tBusquedaContiene:
                return "CONTIENE"
                break;
            case _tBusquedaEmpiezaPor:
                return "EMPIEZAPOR";
                break;
        }
    },
    ObtenerNombreCookie: function (pferia) {
        var cadena = "";
        if (pferia != null && pferia != "")
            cadena = "CodFeria=" + pferia;
        return cadena;
    },
    InicializaBusqueda: function (plistapintada, ptipobusqueda, pultimaletra, ptxtbuscar) {
        this.ListaPintada = plistapintada;
        this.TipoBusqueda = ptipobusqueda;
        this.ultimaletra = pultimaletra;
        this.txtBuscar = ptxtbuscar;
        
    },
    storeCurrentEmpresas: function () {
        var cookieListaEmpresas = this.ObtenerNombreCookie(GetObjListaEmpresas().feria) + "Empresas";
        $.DSt.set(cookieListaEmpresas, this.ListaCompleta);
    },
    SetListaCompleta: function (plista) {
        this.ListaCompleta = plista;
        this.Lista_OK = true;
    },
    ObtenerListaCompleta: function () {
        this.ListaCompleta = "";
        
        if (GetObjGlobales().EsOffline()) {
            if (this.tipolistado == "N") {
                var urlLlamar = GetObjGlobales().getRutaFichero("X", "expositores.json");
            }
            else {
                var urlLlamar = GetObjGlobales().getRutaFichero("X", "expositores_logo.json");
            }
        }
        else {

            if (this.tipolistado == "N") {
                //   listado simple
                var urlLlamar = GetObjGlobales().rutaroot + "wsListaExpositores.svc/ObtenerExpositores?Feria=" + this.feria +
                                            "&tipoBusqueda=TODO" +
                                            "&numEmpresas=9999999" +
                                            "&numpagina=1&cadena=";
            }
            else {
                //   LISTADO CON IMAGENES
                var urlLlamar = GetObjGlobales().rutaroot + "wsListaExpositores.svc/ObtenerExpositoresImagenes?Feria=" + this.feria +
                                                "&tipoBusqueda=TODO" +
                                                "&numEmpresas=9999999" +
                                                "&numpagina=1&cadena=''&conActividad=false";
            }
        }
        $.ajax({
            type: "GET",
            url: urlLlamar,
            data: "{}",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            cache: false,
            success: function (response) {
                var data = eval(response.d);
                GetObjListaEmpresas().ListaCompleta = data;
                //this.storeCurrentEmpresas(pferia);No consigo que me funcione la llamada a storeCurrentEmpresas
                var cookieListaEmpresas = GetObjListaEmpresas().ObtenerNombreCookie(GetObjListaEmpresas().feria) + "Empresas";
                $.DSt.set(cookieListaEmpresas, GetObjListaEmpresas().ListaCompleta);
                GetObjListaEmpresas().Lista_OK = true;
            },
            error: errorAjax
        });
    },
    BusquedaServidor: function () {
        var strtipobusqueda = this.DameTipoBusqueda();

        if (this.tipolistado == "N") {
            //   LISTADO SIMPLE
            var urlLlamar = GetObjGlobales().rutaroot + "wsListaExpositores.svc/ObtenerExpositores?Feria=" + this.feria +
                                "&tipoBusqueda=" + strtipobusqueda +
                                "&numEmpresas=" + this.NEmpresas + "&numpagina=" + this.NumPagina + "&cadena=" + this.txtBuscar;
        }
        else {
            //   LISTADO CON IMAGENES
            var urlLlamar = GetObjGlobales().rutaroot + "wsListaExpositores.svc/ObtenerExpositoresImagenes?Feria=" + this.feria +
                                "&tipoBusqueda=" + strtipobusqueda +
                                "&numEmpresas=" + this.NEmpresas + "&numpagina=" + this.NumPagina + "&cadena=" + this.txtBuscar+ "&conActividad=false";
        }

        $.ajax({
            type: "GET",
            url: urlLlamar,
            data: "{}",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            cache: false,
            success: function (response) {
                var data = eval(response.d);
                //GetObjListaEmpresas().NumPagina++;
                if (GetObjListaEmpresas().tipolistado == "N")
                    GetObjListaEmpresas().PintarEmpresas(data);
                else {
                    GetObjListaEmpresas().PintarEmpresasLogo(data);
                }
            },
            error: errorAjax
        });
    },
    CargaInicial: function () {
        this.NumPagina = 1;
        this.InicializaBusqueda("", _tBusquedaNormal, "", "");

        if (GetObjGlobales().EsOffline()) {
            BuscarSiguientesConFadeOut();
        } else {
            this.BusquedaServidor();
        }
    },
    CargarMasEmpresas: function () {
        this.NumPagina++;
        this.ListaPintada = "";
        switch (this.TipoBusqueda) {
            case _tBusquedaContiene:
                if (this.txtBuscar.length >= 1) {
                    this.BuscarSiguientes();
                }
                break;
            default:
                this.BuscarSiguientes();
        }
    },
    CambiaIdiomaBuscar: function () {
        if (GetObjGlobales().EsSpanish()) {
            this.btnBuscar.val('Buscar');
        }
        else {
            this.btnBuscar.val('Search');
        }
    },
    //en ListaCompleta tenemos todo
    BusquedaLocal: function () {
        var data;
        var desde = this.NEmpresas * (this.NumPagina - 1);
        var hasta = this.NEmpresas * this.NumPagina;
        if (this.TipoBusqueda == _tBusquedaNormal) {//"TODO"
            data = GetObjListaEmpresas().ListaCompleta.slice(desde, hasta);
            //data = this.ListaCompleta.slice(desde, hasta);
        }
        else {
            data = [];
            if (this.TipoBusqueda == _tBusquedaEmpiezaPor) { //"EMPIEZAPOR"
                var i;
                var cont = 0;
                var j = 0;
                for (i = 0; i < this.ListaCompleta.length; i++) {
                    var cade = this.ListaCompleta[i].no.substring(0, this.txtBuscar.length);
                    var cade = normalize(cade);
                    if (this.txtBuscar != '0') {
                        if (cade.toUpperCase() == this.txtBuscar.toUpperCase()) {
                            if ((cont >= desde) && (cont < hasta)) {
                                data[j] = this.ListaCompleta[i];
                                j++;
                            }
                            cont++;
                        }
                    }
                    else {
                        var cade = cade.toUpperCase();
                        if (!EsLetraAlfabeto(cade)) {
                            if ((cont >= desde) && (cont < hasta)) {
                                data[j] = this.ListaCompleta[i];
                                j++;
                            }
                            cont++;
                        }
                    }
                }
            }
            else { //contiene cadena
                var i;
                var cont = 0;
                var j = 0;
                for (i = 0; i < this.ListaCompleta.length; i++) {
                    var cade = this.ListaCompleta[i].no.toUpperCase();
                    if (cade.indexOf(this.txtBuscar.toUpperCase()) != -1) {
                        if ((cont >= desde) && (cont < hasta)) {
                            data[j] = this.ListaCompleta[i];
                            j++;
                        }
                        cont++;
                    }
                }
            }
        }
        return data;
    },
    BuscarSiguientes: function () {
        //ya tenemos la lista completa en local?
        if (this.Lista_OK) {
            //busqueda en local y pintarlos
            var data = this.BusquedaLocal();
            if (this.tipolistado == "N")
                this.PintarEmpresas(data);
            else {
                this.PintarEmpresasLogo(data);
            }
        }
        else {
            //búsqueda en server y pintarlos
            this.NumPagina++;
            this.BusquedaServidor();   
        }
    },
    ListarEmpresas: function (pListaEmpresas) {
        var localobj = this;
        if (this.txtBuscar != "") { this.borrartxt.show(); }
        this.ListaInicial = pListaEmpresas;
        if (this.ListaPintada == "") {
            // obtenemos datos guardados anteriormente si los encontramos. en caso contrario llamamos al servicio para obtener las empresas
            var cookieListaEmpresas = this.ObtenerNombreCookie(GetObjListaEmpresas().feria) + "Empresas";
            var storedEmpresasTxt = $.DSt.get(cookieListaEmpresas);
            //if this.lista_ok = true
            if (storedEmpresasTxt != null) {
                //los recupero de la cache
                this.SetListaCompleta(storedEmpresasTxt);
                this.NumPagina = 1;
                this.txtBuscar = "";
                BuscarSiguientesConFadeOut();
            }
            else {
                this.CargaInicial();
            }
            this.CambiaIdiomaBuscar();
        }
        else {
            this.ListaInicial.innerHTML = this.ListaPintada;
            if (this.TipoBusqueda == _tBusquedaContiene) {
                this.btnBuscar.val(this.txtBuscar)
            }
            else {
                this.CambiaIdiomaBuscar();
            }

            if (this.EmpresaSeleccionada != '') {
                setTimeout(function () {
                    localobj.myScrollListaEmpr.refresh();
                    localobj.myScrollListaEmpr.scrollToElement(localobj.EmpresaSeleccionada, "0s");
                }, 350);
            }
        }
        this.btnBuscar.focus();
    },
    PintarEmpresasLogo: function (data) {
        var primeraletra;
        var letra = '';
        var i, li, no, id, st, url, txtUbicacion;
        letra = this.ultimaletra;

        if (GetObjGlobales().EsSpanish())
            txtUbicacion = 'Ubicación: ';
        else
            txtUbicacion = 'Location: ';

        for (i = 0; i < data.length; i++) {
            no = data[i].no;
            id = data[i].id;
            te = data[i].te;
            st = data[i].st;
            url = data[i].url;
            no = cortaPalabrasListaEmpresas(no, 0);
            primeraletra = normalize(no.substr(0, 1));
            if (primeraletra.toUpperCase() != letra.toUpperCase()) {
                if ((!isNaN(primeraletra)) || (!EsLetraAlfabeto(primeraletra))) {//si es número
                    if (letra != '#') {
                        letra = '#';
                        li = document.createElement('li');
                        li.innerText = letra;
                        li.setAttribute('class', 'letraLista');
                        li.setAttribute('id', letra);
                        this.ListaInicial.appendChild(li, this.ListaInicial.childNodes[0]);
                    }
                } else {
                    letra = primeraletra;
                    li = document.createElement('li');
                    li.innerText = letra;
                    li.setAttribute('class', 'letraLista');
                    li.setAttribute('id', letra);
                    this.ListaInicial.appendChild(li, this.ListaInicial.childNodes[0]);
                }
            }
            li = document.createElement('li'); //DDC falta meterlo en propiedad
            var MyA;
            var nLi = "ListaEmpresa" + id;
            var nTa = "Empresa_" + id; //maem
            li.id = nLi;
            li.setAttribute('rel', 'enlace');
            li.setAttribute('class', 'row');
            //si es representada -->> va a su propia ficha
            if (te == 'R') {
                li.setAttribute('href', 'ajax_fichaR.html?i=' + id);
                MyA = ' "ajax_fichaR.html?i=' + id + '" ';
            }
            else {
                li.setAttribute('href', 'ajax_ficha.html?i=' + id);
                MyA = ' "ajax_ficha.html?i=' + id + '" ';
            }

            var MyIm;
            if (GetObjGlobales().EsOffline()) {
                if (url != null && url != "") {
                    MyIm = GetObjGlobales().getRutaFichero("I", url);
                } else {
                    MyIm = "img/sin-imagen.png";
                }
            } else {
                if (url != null && url != "") {
                    MyIm = GetObjGlobales().rutarecurso + 'Feria=' + this.feria + '&Tipo=I&Fichero=' + url;
                } else {
                    MyIm = "img/sin-imagen.png";
                }
            }

            //con load - tabindex='+id+'
            var TFicha = '<div id=' + nTa + '><table><tbody><tr><td><a>' +
                         '<div class="ImgLogo"><img src="" border="0"></div></a></td> ' +
        	             '<td class="espIzquierda"><a>' +
                         '<p class="title">' + no + '</p><p class="note">' + txtUbicacion + st + '</p></a></td></tr></tbody></table></div>';

            this.ListaInicial.appendChild(li, this.ListaInicial.childNodes[0]);
            $('#' + nLi).html(TFicha);
            //con load
            $('#' + nLi).find('img').attr("src", MyIm).load(function () {
                if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {
                }
            });
        }

        this.ListaPintada = this.ListaInicial.innerHTML;

        this.ultimaletra = letra;
        addEventoNavega('[rel="enlace"]', 'href');

        setTimeout(function () { GetObjListaEmpresas().myScrollListaEmpr.refresh() }, 300);
    },
    PintarEmpresas: function (data) {
        var primeraletra;
        var letra = '';
        var i, li, no, id, te;
        letra = this.ultimaletra;
        for (i = 0; i < data.length; i++) {
            no = data[i].no;
            id = data[i].id;
            te = data[i].te; //tipo de empresa
            no = cortaPalabrasListaEmpresas(no, 0);
            primeraletra = normalize(no.substr(0, 1));
            if (primeraletra.toUpperCase() != letra.toUpperCase()) {
                if ((!isNaN(primeraletra)) || (!EsLetraAlfabeto(primeraletra))) {//si es número
                    if (letra != '#') {
                        letra = '#';
                        li = document.createElement('li');
                        li.innerText = letra;
                        li.setAttribute('class', 'letraLista');
                        li.setAttribute('id', letra);
                        this.ListaInicial.appendChild(li, this.ListaInicial.childNodes[0]);
                    }
                } else {
                    letra = primeraletra;
                    li = document.createElement('li');
                    li.innerText = letra;
                    li.setAttribute('class', 'letraLista');
                    li.setAttribute('id', letra);
                    this.ListaInicial.appendChild(li, this.ListaInicial.childNodes[0]);
                }
            }
            li = document.createElement('li');
            //Meter método para cortar a 50 caracteres el nombre
            var nTa = "Empresa_" + id;
            var nLi = "ListaEmpresa" + id;
            //li.innerText = no;
            li.id = nLi;
            li.setAttribute('rel', 'enlace');

            //si es representada -->> va a su propia ficha
            if (te == 'R')
                li.setAttribute('href', 'ajax_fichaR.html?i=' + id);
            else
                li.setAttribute('href', 'ajax_ficha.html?i=' + id);

            this.ListaInicial.appendChild(li, this.ListaInicial.childNodes[0]);

            var TFicha = '<div id=' + nTa + '> <p>' + no + '</p> </div>';
            $('#' + nLi).html(TFicha);
        }
        this.ListaPintada = this.ListaInicial.innerHTML;
        this.ultimaletra = letra;
        addEventoNavega('[rel="enlace"]', 'href');

        setTimeout(function () { GetObjListaEmpresas().myScrollListaEmpr.refresh() }, 300);
    }
}

//******************************************* INICIO funciones iSroll *****************************

function CrearScrollEmpresas() {
    var pullDownOffset, pullUpEl, pullUpOffset;//no entiendo porque funcioan esto DDC

    pullUpEl = document.getElementById('pullUp');
    pullUpOffset = pullUpEl.offsetHeight;

    GetObjListaEmpresas().myScrollListaEmpr = new iScroll('wrapper', {
        useTransition: true,
        hScrollbar: false,
        vScrollbar: true,
        momentum: true,
        useTransform: false,
        topOffset: pullDownOffset,
        onRefresh: function () {
            if (pullUpEl.className.match('loading')) {
                pullUpEl.className = '';
                pullUpEl.querySelector('.pullUpLabel').innerHTML = GetObjGlobales().DameTraduccion(13); // 'Pull up to load more...';
            }
        },
        onScrollMove: function () {
            if (this.y < (this.maxScrollY - 5) && !pullUpEl.className.match('flip')) {
                pullUpEl.className = 'flip';
                pullUpEl.querySelector('.pullUpLabel').innerHTML = GetObjGlobales().DameTraduccion(14); //release to refresh
                this.maxScrollY = this.maxScrollY;
            } else if (this.y > (this.maxScrollY + 5) && pullUpEl.className.match('flip')) {
                pullUpEl.className = '';
                pullUpEl.querySelector('.pullUpLabel').innerHTML = GetObjGlobales().DameTraduccion(13); //'Pull up to load more...';
                this.maxScrollY = pullUpOffset;
            }
        },
        onScrollEnd: function () {
            if (pullUpEl.className.match('flip')) {
                pullUpEl.className = 'loading';
                pullUpEl.querySelector('.pullUpLabel').innerHTML = GetObjGlobales().DameTraduccion(12); // {'Loading...';
                GetObjListaEmpresas().CargarMasEmpresas(); // Execute custom function (ajax call?)
            }
        }
    });

    setTimeout(function () { document.getElementById('wrapper').style.left = '0'; }, 800);
}

if (document.addEventListener)
    document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);


//******************************************* FIN funciones iSroll *****************************




/*function ajustaimagenLogo() {
    $('div.ImgLogo img').each(function () {
        var cual = $(this);
        var ancho = cual.width();
        var alto = cual.height();
        fotilla(ancho, alto, cual, "48", "48");
        ObjListaEmpresas.myScrollListaEmpr.refresh(); //al final de la carga ya se hace un refresh
    });
}*/

function errorAjax(result) {
    //alert('ERROR ' + result.status + ' ' + result.statusText);
    //showPrincipal();
}

function BuscarSiguientesConFadeOut() {
    GetObjListaEmpresas().BuscarSiguientes();
    $('#capaCargador').fadeOut();
}



