// este fichero esta para poder compartir codigo con Catálogo Web 

/// <reference path="Agenda.js" />

var oControlador;

function GetObjControlador()
{
    if (oControlador == undefined)
    {
        oControlador = new ObjControlador();
    }
    return oControlador;
}

function ObjControlador()
{
    var that = this;

    // esta funcion es distinta en app de ferias y Catalogo Web por la forma de llegar a la ficha de empresa
    this.navegarAFichaEmpresa= function (empresa, stand)
    {
        that.navegarAPagina('', '#ajax_ficha.html?i=' + empresa + '&s=' + stand);
    }
    this.navegarAPagina = function (Tipo, url)
    {
        window.location= url;
    }

    //[ALTIA]
    //this.IniciarColores = function ()
    //{
    //    GetObjAgenda().cargaColoresCorporativos();
    //}
    //FIN [ALTIA]
};
