/// <reference path="C:\apps\Catalogo Movil\waCatalogoMovil-KENDOUI\waCatalogoMovil\Scripts/kendo/kendo.ui.core.js" />

(function (kendoNameSpace) {
    'use strict';
    //below you place anything private you don't want exposed in the viewModel
    //below we create the viewModel
    kendoNameSpace.ficha =
    {//create viewModel namespace in global i.e. namespace.[viewModel Name], to expose to global
    	
        viewModel: kendo.observable({
            //the actual model
            modelData: kendoNameSpace.listaModel,
            description: "Description",
            titulo: "Ficha Acreditación",
            isDescriptionShown: false,
            id: null,
            selectedPerson: null,
            init: function () {
                //alert('id = ' + getParameterByName('id'));
                this.id = parseInt(getParameterByName('id'));
                var elem = kendoNameSpace.listaModel.getPersonId(this.id);
                this.set("selectedPerson", elem);
              
            },
            show: function (e) {//set data on scrollview
                //este bind es para que se enganchen los botones de navegación de la cabecera
                kendo.bind($("#contenedor_paginas_kendo > #ajax_ficha"), this);
                GetObjGlobales().cargaIdioma();
                if (GetObjGlobales().EsSpanish()) { GetObjGlobales().titulaCabecera('Ficha Acreditacion'); } else { GetObjGlobales().titulaCabecera('Accreditation Tab'); }
               
            },
            onHome: function () {
                kendoNameSpace.navegacion.NavegaHome();
            },
            onBack: function (e) {
                kendoNameSpace.navegacion.NavegaBack();
            },
            newContact: function () {
            		addeventoanalitics(GetObjGlobales().feria,"Acreditacion Gestion Contactos", this.id, 0);
            		var prueba= false;
            		var datos=this.selectedPerson;
		            function onSuccess(contacts) {
		            	//alert(JSON.stringify(contacts));
		            	var oldContact = undefined;
		            	if (contacts.length > 0)
		            		oldContact = contacts[0];

		            	var contact= navigator.contacts.create({"displayName": datos.name});
		            	var phones=[];
	            		phones[0]=new ContactField ('Teléfono',datos.phone, true);
	            		phones[1]=new ContactField ('Fax',datos.fax, false);
	            		contact.phoneNumbers=phones;
	            		
	            		var addresses=[];
	            		addresses[0]= new ContactAddress (true, '', '', datos.address, datos.municipaly, datos.province, datos.cpostal, datos.country);
	            		contact.addresses=addresses;
	            		
	            		var organizations=[];
	            		organizations[0]= new ContactOrganization (true, '', datos.company ,'', '');
	            		organizations[0].title=datos.position;
	            		contact.organizations=organizations;
	            	
	            		var emails=[];
	            		emails[0]=new ContactField ('emails', datos.mail, true);
	            		contact.emails=emails;
	            		
	            		if (oldContact !== undefined)
	            		{
	            			if (GetObjGlobales().EsSpanish()) {
	            				 $('#dialog-confirm').html('<p> El contacto existe en el dispositivo. Sus datos se sobrescribirán ' +_interr + 'Desea continuar?</p>');
	            				MsgSiNoActualizarContacto('Actualizar contacto', oldContact, contact);
	            				
	            	    	} else {
	            	    		$('#dialog-confirm').html('<p>The contact exits in the device. Your data will be overwritten. ' + 'Do you want continue?</p>');
	            	    		MsgSiNoActualizarContacto('Update contact', oldContact, contact);
	            	        } 
	            			
	            			
	            		} else {
	            			if (GetObjGlobales().EsSpanish()) {
	            				 $('#dialog-confirm').html('<p> Se añadirá el contacto al dispositivo ' +_interr + 'Desea continuar?</p>');
	            				MsgSiNoCrearContacto('Crear contacto', contact);
	            			
	            	    	} else {
	            	    		$('#dialog-confirm').html('<p> The contact will be added to the device.' + 'Do you want continue?</p>');
	            	    		MsgSiNoCrearContacto('Create contact', contact);
	            	        } 
	            			
	            		}
		            };
		
		            function onError(contactError) {
                        if (GetObjGlobales().EsSpanish()) {
                            $('#dialog-confirm').html('<p>No se pueden gestionar los contactos del dispositivo. Revise los permisos de la aplicación.</p>');
                            MsgSi("Error", null);
                        } else {
                            $('#dialog-confirm').html('<p>Device contacts cannot be managed. Check the application permissions.</p>');
                            MsgSi("Error", null);
                        }
		            };
		            if (datos.phone!=""){
			            var fields = [navigator.contacts.fieldType.phoneNumbers];
		            
		            	var options=new ContactFindOptions();
		            	options.filter=this.selectedPerson.phone;
		            	options.multiple = true;
		            	//options.desiredFields = [navigator.contacts.fieldType.phoneNumbers];
		            	
		            	navigator.contacts.find(fields,onSuccess,onError,options);
		            } else if (datos.mail!="e-mail no disponible" && datos.mail!="e-mail not available") {
		            	var fields = [navigator.contacts.fieldType.emails];
		            	
			            
		            	var options=new ContactFindOptions();
		            	options.filter=this.selectedPerson.mail;
		            	options.multiple = true;
		            	//options.desiredFields = [navigator.contacts.fieldType.emails];

		            	navigator.contacts.find(fields,onSuccess,onError,options);
		            	
		            } else {
		            	if (GetObjGlobales().EsSpanish()) {
                             $('#dialog-confirm').html('<p>No se puede añadir un contacto al dispositivo sin telefono ni e-mail</p>');
                             MsgSi("Error", null);
		            	} else {
		            		 $('#dialog-confirm').html('<p>You can not add a contact to the device without a phone or e-mail</p>');
	           				 MsgSi("Error", null);
		            	}
		            }
	            	
	            	
            },
            marcarEmailAnalytics: function(){
            	var mail=this.selectedPerson.mail;
            	addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Email', mail.substring(0, mail.indexOf("@")), 0);
            	document.location.href = "mailto:" + mail;
            },
            hideWithTimeout : function(id) {
            	setTimeout(function() {
            		$(id).fadeOut(1000);
            	}, 5000);
            }
            
        })
    }
    
    	
    /*kendoNameSpace.marcarEmailAnalytics = function(mail) {
    	
    }*/

})(kendoNameSpace); //pass in global namespace
