/// <reference path="C:\apps\Catalogo Movil\waCatalogoMovil-KENDOUI\waCatalogoMovil\Scripts/kendo/kendo.ui.core.js" />

(function (kendoNameSpace) {
    'use strict';
    kendoNameSpace.CSSnewAcreditation = "newAcreditation",
    kendoNameSpace.CSSnewAcreditationNoSynchronized = "newAcreditationNoSynchronized",    
    kendoNameSpace.CSSsynchronizedAcreditation = "synchronizedAcreditation",    
    kendoNameSpace.CSSnoSynchronizedAcreditation = "noSynchronizedAcreditation",    
    kendoNameSpace.flagFiltroLetra = false,
    kendoNameSpace.ultimoQRLeido = "",
    kendoNameSpace.utils = {
    		hayConexion: function (){
    			obtenerModoConexion();    			
    			if ((GetObjGlobales().modoConexion.length > 0) && (GetObjGlobales().modoConexion != 'Conexion desconocida') && (GetObjGlobales().modoConexion != 'Sin conexion')) 
    				return true;
    			else
    				return false;
    		}
    },
    
    kendoNameSpace.lista =
    {//create viewModel namespace in global i.e. namespace.[viewModel Name], to expose to global

        viewModel: kendo.observable({
            //the actual model
            modelData: kendoNameSpace.listaModel.dataSource,
            description: "Description",
            titulo: "Meet&Scan",
            isDescriptionShown: false,
            filaABorrar:'',
            onClickElemento: function (e) {
                //navega("kendo/ui/ajax_ficha.html?id=" + e.dataItem.id);               
            },
            onDeleteclick: function (e) {
                this.filaABorrar = e.data;
                if (GetObjGlobales().EsSpanish()) {
                    $('#dialog-confirm').html('<p>' + _msgInfo +
                    'La acreditaci' + _o + 'n va a ser eliminada. '+ _interr +'Desea continuar?</p>');
                    MsgSiNo('Eliminar acreditaci'+_o+'n', kendoNameSpace.lista.viewModel.eliminaAcreditacion, null);
                } else {
                    $('#dialog-confirm').html('<p>' + _msgInfo +
                    'This accreditation will be deleted. Are you sure?</p>');
                    MsgSiNo('Delete accreditation', kendoNameSpace.lista.viewModel.eliminaAcreditacion, null);
                }

            },
            eliminaAcreditacion: function () {
                kendoNameSpace.listaModel.deleteLectura(kendoNameSpace.lista.viewModel.filaABorrar.codigo,
                        function (result) {
                            if (result == 0) {
                                var listView = $("#list").data("kendoMobileListView").dataSource;
                                listView.remove(kendoNameSpace.lista.viewModel.filaABorrar);
                                var dsFetch = listView.fetch(
                                        function () {
                                            if (this.data().length == 0)
                                                kendoNameSpace.lista.viewModel.addNoHayDatos();
                                            kendo.mobile.application.scroller().scrollTo(0, 0);
                                        }
                                );
                            }
                        }
                );
            },
            show: function (e) {
                addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Gestionar', '', 0);
                //este bind es para que se enganchen los botones de navegacion de la cabecera
                kendo.bind($("#contenedor_paginas_kendo > #ajax_lista"), this);
                GetObjGlobales().cargaIdioma();
                kendoNameSpace.checkConectionMessage();
                
                if(GetObjGlobales().so == 'IOS'){
                    var currentHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                    var dif = currentHeight - 122;
                    var posBotom = (dif)*(-1);

                    $("#botonScroll").css('bottom',posBotom + "px");

                    $("#list-navigation-acreditations").css('height',dif + "px");
                }
                if (GetObjGlobales().EsSpanish()) {
                    $('input[type=search]').attr('placeholder', 'Buscar...');
                }else{
                    $('input[type=search]').attr('placeholder', 'Search...');
                }			                
                $("input[type=search]").keydown(function(event){
                	if(kendoNameSpace.flagFiltroLetra && $("input[type=search]").val().length == 0){
                		kendoNameSpace.flagFiltroLetra = false;                		
                		kendoNameSpace.RefrescarLecturasLista().then(function(result){});
                	}
                });
                kendoNameSpace.lista.viewModel.synchronizeAcreditations();
        		/*kendoNameSpace.lista.hayLecturasNoSincronizadas().then(
        	    		function (result)
        	    		{          	
        			            if (result && kendoNameSpace.utils.hayConexion())
        			            {                   	   
        							new RSVP.Promise(function(resolve, reject) {    	
        				        		window.sincronizarCodigosQR([kendoNameSpace.loadJSONParamToObtenerSincronizarCodigsoQR()],
        										function(){resolve()}, 
        										function(){reject()}); 
        							}).then(function (){
        								kendoNameSpace.RefrescarLecturasLista().then(function(result){});
        							});
        			            }
        			            else{
        			            	kendoNameSpace.RefrescarLecturasLista().then(function(result){});       			            	
        			            }
        		    	}
        	    );   */         		                
            },
            onHome: function () {
                kendoNameSpace.navegacion.NavegaHome();
            },
            onBack: function (e) {
                kendoNameSpace.navegacion.NavegaBack();
            },
            fichaAcreditacion: function (e) {
            	kendoNameSpace.navegacion.NavegaFicha(e);
            },
            leyProteccionDatos: function () {
            	kendoNameSpace.navegacion.NavegaLey();
            },
            readAcreditation: function (e) {
            	if (GetObjGlobales().MostrarAyuda()){
            		if (GetObjGlobales().EsSpanish()){
            			MsgLeerAcreditacionHelp('Leer acreditacion', kendoNameSpace.execReadAcreditationPlugin);
            		} else MsgLeerAcreditacionHelp('Read acreditation', kendoNameSpace.execReadAcreditationPlugin);
            	} else kendoNameSpace.execReadAcreditationPlugin();
            },
            helpReadAcreditation: function () {
            	if (GetObjGlobales().EsSpanish()){
            		MsgLeerAcreditacionHelpButton('Leer acreditacion');
            	} else MsgLeerAcreditacionHelpButton('Read acreditation');
            },
            synchronizeAcreditations: function() {
            	kendoNameSpace.lista.viewModel.limpiaFiltroBuscar();
                if (kendoNameSpace.utils.hayConexion()) 
               {
                	$(".loader").show();                    	
                    addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Sincronizar', '', 0);
                	setTimeout(function() {
                        window.sincronizarCodigosQR([kendoNameSpace.loadJSONParamToObtenerSincronizarCodigsoQR()], 
                    			kendoNameSpace.sincronizarQRPluginOK,//setObtenerSincronizarCodigosQROK, 
                    			kendoNameSpace.sincronizarQRPluginError//setObtenerSincronizarCodigsoQRError
                    			);
                	}, 200); //lo meto en timeout simplemente para que me salga el loader un poquito

                }
                else {
                    $("#errorSincronizarDispositivoSinConexion").show();                        
                    kendoNameSpace.hideWithTimeout("#errorSincronizarDispositivoSinConexion");
                }
            },
            exportAcreditationsCSV : function (){
            	
            	addeventoanalitics(GetObjGlobales().feria, "Acreditacion Exportacion", "", 0);
            	kendoNameSpace.listaModel.getAllQRCodesFeriaPromise(GetObjGlobales().feriaPrincipal).then(
    					function (result) {
    						
    						var csv="";
    						if (GetObjGlobales().EsSpanish()) { 						
    							csv='Nombre;Empresa;Cargo;Telefono;Fax;E-Mail;Pais;Provincia;Municipio;Direccion;Codigo Postal \n';
    						} else {
    							csv='Name;Company;Position;Phone;Fax;Mail;Country;Province;Municipaly;Address;Postal Code \n';
    						}
    						
    						if (result.length>0){
    							
    							var separador = ";";
    							var i;
    							for (i=0;i<result.length;i++){
    								var row = result.item(i);
    								var datos = JSON.parse(row.datosAcreditacion); 
    								datos = kendoNameSpace.lista.viewModel.comprobarIndefinido(datos);
	    							csv += datos.nombre + separador + 
	    							datos.empresa + separador + 
	    							datos.cargo + separador + 
	    							datos.telefono + separador + 
	    							datos.fax + separador + 
	    							datos.email + separador + 
	    							datos.pais + separador + 
	    							datos.provincia + separador + 
	    							datos.poblacion + separador + 
	    							datos.direccion + separador + 
	    							datos.codigoPostal + '\n';
    								
    							}
    							csv = "\ufeff" + csv; //necesario para que se vea el caracter ñ
                                // iOS
                                //var systemURI = cordova.file.syncedDataDirectory;
                                // Android
                                var systemURI = cordova.file.externalDataDirectory;
                                //alert(systemURI);
                                if (systemURI != null)
                            	{
	    							window.resolveLocalFileSystemURL(systemURI, function(dir) {
	    								function writeFile(fileEntry, dataObj) {
	    	    						    // Create a FileWriter object for our FileEntry (log.txt).
	    	    						    fileEntry.createWriter(function (fileWriter) {
	
	    	    						        fileWriter.onwriteend = function() {
	    	    						            console.log("Successful file write...");
	    	    						            readFile(fileEntry);
	    	    						        };
	
	    	    						        fileWriter.onerror = function (e) {
	    	    						            console.log("Failed file write: " + e.toString());
	    	    						        };
	
	    	    						        // If data object is not passed in,
	    	    						        // create a new Blob instead.
	    	    						        if (!dataObj) {
	    	    						            dataObj = new Blob([' '], { type: 'text/plain;charset=UTF-8' });
	    	    						        }
	
	    	    						        fileWriter.write(dataObj);
	    	    						    });
	    	    						    
	    	    						    return dataObj;
	    	    						}
	    								
	    								dir.getFile("ListaAcreditaciones.csv",{create:true},function (fileEntry){
	    									console.log("fileEntry is file?" + fileEntry.isFile.toString());
										    // EN EXCEL NO SE MUESTRA CORRECTAMENTE LA Ñ PERO SI SE LEE EL FICHERO COMO UTF-8 SE PROCESA SIN PROBLEMAS  
										    var blob = new Blob([csv],{type:'text/csv;charset=UTF-8;'});
	
										    blob = writeFile(fileEntry,blob);
										    var reader = new FileReader();
										    reader.onloadend = function() {
										        var base64data = reader.result;     
										        base64data = base64data.substr(base64data.indexOf(',')+1);
									        	//alert(base64data);     
										        
										        if (GetObjGlobales().EsSpanish()) {
										        	$('#dialog-confirm').html('<p>' +_interr +'Desea compartir el fichero de acreditaciones generado?</p>');
										        	MsgSiNoEnviarCorreo('Enviar correo electrónico', base64data);
										        } else {
										        	$('#dialog-confirm').html('<p>Do you want to share the generated accreditation file?</p>');
										        	MsgSiNoEnviarCorreo('Send e-mail', base64data);
										        }
										    }
										    reader.readAsDataURL(blob); 
	    								});
	    							});
                            	} else {
                                      var blob = new Blob([csv],{type:'text/csv;charset=UTF-8;'});
                                      var reader = new FileReader();
                                      reader.onloadend = function() {
                                          var base64data = reader.result;
                                          base64data = base64data.substr(base64data.indexOf(',')+1);
                                          if (GetObjGlobales().EsSpanish()) {
                                              $('#dialog-confirm').html('<p>Se va a compartir el fichero</p>');
                                              MsgSiEnviarCorreo('Enviar correo electrónico', base64data);
                                          } else {
                                              $('#dialog-confirm').html('<p>The file will be shared</p>');
                                              MsgSiEnviarCorreo('Send e-mail', base64data);
                                          }
                                      }
                                      reader.readAsDataURL(blob);
                            	}
    							kendoNameSpace.AddEventoGoogleAnalyticsError('Documento creado correctamente');
    			    	    	$("#creacionCorrectaCsv").show();	 
    			    	    	kendoNameSpace.hideWithTimeout("#creacionCorrectaCsv");
    							
    						} else {
    							if (GetObjGlobales().EsSpanish()) {
							        $('#dialog-confirm').html('No hay datos para exportar');
							        MsgSi("Error al exportar", "");
						        } else {
						        	$('#dialog-confirm').html('There is no data to export');
						        	MsgSi("Error export", "");
						        }
    						}
    						    						
    					}
    				);
            		//alert("Funcionalidad 'Exportar csv' en construcción...");
            	
            },
            limpiaFiltroBuscar: function (){
            	$("#list").data("kendoMobileListView").dataSource.filter({});
        		kendoNameSpace.flagFiltroLetra = false;
            },
            addNoHayDatos: function ()
            {
            	var listView = $("#list").data("kendoMobileListView").dataSource;
    			var tagNoHayDatos = "No hay datos";   		
        		if(!GetObjGlobales().EsSpanish()){
        			tagNoHayDatos = 'There is no data';}    		
    			listView.add({ letter:"", allData:"", name: "", company: "", mail:"",date: "", tagNombre: "", tagEmpresa: "", 
    							tagNoSincronizado1: "", tagNoSincronizado2: "", tagNoSincronizado3: "", tagNoHayDatos: tagNoHayDatos, 
    							isSynchronied: true, isHasMail: false, CSSClass: "noData"});        	
            },
            altaLecturaLeida: function(DatosAcreditacion)
        	{
            	
    	        var newItemId='';    		    			    		    				        
    	        var concatAllData = DatosAcreditacion.nombre + " " + DatosAcreditacion.empresa + " " + DatosAcreditacion.email + " " + DatosAcreditacion.fecha;
    			var sortData = DatosAcreditacion.nombre + " " + DatosAcreditacion.empresa + " " + DatosAcreditacion.email + " " + new Date(DatosAcreditacion.fecha).getTime();
    			var listView = $("#list").data("kendoMobileListView").dataSource;
    			//alert("funcion alta recibe:"+concatAllData);
    	    	if(DatosAcreditacion.conexion == "0"){
    	    		var tagNombre = "Nombre";
            		var tagEmpresa = "Empresa";
            		var tagEmail = "e-mail no disponible";
            		
            		if(!GetObjGlobales().EsSpanish()){
            			tagNombre = 'Name';
            			tagEmpresa = "Company";
            			tagEmail = "e-mail not available";
                    }
            		
            		var isHasMail = true;
        			
        			if(DatosAcreditacion.email == "" || DatosAcreditacion.email == null){
        				isHasMail = false;                    				
        				DatosAcreditacion.email = tagEmail;
        			}    		            		
    	    		var primeraLetra = DatosAcreditacion.nombre.charAt(0).toUpperCase();
    	    		
        			newItemId = listView.add({codigo:DatosAcreditacion.codigoBarras, letter:primeraLetra, sortElements:sortData, allData:concatAllData, name: DatosAcreditacion.nombre, 
        									company: DatosAcreditacion.empresa, mail: DatosAcreditacion.email, date: DatosAcreditacion.fecha, position: DatosAcreditacion.cargo, tagNombre: tagNombre, 
        									tagEmpresa: tagEmpresa, tagNoSincronizado1: "", tagNoSincronizado2: "", tagNoSincronizado3: "", 
        									tagNoHayDatos: "", isSynchronied: true, isHasMail: isHasMail, CSSClass: kendoNameSpace.CSSsynchronizedAcreditation, id: DatosAcreditacion.id });
        			
        			kendoNameSpace.lista.viewModel.synchronizeAcreditations();
    	    	}else{
    	    		//no debe entrar
    	    		alert("error");
    	    	}
    	    	return(newItemId);            	
            },            
            MarcarFilaActiva: function (codigo,fecha)
            {
            	return new RSVP.Promise(function (resolve, reject){    	
        					var datSource = $("#list").data("kendoMobileListView").dataSource;
        			   	 	var dsFetch = datSource.fetch(function(){
        			   	 										  var itemNew=-1;			   	 		
        												  		  var dataItems = this.data();
        												          for(var i=0;i<dataItems.length;i++)
        												          {
        														      	if (dataItems[i].codigo == codigo)
        														      	{       
        														      		if (fecha != undefined) dataItems[i].date = fecha;
        														      		if (dataItems[i].isSynchronied)
        														      			dataItems[i].CSSClass= kendoNameSpace.CSSnewAcreditation;
        														      		else
        														      			dataItems[i].CSSClass= kendoNameSpace.CSSnewAcreditationNoSynchronized;
        														      		itemNew = i;
        														      	}
        														      	else
        														      	{
        														      		if ((dataItems[i].CSSClass == kendoNameSpace.CSSnewAcreditation) || (dataItems[i].CSSClass == kendoNameSpace.CSSnewAcreditationNoSynchronized))
        														      		{
        														            	if (dataItems[i].isSynchronied)        			
        														            		dataItems[i].CSSClass= kendoNameSpace.CSSsynchronizedAcreditation;
        														        		else
        														        			dataItems[i].CSSClass= kendoNameSpace.CSSnoSynchronizedAcreditation;
        														      		}        			        		
        														      	}
        												          };
        												          resolve(itemNew);
        			   	 									});
        			   	 	});		
            	
            },            
            CargaListaLecturas: function(result)
            {
                var listView = $("#list").data("kendoMobileListView").dataSource;
                listView.data([]);
                var data = new kendo.data.ObservableArray([]);
              //  alert("Cargando lista");
        		if(result != 0)
        		{		
        			var posNewItemId = 0;
            		var tagNombre = "Nombre";
            		var tagEmpresa = "Empresa";
            		var tagNoSincronizado1 = "La lectura se realiz\u00f3 sin conexi\u00f3n.";
            		var tagNoSincronizado2 = "Pulse";
            		var tagNoSincronizado3 = "para sincronizar";
            		var tagEmail = "e-mail no disponible";
            		
            		if(!GetObjGlobales().EsSpanish()){
            			tagNombre = 'Name';
            			tagEmpresa = "Company";
            			tagNoSincronizado1 = "The reading was made offline.";
            			tagNoSincronizado2 = "Push";
            			tagNoSincronizado3 = "to synchronize";
            			tagEmail = "e-mail not available";
                    }    		
            		var data = new kendo.data.ObservableArray([]);
                	for(var i=0;i<result.length;i++)
                	{       		
                		var row = result.item(i);
                        var item = 0;      
                       // alert("Resultados en array="+result.length+"row id ="+row.id);
                		
                			var QRCodeData = JSON.parse(row.datosAcreditacion); 
                			var concatAllData = QRCodeData.nombre + " " + QRCodeData.empresa + " " + QRCodeData.email + " " + row.fecha;
                			
                			var sortData = QRCodeData.nombre + " " + QRCodeData.empresa + " " + QRCodeData.email + " " + new Date(row.fecha).getTime();
                			var primeraLetra = QRCodeData.nombre.charAt(0).toUpperCase();        			
                			var isHasMail = true;        			
                			if(QRCodeData.email == "" || QRCodeData.email == null){
                				isHasMail = false;        				
                				QRCodeData.email = tagEmail;
                			}       
                			data.push({codigo:row.codigoBarras, letter:primeraLetra, sortElements:sortData, allData:concatAllData, name: QRCodeData.nombre, company: QRCodeData.empresa, 
                                mail: QRCodeData.email, date: row.fecha, phone: QRCodeData.telefono, fax: QRCodeData.fax, country: QRCodeData.pais, province: QRCodeData.provincia, municipaly: QRCodeData.poblacion, address: QRCodeData.direccion, cpostal: QRCodeData.codigoPostal, position: QRCodeData.cargo,  tagNombre: tagNombre, tagEmpresa: tagEmpresa, tagNoSincronizado1: "", tagNoSincronizado2: "", 
                                tagNoSincronizado3: "", tagNoHayDatos: "", isSynchronied: true, isHasMail: isHasMail, CSSClass: kendoNameSpace.CSSsynchronizedAcreditation, id: row.id });
        			
                		
                	}
                	listView.data(data);
        		}
        		else
        		{
        			kendoNameSpace.lista.viewModel.addNoHayDatos();
        		}
        		//$('.loader').hide();		
        	},
        	comprobarIndefinido: function(datos){
        		if (datos.nombre===undefined)datos.nombre="";
        		if (datos.empresa==undefined) datos.empresa="";
        		if (datos.cargo==undefined) datos.cargo="";
        		if (datos.telefono==undefined) datos.telefono="";
        		if (datos.fax==undefined) datos.fax="";
        		if (datos.email==undefined)  datos.email="";
        		if (datos.pais==undefined) datos.pais="";
        		if (datos.provincia==undefined)datos.provincia="";
        		if (datos.poblacion==undefined) datos.poblacion="";
        		if (datos.direccion==undefined)  datos.direccion="";
        		if (datos.codigoPostal==undefined) datos.codigoPostal="";
        		return datos;
        		
        	}
        }),

    },

    kendoNameSpace.loadJSONParamToObtenerCodigoPDF417Plugin= function(nombrePersona, cargo, nombreEmpresa, direccion, 
    		codigoPostal, poblacion, provincia, pais, telefono, fax, email, codigoBarras, perfilProfesional) {
		var param = { };
        param.nombre = nombrePersona;
        param.cargo = cargo;
        param.empresa = nombreEmpresa;
        param.direccion = direccion;
        if (param.codigoPostal!="53000")
        {
            param.codigoPostal = codigoPostal;
        } else param.codigoPostal = "";
        param.poblacion = poblacion;
        param.provincia = provincia;
        param.pais = pais;
        param.telefono=telefono;
        param.fax = fax;
        param.email=email;
        param.codigoBarras = codigoBarras;
        param.perfilProfesional = perfilProfesional;
		param.codigoFeria = GetObjGlobales().feriaPrincipal;
	    return param;
	},
	
	kendoNameSpace.loadJSONParamToObtenerSincronizarCodigsoQR= function() {
		var param = { };
	    
	    param.codigoFeria = GetObjGlobales().feriaPrincipal;
	    
	    return param;
	},
    kendoNameSpace.execReadAcreditationPlugin = function() {
	
        addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Leer', '', 0);
   
        cordova.plugins.barcodeScanner.scan(
            function (result) {
            	
            	if (/^((.+){0,1}\/(.+){0,1})+$/.test(result.text) && result.text.length > 12)
        		{
            		var codeText = result.text;//.replace(/#/g,"Ñ");
            		var attributes = codeText.split("/");
            		
                	kendoNameSpace.RefrescarLecturasLista().then(function(value) {
	            		window.obtenerCodigoPDF417([kendoNameSpace.loadJSONParamToObtenerCodigoPDF417Plugin(
	            					attributes[0],attributes[1],attributes[2],attributes[3],attributes[4],
	            					attributes[5],attributes[6],attributes[7],attributes[8],attributes[9],
	            					attributes[10],attributes[11],attributes[12])], 
								kendoNameSpace.obtenerPDF417PluginOK, 
								kendoNameSpace.obtenerPDF417PluginError);	
			    	});
                
        		}
            	else if(!result.cancelled){
    						//El codigo leido no es valido en cuanto a patron de formato
    						$( "#errorFormatoCodigoQR" ).show();    				
    						kendoNameSpace.hideWithTimeout("#errorFormatoCodigoQR");
    				}
            },
            function (error) {
    	    	kendoNameSpace.AddEventoGoogleAnalyticsError('Error de lectura de código');
                kendoNameSpace.showReadAcreditationModalError();
            },
            {
                "preferFrontCamera" : false,
                "showFlipCameraButton" : true,
                "orientation" : "portrait",
                formats : "PDF_417"
            }
        );
    },
    
    kendoNameSpace.RefrescarLecturasLista = function(firstLetter) {
   
		return new RSVP.Promise(function(resolve, reject) {		
		
		    	$(".loader").show();
		    	if (firstLetter != undefined && firstLetter != "")
		    	{
		    		//alert("entro por letra");
		    		kendoNameSpace.listaModel.getAllQRCodesFirstLetterPromise(firstLetter,  GetObjGlobales().feriaPrincipal).then(
		        			function(result){
		        					kendoNameSpace.lista.viewModel.CargaListaLecturas(result);
		        					kendo.mobile.application.scroller().scrollTo(0, 0);
		        					$('.loader').hide();
		        		        	resolve('');		        					
		        				}
		        			);
		        }
		    	else
		    	{
		    		//alert("entro por global");
		    		kendoNameSpace.listaModel.getAllQRCodesFeriaPromise(GetObjGlobales().feriaPrincipal).then(
		    					function (result) {
		    						
		    						kendoNameSpace.lista.viewModel.CargaListaLecturas(result);
		    						kendo.mobile.application.scroller().scrollTo(0, 0);		    						
		    						$('.loader').hide();		    						
		    			        	resolve('');		    						
		    					}
		    				);
		    	}
		    	
		})
    },

    kendoNameSpace.checkConectionMessage = function() {
        if (!kendoNameSpace.utils.hayConexion()){
                $( "#noConexionModal" ).show();                
                kendoNameSpace.hideWithTimeout("#noConexionModal");
        }
    },
        
    kendoNameSpace.reintentarLectura = function() {
    	//Se oculta el dialogo
        $("#errorLecturaQRModal").hide();

        //Se llama al plugin para realizar el reintento
        kendoNameSpace.execReadAcreditationPlugin();
    },
    
    kendoNameSpace.showReadAcreditationModalError = function() {
    	$("#errorLecturaQRModal").show();
        
    	kendoNameSpace.hideWithTimeout("#errorLecturaQRModal");
        
        if(GetObjGlobales().EsSpanish()) {
           $("#retryReadAcreditationButton").attr("value","Reintentar");
        }else {
           $("#retryReadAcreditationButton").attr("value","Retry");
        }
    },
    
    kendoNameSpace.hideWithTimeout = function(id) {
    	setTimeout(function() {
    		$(id).fadeOut(1000);
    	}, 5000);
    },
    
    kendoNameSpace.hideElement = function(id) {
    	$(id).hide();
    },
    
    kendoNameSpace.marcarEmailAnalytics = function(mail) {
    	addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Email', mail.substring(0, mail.indexOf("@")), 0);
    	
    	document.location.href = "mailto:" + mail;
    },
    
    kendoNameSpace.filtrarFirstLetter = function(element) {
    	kendoNameSpace.lista.viewModel.limpiaFiltroBuscar();
    	
        if(element == undefined){
        	kendoNameSpace.flagFiltroLetra = false;
        	kendoNameSpace.RefrescarLecturasLista().then(function(result){});
        }else{
        	kendoNameSpace.flagFiltroLetra = true;

            var list = element.id.split('-');
            
            var letter = list[1];
            kendoNameSpace.RefrescarLecturasLista(letter).then(function(result){
            	//kendo.mobile.application.scroller().scrollTo(0, 0);            	
            });                        
        }
    },
    
    kendoNameSpace.ScrollToItem = function (posNewItemId)
    {
    	//var posNewItemId = $("#list").data("kendoMobileListView").dataSource.at(item);    	
    	$("#list").data("kendoMobileListView").refresh();    	
    	kendo.mobile.application.scroller().scrollTo(0, 0);    	
        var posNewItem = $("li[data-uid='" + posNewItemId.uid + "']").position().top;
        posNewItem = posNewItem*(-1);
        var currentHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var mit = currentHeight - currentHeight*0.20;
        if((posNewItem*(-1)) > mit){
        	var posToScroll = $("li[data-uid='" + posNewItemId.uid + "']").offset().top - currentHeight + $("li[data-uid='" + posNewItemId.uid + "']").height();
        	posToScroll = posToScroll*(-1);
        	kendo.mobile.application.scroller().animatedScrollTo(0, posToScroll);
        }        		
    },    



    kendoNameSpace.obtenerPDF417PluginOK = function(codigoPDF417) {  
    	kendoNameSpace.AfterLecturaLeidaViewPDF417(codigoPDF417);
    }, 
    kendoNameSpace.AfterLecturaLeidaViewPDF417 = function (codigo)
    {
    	var CodeData = JSON.parse(codigo);
    	//alert("CodigoLeido="+CodeData.codigoBarras)
    	if(CodeData.existe == "YA_EXISTE"){
	        addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Duplicada', CodeData.codigoBarras, 0);    		
    		$("#errorYaExisteCodigoQR").show();
    		kendoNameSpace.lista.viewModel.MarcarFilaActiva(CodeData.codigoBarras,CodeData.fecha).then(
    				function (itemNew){
    					if (itemNew != -1){ 
    						kendoNameSpace.ScrollToItem($("#list").data("kendoMobileListView").dataSource.at(itemNew));
    					}
    					kendoNameSpace.hideWithTimeout("#errorYaExisteCodigoQR");
    				}
    		/*
    		kendoNameSpace.lista.viewModel.MarcarFilaActiva(CodeData.codigoQR,CodeData.fecha).then(
    				function (itemNew){
    					if (itemNew != -1){ 
    						kendoNameSpace.ScrollToItem($("#list").data("kendoMobileListView").dataSource.at(itemNew));
    					}
    					kendoNameSpace.hideWithTimeout("#errorYaExisteCodigoQR");
    				}*/
    		);                          		  		    		
    	}
    	else
    	{	    
    		//alert("code="+CodeData.conexion);
    		switch(CodeData.conexion) {
    	    case "-1":
    	    	kendoNameSpace.AddEventoGoogleAnalyticsError('No existe informacion para el codigo leido');
    	    	$("#errorInformacionCodigoQR").show();	        	    	
    	    	kendoNameSpace.hideWithTimeout("#errorInformacionCodigoQR");
    	        break;
    	    case "100":
    	    	kendoNameSpace.AddEventoGoogleAnalyticsError('No existe informacion para el codigo leido');    	    	   	    	
	    		$("#errorInformacionCodigoQR").show();	        	    	
	    		kendoNameSpace.hideWithTimeout("#errorInformacionCodigoQR");
    	        break;
    	    case "102":
    	    	kendoNameSpace.AddEventoGoogleAnalyticsError('No se ha podido leer la acreditacion');    	    	   	    	    	    	
    			kendoNameSpace.showReadAcreditationModalError();
    			break;
    	    case "103":
    	    	kendoNameSpace.AddEventoGoogleAnalyticsError('El servicio unicamente se encuentra disponible los dias de la feria');    	    	   	    	
    			$("#errorLecturaDisponiblidadFecha").show();	        	    	
    			kendoNameSpace.hideWithTimeout("#errorLecturaDisponiblidadFecha");
    	        break;
    	    case "104":
    	    	kendoNameSpace.AddEventoGoogleAnalyticsError('El servicio no se encuentra disponible para esta acreditacion');    	    	   	    	
				$("#errorLecturaDisponiblidad").show();	        	    	
				kendoNameSpace.hideWithTimeout("#errorLecturaDisponiblidad");
    	        break;	        	        
    	    default:
		    	//Se han obtenido los datos del Codigo PDF417
		    	var listView = $("#list").data("kendoMobileListView").dataSource;   			
		    	if($(".noData").length){
		    		var list = $("#list").data("kendoMobileListView");
		    		listView.remove(listView.at(0));
		    	}    	    	   				       
		        addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Leida', CodeData.codigoBarras, 0);
		        kendoNameSpace.lista.viewModel.altaLecturaLeida(CodeData);
	    		//kendoNameSpace.lista.viewModel.MarcarFilaActiva(CodeData.codigoBarras).then(function (itemNew){
					//if (itemNew != -1){
						//kendoNameSpace.ScrollToItem($("#list").data("kendoMobileListView").dataSource.at(itemNew));
					//}		    			
	    		//});                         		    		
    		}	       		
    	}   			    	
    },
    kendoNameSpace.AddEventoGoogleAnalyticsError = function(error){    	
        addeventoanalitics(GetObjGlobales().feriaPrincipal, 'Acreditacion Error' , error, 0);    	
    },
//    kendoNameSpace.obtenerQRPluginError = function(error) {
//    	kendoNameSpace.AddEventoGoogleAnalyticsError('Error de lectura de datos del servicio.'+ error);    	
//        kendoNameSpace.showReadAcreditationModalError();
//    },
    kendoNameSpace.obtenerPDF417PluginError = function(error) {
    	kendoNameSpace.AddEventoGoogleAnalyticsError('Error de lectura de datos del servicio.'+ error);    	
        kendoNameSpace.showReadAcreditationModalError();
    },
    
    kendoNameSpace.sincronizarQRPluginOK = function(codigosActualizados) {
    	kendoNameSpace.RefrescarLecturasLista().then(
    				function (result)
    				{    					
    			        $(".loader").hide();        
    			        if(codigosActualizados == 1){
    			            $("#errorSincronizacionLLamadaWS").show();            
    			            kendoNameSpace.hideWithTimeout("#errorSincronizacionLLamadaWS");
    			        }else if(codigosActualizados == 2){
    			                //Error de informaciÛn de datos
    			                $("#errorSincronizacionInformacionCodigoQR").show();    			                
    			                kendoNameSpace.hideWithTimeout("#errorSincronizacionInformacionCodigoQR");
    			            }else if(codigosActualizados == 3){
    			                    //Ambos errores anteriores a la vez
    			                    $("#errorSincronizacionLLamadaWSAndInformacionCodigoQR").show();    			                    
    			                    kendoNameSpace.hideWithTimeout("#errorSincronizacionLLamadaWSAndInformacionCodigoQR");
    			                }else if(codigosActualizados == 4){
    			                    //Ambos errores anteriores a la vez
    			                    $("#errorLecturaDisponiblidadFecha").show();    			                    
    			                    kendoNameSpace.hideWithTimeout("#errorLecturaDisponiblidadFecha");
    			                }    			        
    			        $("#list").data("kendoMobileListView").refresh();    					
    				}
    			);        
    },
    kendoNameSpace.sincronizarQRPluginError = function(error) {
    	$(".loader").hide();    	

        kendoNameSpace.AddEventoGoogleAnalyticsError('Error sincronizando. Lectura de datos del servicio.');
        
        //DDC no entiendo estos mensajes de error. Con uno generico deberia valer
        if(error == 1){
            $("#errorSincronizacionLLamadaWS").show();            
            kendoNameSpace.hideWithTimeout("#errorSincronizacionLLamadaWS");
        }else if(error == 3){
                    //Ambos errores anteriores a la vez
                    $("#errorSincronizacionLLamadaWSAndInformacionCodigoQR").show();                   
                    kendoNameSpace.hideWithTimeout("#errorSincronizacionLLamadaWSAndInformacionCodigoQR");                   
              }        
        
    }
})(kendoNameSpace); //pass in global namespace
