function inicializaestadisticas() {
	if (GetObjGlobales().so == SO_WEB || GetObjGlobales().so == ''){
		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
		(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
		})(window,document,'script','js/ua.js','ga');

		ga('create', 'UA-39829262-2', 'auto');
		ga('send', 'pageview');
	}else if ((GetObjGlobales().so == 'ANDROID') || (GetObjGlobales().so == 'IOS')){
		window.analytics.startTrackerWithId('UA-39829262-2');
	}
}

function inicializaestadisticas_agenda() {
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
	(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
	m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	})(window,document,'script','js/ua.js','ga');

	ga('create', 'UA-39829262-8', 'auto');
	ga('send', 'pageview');
}

function addeventoanalitics(category, action, label, valor) {
    //Para la agenda so se inicializa a WEB
	if (GetObjGlobales().so == SO_WEB){
		ga('send', 'event', category,action,label,valor);
	}else if ((GetObjGlobales().so == 'ANDROID') || (GetObjGlobales().so == 'IOS')){
		window.analytics.trackEvent(category,action,label,valor);
	}
}